import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { RootState } from 'store';
import { setRedirectionUrl } from 'store/routerRedirect';

const RedirectComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    routerRedirect: { redirectionUrl },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    redirectionUrl && navigate(redirectionUrl, { replace: true });
    return () => {
      dispatch(setRedirectionUrl(''));
    };
  }, [redirectionUrl]);

  return null;
};

export default RedirectComponent;
