import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { Status } from 'store/types';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Product, GetSimilarProductParams } from 'store/currentProduct/types';
import { calculateDeliveryDate } from 'helpers/checkDeliveryDate';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import productOwnerTypes from 'constants/productOwnerTypes';

interface ProductState {
  data: Product;
  status: Status;
  getProductInfoErrorCode: number | null;
  durationGetLoading: Status;
  productCountInCart: number;
  availablStock: number;
  postalCode?: string;
}

interface OneProductRequestInterface {
  id: string;
  lat: string;
  lng: string;
  enableDurationPostalCode?: boolean;
  postalCode?: string;
}

const getProduct = async (
  { id, lat, lng, enableDurationPostalCode, postalCode }: OneProductRequestInterface,
  { rejectWithValue }: any
) => {
  try {
    const selectedPostalCode =
      postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;
    let url = REACT_APP_BUYER_URL;
    const params = {} as { postalCode: string; lat: string; lng: string };
    if (enableDurationPostalCode) {
      url = REACT_APP_BUYER_URL_V2;
      params.postalCode = selectedPostalCode;
    } else {
      params.lat = lat;
      params.lng = lng;
    }
    return await read(`${url}/products/${id}`, { params });
  } catch (err: any) {
    return rejectWithValue(err.response);
  }
};

export const getProductInfo = createAsyncThunk('product', getProduct);

const getProductDuration = async ({ id, lat, lng }: OneProductRequestInterface) => {
  const responseNoDeliveryZone = await read(
    `${REACT_APP_BUYER_URL}/in-no-delivery-zone?lat=${lat}&lng=${lng}`
  );
  const { outOfDelivery } = responseNoDeliveryZone as any;
  let res = await read(
    `${REACT_APP_BUYER_URL}/products/${id}/duration?lat=${lat}&lng=${lng}&sendDeliveryDuration=${!outOfDelivery}`
  );
  if (outOfDelivery) {
    res = { ...res, duration: 4 } as any;
  }
  return res;
};
export const getProductDurationThunk = createAsyncThunk('productDuration/get', getProductDuration);

export const getProductCountInCart = async ({
  id,
  enableDurationPostalCode,
}: {
  id: string;
  enableDurationPostalCode: boolean;
}) => {
  const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  let url = REACT_APP_BUYER_URL;
  const params = {} as { postalCode?: string };
  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = postalCode;
  }
  return await read(`${url}/products/${id}/stock`, { params });
};

export const getProductCountInCartThunk = createAsyncThunk(
  'productCountInCart/get',
  getProductCountInCart
);

const getSimilarProduct = async ({
  productId,
  filterName,
  filterValue,
  enableDurationPostalCode,
}: GetSimilarProductParams) => {
  const selectedPostalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  let url = REACT_APP_BUYER_URL;

  const params = {} as any;

  if (filterName && filterValue) {
    params[filterName] = filterValue;
  }

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = selectedPostalCode;
  }

  return await read(`${url}/same-products/${productId}`, { params });
};

export const getSimilarProductThunk = createAsyncThunk('sameProduct/get', getSimilarProduct);

const initialState = {
  data: {},
  status: 'idle',
  getProductInfoErrorCode: null,
  durationGetLoading: 'idle',
  productCountInCart: 0,
  availablStock: 0,
} as ProductState;

const currentProductInfoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    changWishlistProductInCurrentProduct: (state: ProductState, action) => {
      const {
        payload: { wishlistProductId },
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductId,
        },
      };
    },
    removeFromWishlistInCurrentProduct: (state: ProductState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductId: null,
        },
      };
    },
    resetProduct: () => {
      const postalCode = sessionStorage.getItem('postalCode') || '';
      return { ...initialState, postalCode };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getProductInfo.fulfilled.type,
        (state: ProductState, action: PayloadAction<Product>) => {
          const { duration, isAvailable, ownerType, shopInfo } = action.payload;
          const payloadData = action.payload;
          const checkProductOwner = ownerType === productOwnerTypes.OWNER_TYPE_SHOP;
          if (checkProductOwner) {
            const { deliveryDurationTo } = shopInfo || {};
            payloadData.deliveryDate = calculateDeliveryDate(deliveryDurationTo);
          } else if (typeof duration !== 'number' && isAvailable) {
            const { durationTo } = duration || {};
            payloadData.storageCity = duration || {};
            payloadData.deliveryDate = calculateDeliveryDate(durationTo);
          }

          return {
            ...state,
            data: { ...state.data, ...payloadData },
            status: 'success',
          };
        }
      )
      .addCase(getProductInfo.pending.type, (state: ProductState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getProductInfo.rejected.type, (state: ProductState, action: any) => {
        const { data } = action?.payload || {};
        const { details: { code = null } = {} } = data || {};

        return {
          ...state,
          status: 'failed',
          getProductInfoErrorCode: code,
        };
      })
      .addCase(getProductDurationThunk.fulfilled.type, (state: ProductState, action: any) => {
        const { duration, pickupDuration } = action.payload;
        return {
          ...state,
          data: {
            ...state.data,
            duration,
            pickupDuration,
          },
          durationGetLoading: 'success',
        };
      })
      .addCase(getProductDurationThunk.pending.type, (state: ProductState) => {
        return {
          ...state,
          durationGetLoading: 'loading',
        };
      })
      .addCase(getProductDurationThunk.rejected.type, (state: ProductState) => {
        return {
          ...state,
          durationGetLoading: 'failed',
        };
      })
      .addCase(getProductCountInCartThunk.fulfilled.type, (state: ProductState, action: any) => {
        const { countInCart: productCountInCart = 0, stock: availablStock } = action.payload;
        return {
          ...state,
          productCountInCart,
          availablStock,
        };
      });
  },
});

export const useCurrentProductInfoData = (): ProductState =>
  useAppSelector((state: RootState) => state.currentProduct.info);

export const {
  resetProduct,
  changWishlistProductInCurrentProduct,
  removeFromWishlistInCurrentProduct,
} = currentProductInfoSlice.actions;

export default currentProductInfoSlice.reducer;
