import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { REACT_APP_BUYER_URL } from 'constants/config';

export const BoxBerryPointsApi = createApi({
  reducerPath: 'boxBerryApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_BUYER_URL}`,
  }),
  keepUnusedDataFor: 600000,
  endpoints: builder => ({
    getBoxBerryPoints: builder.query({
      query: () => 'boxberry-points',
    }),
  }),
});
export const { useGetBoxBerryPointsQuery } = BoxBerryPointsApi;
