const handleImageError = (
  e: React.SyntheticEvent<HTMLImageElement, Event>,
  path: string | null,
  defaultImage?: string
) => {
  if (e.currentTarget.src !== path && path) {
    e.currentTarget.src = path;
  } else if (defaultImage) {
    e.currentTarget.src = defaultImage;
  }
};

export default handleImageError;
