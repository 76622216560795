import { useEffect } from 'react';
import { Grid, Toolbar } from '@mui/material';

import Fb from 'components/FB';
import Header from 'sections/Header';
import Footer from 'sections/Footer';

import AppLayout from './AppLayout';
// import MaxMarketMobileLoading from 'components/MaxMarketMobileLoading';
import Maintenance from 'pages/Maintenance';
import { useMaintenance } from 'store/maintenance';
import { useAppDispatch } from 'store/hooks';
import { setMaintenanceHide } from 'store/maintenance';

const PagesWrapper = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const maintenance = useMaintenance();

  useEffect(() => {
    return () => {
      dispatch(setMaintenanceHide());
    };
  }, []);

  return (
    <Fb column className="h-100-vh">
      {maintenance ? (
        <Maintenance />
      ) : (
        <>
          {/* <MaxMarketMobileLoading /> */}
          <div id="header">
            <Toolbar>
              <Header />
            </Toolbar>
            <Toolbar />
          </div>
          <Grid container justifyContent="center" sx={{ width: '100%' }}>
            <Grid
              item
              sx={{ width: '100%', maxWidth: '1180px' }}
              className="mobile_container_grid main-wrap"
            >
              <AppLayout />
            </Grid>
            <Footer />
          </Grid>
        </>
      )}
    </Fb>
  );
};

export default PagesWrapper;
