import { NavLink } from 'react-router-dom';

import Fb from 'components/FB';

import './styles.scss';

const RegistrationAndLoginButtons = (): JSX.Element => {
  return (
    <Fb alignCenter justifyCenter className="registration-login-buts-wrap">
      <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/sign-in">
        Войти
      </NavLink>
      <span className="separate"> | </span>
      <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="/sign-up">
        Регистрация
      </NavLink>
    </Fb>
  );
};

export default RegistrationAndLoginButtons;
