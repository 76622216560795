const keyCode = {
  BACKSPACE: 8,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  DELETE: 46,
  SPACEBAR: 3,
};

export default keyCode;

export const numInputs = 4;
