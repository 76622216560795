import { Category } from 'store/catalog/types';

export const subCategoryDataGroups = (groupData: Array<Category>) => {
  const result = [];
  let currentSubArray = [];
  const groupSize = Math.ceil(groupData.length / 2);

  for (const item of groupData) {
    currentSubArray.push(item);
    if (currentSubArray.length === groupSize) {
      result.push(currentSubArray);
      currentSubArray = [];
    }
  }
  if (currentSubArray.length > 0) {
    result.push(currentSubArray);
  }

  return result;
};

export default subCategoryDataGroups;
