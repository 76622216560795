import { combineReducers } from 'redux';
import categories from './BrandCategories';
import info from './BrandInfo';

const BrandReducer = combineReducers({
  categories,
  info,
});

export default BrandReducer;
