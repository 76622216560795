import { createSlice, createAsyncThunk, current, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { getRequests, handleNewRequest, handleMakeIssueSeen } from './api';
import { statusTypes } from 'constants/requestTypes';

import { RequestListState } from './types';

export const getRequestsThunk = createAsyncThunk('getRequests', getRequests);

export const handleNewRequestThunk = createAsyncThunk('newRequest', handleNewRequest);

export const handleMakeIssueSeenThunk = createAsyncThunk('makeIssueSeen', handleMakeIssueSeen);

const initialState: RequestListState = {
  data: [],
  meta: {},
  newRequestStatus: 'idle',
};

const RequestsListSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetNewRequestSatatus(state: RequestListState) {
      return {
        ...state,
        newRequestStatus: 'idle',
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getRequestsThunk.fulfilled.type,
        (state: RequestListState, action: PayloadAction<RequestListState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(handleNewRequestThunk.fulfilled.type, (state: RequestListState, action: any) => {
        state = current(state);
        const { payload, meta } = action;
        const { issueId } = payload;

        const { total } = state.meta;
        const { is_new } = statusTypes;

        return {
          ...state,
          newRequestStatus: 'success',
          meta: { ...state.meta, total: total + 1 },
          data: [{ id: issueId, ...meta.arg.data, status: is_new }, ...state.data].slice(0, 15),
        };
      })
      .addCase(handleNewRequestThunk.pending.type, (state: RequestListState) => {
        return {
          ...state,
          newRequestStatus: 'loading',
        };
      })
      .addCase(handleNewRequestThunk.rejected.type, (state: RequestListState) => {
        return {
          ...state,
          newRequestStatus: 'failed',
        };
      })
      .addCase(handleMakeIssueSeenThunk.fulfilled.type, (state: RequestListState, action: any) => {
        state = current(state);
        const { issueId } = action.meta.arg;
        const { in_process } = statusTypes;

        const newData = state.data.map(request => {
          if (request.id === issueId) {
            return { ...request, status: in_process };
          }
          return request;
        });

        return {
          ...state,
          data: newData,
        };
      });
  },
});

export const useRequestsData = (): RequestListState => {
  const reducerState = useAppSelector((state: RootState) => state.request.requests);
  return reducerState;
};

export const { reset, resetNewRequestSatatus } = RequestsListSlice.actions;

export default RequestsListSlice.reducer;
