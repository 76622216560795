import { createSlice, createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import { REACT_APP_SELLER_URL } from 'constants/config';
import { read, create } from 'utils/api';

import { Balance, NewInvoiceRequestData } from './types';

interface Params {
  page: number;
  startDate?: string;
  endDate?: string;
}
const getBalance = async ({ page, startDate, endDate }: Params) => {
  let url = `${REACT_APP_SELLER_URL}/sellers/balance-history?page=${page}`;
  if (startDate) {
    url += `&startDate=${startDate}`;
  }
  if (endDate) {
    url += `&endDate=${endDate}`;
  }
  const response = await read(url);
  return response;
};

const handleNewInvoiceRequest = (data: NewInvoiceRequestData, { dispatch }) => {
  return create(`${REACT_APP_SELLER_URL}/money-requests`, data).then(response => {
    dispatch(getBalanceHistoryThunk({ page: 1 }));
    return response;
  });
};

export const handleNewInvoiceRequestThunk = createAsyncThunk(
  'seller/money-request',
  handleNewInvoiceRequest
);

export const getBalanceHistoryThunk = createAsyncThunk('seller/balance', getBalance);

const initialState: Balance = {
  data: [],
  meta: {},
};

const BalanceSlice = createSlice({
  initialState,
  name: 'balance',
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBalanceHistoryThunk.fulfilled.type, (state: Balance, action: AnyAction) => {
      const { data, meta } = action.payload;
      return {
        ...state,
        data,
        meta,
      };
    });
  },
});

export default BalanceSlice.reducer;
