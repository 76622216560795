import { createSlice, createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import { REACT_APP_SELLER_URL } from 'constants/config';
import { read } from 'utils/api';

import { SellerOrders } from './types';

const getSellerOrders = async (page: number) => {
  const response = await read(`${REACT_APP_SELLER_URL}/orders?page=${page}`);
  return response;
};

export const getSellerOrdersThunk = createAsyncThunk('seller/getOrders', getSellerOrders);

const getSellerOneOrder = async (id: number) => {
  const response = await read(`${REACT_APP_SELLER_URL}/orders/${id}`);
  return response;
};

export const getSellerOneOrderThunk = createAsyncThunk('seller/getOneOrder', getSellerOneOrder);

const initialState: SellerOrders = {
  data: [],
  oneOrder: {
    id: 0,
    status: 0,
    notes: '',
    buyerName: '',
    buyerPhone: 0,
    countryCode: '',
    deliveryMethod: 0,
    deliveryAddress: '',
    products: {
      data: [],
    },
  },
  meta: {},
};

const SellerOrdersList = createSlice({
  name: 'seller/orders',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSellerOrdersThunk.fulfilled.type, (state: SellerOrders, action: AnyAction) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          data,
          meta,
        };
      })
      .addCase(getSellerOneOrderThunk.fulfilled.type, (state: SellerOrders, action: any) => {
        const { payload: oneOrder } = action;
        return {
          ...state,
          oneOrder,
        };
      });
  },
});

export const { reset } = SellerOrdersList.actions;

export default SellerOrdersList.reducer;
