import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_SELLER_URL } from 'constants/config';

import { Statistics } from './types';

const initialState: Statistics = {
  bestCategories: [],
  revenue: {
    currentYear: {
      data: [],
    },
    previousYear: {
      data: [],
    },
  },
  ordersCount: {
    currentYear: {
      data: [],
    },
    previousYear: {
      data: [],
    },
  },
};

const getBestCategories = async () => {
  const response = await read(`${REACT_APP_SELLER_URL}/metrics/best-categories?limit=4`);
  return response;
};

export const getBestCategoriesThunk = createAsyncThunk(
  'metrics/best-categories',
  getBestCategories
);

const getRevenue = async () => {
  const response = await read(`${REACT_APP_SELLER_URL}/metrics/income-by-year`);
  return response;
};

export const getRevenueThunk = createAsyncThunk('metrics/income-by-year', getRevenue);

const getOrdersCount = async () => {
  const response = await read(`${REACT_APP_SELLER_URL}/metrics/orders-number`);
  return response;
};

export const getOrdersCountThunk = createAsyncThunk('metrics/orders-number', getOrdersCount);

const StatisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getBestCategoriesThunk.fulfilled.type, (state: Statistics, action: any) => {
        const { data } = action.payload;
        return {
          ...state,
          bestCategories: data,
        };
      })
      .addCase(getRevenueThunk.fulfilled.type, (state: Statistics, action: any) => {
        return {
          ...state,
          revenue: action.payload,
        };
      })
      .addCase(getOrdersCountThunk.fulfilled.type, (state: Statistics, action: any) => {
        return {
          ...state,
          ordersCount: action.payload,
        };
      });
  },
});

export const { reset } = StatisticsSlice.actions;

export default StatisticsSlice.reducer;
