import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { create } from 'utils/api';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { authStorage } from 'store/storage';
import { setRedirectionUrl } from 'store/routerRedirect';
import { Status } from 'store/types';
import { moveProductToTheWishlistAfterOpeningThePageAction } from 'store/wishlist';
import { bulkAddProductsInShoppingCartInlogin } from 'store/cart';
import userTypes from 'constants/userTypes';
import ROUTES from 'constants/routes';
import { REACT_APP_BUYER_URL } from 'constants/config';
import AccountTabs from 'constants/accountTabs';
import { accountPageByType } from 'helpers/routeHelper';
import handleErrorMessage from 'helpers/handleErroreMessage';
import resetStoreOnLogout from 'helpers/resetStoreOnLogout';
import {
  clearbuyNowData,
  returnShoppingCartPreviousItemsState,
} from 'helpers/returnShoppingCartItemsPreviouesState';

import { LoginResponse, LogoutParams } from './types';

export interface LoginState {
  userData: LoginResponse;
  status: Status;
  isDeletedUser: boolean;
  loginErrorMessage: null | string;
}

const initialState: LoginState = {
  userData: authStorage.get(),
  status: 'idle',
  isDeletedUser: false,
  loginErrorMessage: null,
};

export const checkOTP = async (params, { dispatch, rejectWithValue }: any) => {
  try {
    const { redirectionUrl, countryCode, otp, phoneNumber, enableDurationPostalCode } = params;

    const response = await create(`${REACT_APP_BUYER_URL}/login`, {
      countryCode,
      otp,
      phoneNumber,
    });
    authStorage.set(response);

    const { userType } = response as any;

    if (userType === +userTypes.buyer) {
      await bulkAddProductsInShoppingCartInlogin(enableDurationPostalCode);
      if (redirectionUrl) {
        dispatch(moveProductToTheWishlistAfterOpeningThePageAction(true));
        dispatch(setRedirectionUrl(redirectionUrl));
      } else {
        dispatch(setRedirectionUrl(ROUTES.DASHBOARD));
      }
    } else {
      dispatch(setRedirectionUrl(accountPageByType(AccountTabs.PERSONAL_INFO)));
    }
    clearbuyNowData();

    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const checkOTPLoginThunk = createAsyncThunk('login/checkOtp', checkOTP);

const logout = async ({ enableDurationPostalCode, goToSignIn }: LogoutParams, { dispatch }) => {
  const { refreshToken: token, userType } = authStorage.get();
  await returnShoppingCartPreviousItemsState({
    getShoppingCart: false,
    refreshShoppingCartFromChangeProductStatus: false,
    enableDurationPostalCode,
  });
  await create(`${REACT_APP_BUYER_URL}/logout`, { token });
  resetStoreOnLogout(userType, dispatch);
  goToSignIn && goToSignIn();
};

export const logoutThunk = createAsyncThunk('logout', logout);

export const LoginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {
    resetLogin: () => initialState,
    resetLoginErrorMessage: (state: LoginState) => {
      const { loginErrorMessage } = initialState;
      return { ...state, loginErrorMessage };
    },
    setIsDeletedUser: (state: LoginState, action: PayloadAction<boolean>) => {
      state.isDeletedUser = action.payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(checkOTPLoginThunk.pending.type, (state: LoginState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(checkOTPLoginThunk.fulfilled.type, (state: LoginState, action: AnyAction) => {
        const { payload } = action;
        return {
          ...state,
          status: 'success',
          userData: payload,
        };
      })
      .addCase(checkOTPLoginThunk.rejected.type, (state: LoginState, action: AnyAction) => {
        const loginErrorMessage = handleErrorMessage(action.payload);
        return {
          ...state,
          loginErrorMessage,
          status: 'failed',
        };
      })
      .addCase(logoutThunk.fulfilled.type, (state: LoginState) => {
        authStorage.remove();
        return {
          ...state,
          userData: {},
        };
      }),
});

export const useLoginData = (): LoginState => {
  const reducerState = useAppSelector((state: RootState) => state.login);
  return reducerState;
};

export const { resetLogin, resetLoginErrorMessage, setIsDeletedUser } = LoginSlice.actions;
export default LoginSlice.reducer;
