import { createSlice, createAsyncThunk, PayloadAction, current } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { getIssueMessages, handleNewMessage } from './api';
import { statusTypes } from 'constants/requestTypes';

import { OneRequestState } from './types';

export const getIssueMessagesThunk = createAsyncThunk('getIssueMessages', getIssueMessages);

export const handleNewMessageThunk = createAsyncThunk('newMessage', handleNewMessage);

const initialState: OneRequestState = {
  data: [],
  meta: {},
  status: 'idle',
};

const OneRequestsSlice = createSlice({
  name: 'oneRequest',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getIssueMessagesThunk.fulfilled.type,
        (state: OneRequestState, action: PayloadAction<OneRequestState>) => {
          const { data, meta } = action.payload;
          state = current(state);
          return {
            ...state,
            data: [...state.data, ...data],
            meta,
            status: 'success',
          };
        }
      )
      .addCase(getIssueMessagesThunk.pending.type, (state: OneRequestState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getIssueMessagesThunk.rejected.type, (state: OneRequestState) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(handleNewMessageThunk.fulfilled.type, (state: OneRequestState, action: any) => {
        const {
          payload,
          meta: {
            arg: {
              data: { files, message },
            },
          },
        } = action;

        const { issueId: id } = payload;
        const { is_new } = statusTypes;

        return {
          ...state,
          data: [
            { ...payload, id, message, files: { data: files }, status: is_new },
            ...state.data,
          ],
        };
      });
  },
});

export const useOneRequestsData = (): OneRequestState => {
  const reducerState = useAppSelector((state: RootState) => state.request.oneRequest);
  return reducerState;
};

export const { reset } = OneRequestsSlice.actions;

export default OneRequestsSlice.reducer;
