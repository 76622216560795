import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState: false,
  reducers: {
    setMaintenanceShow: () => {
      return true;
    },
    setMaintenanceHide: () => {
      return false;
    },
  },
});

export const useMaintenance = () => {
  const reducerState = useAppSelector((state: RootState) => state.maintenance);
  return reducerState;
};

export default maintenanceSlice.reducer;
export const { setMaintenanceShow, setMaintenanceHide } = maintenanceSlice.actions;
