import { createAsyncThunk, createSlice, AnyAction } from '@reduxjs/toolkit';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { Status } from 'store/types';

import { BrandInfo } from './types';

interface BrandInfoInterface {
  data: BrandInfo;
  status: Status;
}

const initialState = { data: {}, status: 'idle' } as BrandInfoInterface;

const getBrandInfo = id => read(`${REACT_APP_BUYER_URL}/brands/${id}`);

export const getBrandInfoAction = createAsyncThunk('currentBrand/info', getBrandInfo);

const BrandInfoSlice = createSlice({
  name: 'brandInfo',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(
        getBrandInfoAction.fulfilled.type,
        (state: BrandInfoInterface, action: AnyAction) => {
          return {
            ...state,
            data: action.payload,
            status: 'success',
          };
        }
      )
      .addCase(getBrandInfoAction.pending.type, (state: BrandInfoInterface) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getBrandInfoAction.rejected.type, (state: BrandInfoInterface) => {
        return { ...state, status: 'failed' };
      });
  },
});

export const useBrandInfoData = (): BrandInfoInterface => {
  const reducerState = useAppSelector((state: RootState) => state.currentBrand.info);
  return reducerState;
};

export const { reset } = BrandInfoSlice.actions;
export default BrandInfoSlice.reducer;
