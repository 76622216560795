import { ReactChild, ReactElement } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import customTheme from './customTheme';

interface ThemeProviderProps {
  children: ReactChild;
}

const ThemeProvider = ({ children }: ThemeProviderProps): ReactElement => {
  return (
    <MUIThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
};

export default ThemeProvider;
