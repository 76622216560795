export const creatorTypes = {
  admin: 1,
  buyer: 2,
  seller: 3,
};

export const statusTypes = {
  is_new: 1,
  in_process: 2,
  admin_wrote: 3,
  buyer_wrote: 4,
  seller_wrote: 5,
  completed: 6,
};

export const statusTypesOrderComplated = (status: number) => {
  switch (status) {
    case 2:
    case 13:
    case 17:
    case 20:
      return status;
  }
};
