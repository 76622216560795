import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Grid, Typography, Link, Box, Skeleton } from '@mui/material';

import {
  getContactInformationThunk,
  useContactInformationData,
} from 'store/ContactInformation/ContactInformation';
import { ContactGroupInformationType } from 'store/ContactInformation/type';
import handleImageError from 'helpers/handleImageError';

import useCheckScreen from 'utils/useCheckScreen';
import { FooterLinkInterface, footerLinks, footerIcons } from './constants';
import ROUTES from 'constants/routes';
import Fb from 'components/FB';
import MobileFooter from 'sections/Footer/MobileFooter/MobileFooter';

import PaymentOptionsImage from 'assets/icons/payment-options.svg';
import PhoneIcon from 'assets/icons/phoneFooter.svg';
import MaxMarketLogo from 'assets/icons/maxmarket-header-logo.svg';
import defaultImg from 'assets/icons/defaultImg.svg';

import './style.scss';

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContactInformationThunk());
  }, []);

  const { DASHBOARD, REQUISITES, SECURITY } = ROUTES;
  const { forBuyers, newsCompanies, forPartners }: any = footerLinks;
  const { AddressMarkIcon, MailIcon } = footerIcons;
  const isDesktop = useCheckScreen({ type: 'desktop' });

  const { data, contactInfoStatus } = useContactInformationData();

  const { socialMediaData, phoneData, emailData, adressData } = data as ContactGroupInformationType;

  return (
    <>
      {isDesktop ? (
        <Grid container className="footer mobile_container_grid_h">
          <Grid item sx={{ maxWidth: '100%' }}>
            <Fb column alignStart className="footer-text-wrap">
              <Fb
                sx={{ p: 0, mb: 2, width: '80%', height: 'auto' }}
                onClick={() => navigate(DASHBOARD)}
              >
                <img
                  className="cursor-pointer"
                  src={MaxMarketLogo}
                  alt="MaxMarket Logo"
                  width="258px"
                />
              </Fb>
              <Typography className="footer-text">
                МаксМаркет — это новый современный маркетплейс, где вы можете не только покупать
                товары, но и в 2 клика стать продавцом. Покупать или продавать здесь просто и
                удобно. Вас ждут лучшие цены, безупречный сервис и быстрая доставка. МаксМаркет -
                твой шоппинг без колючек!
              </Typography>
            </Fb>
            <Grid item xs={12} sx={{ height: '400px', minWidth: '100%', maxHeight: '1440px' }}>
              <Grid
                container
                columns={20}
                justifyContent="space-between"
                sx={{ paddingTop: '30px', width: '100%' }}
              >
                <Grid item>
                  <Fb column alignStart>
                    <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                      Покупателям
                    </Typography>
                    {forBuyers.map(({ link, label }: FooterLinkInterface, index) => (
                      <NavLink
                        to={link}
                        className="footer_title"
                        key={'footer-key-forBuyers-' + index}
                      >
                        {label}
                      </NavLink>
                    ))}
                  </Fb>
                  <Grid item>
                    <Fb column alignStart sx={{ marginTop: '40px' }} className="Requisites">
                      <NavLink to={SECURITY}>Охрана труда</NavLink>
                    </Fb>
                  </Grid>
                </Grid>
                <Grid item>
                  <Fb column alignStart>
                    <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                      Партнерам
                    </Typography>
                    {forPartners.map(({ link, label }: FooterLinkInterface, index) => (
                      <NavLink
                        className="footer_title"
                        key={'footer-key-forPartners-' + index}
                        to={link}
                      >
                        {label}
                      </NavLink>
                    ))}
                  </Fb>
                  {/* <Fb column alignStart>
                    <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                      Магазинам
                    </Typography>
                    {forStores.map(({ link, label }: FooterLinkInterface, index) => (
                      <NavLink
                        to={link}
                        className="footer_title"
                        key={'footer-key-forBuyers-' + index}
                      >
                        {label}
                      </NavLink>
                    ))}
                  </Fb> */}

                  <Grid item>
                    <Fb column alignStart sx={{ marginTop: '25px' }}>
                      <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                        Новости
                      </Typography>
                      {newsCompanies.map(({ link, label }: FooterLinkInterface, index) => (
                        <NavLink
                          to={link}
                          className="footer_title"
                          key={'footer-key-forBuyers-' + index}
                        >
                          {label}
                        </NavLink>
                      ))}
                    </Fb>
                  </Grid>

                  <Grid item>
                    <Fb column alignStart sx={{ marginTop: '20px' }} className="Requisites">
                      <NavLink to={REQUISITES}>Реквизиты</NavLink>
                    </Fb>
                  </Grid>
                </Grid>
                <Grid item>
                  <Fb column alignStart>
                    {(phoneData || adressData || emailData) && (
                      <Typography sx={{ color: '#292929' }} fontSize={20} fontWeight={500}>
                        Наши контакты
                      </Typography>
                    )}
                    {adressData && (
                      <Fb sx={{ margin: '16px 0px 10px 0px' }} className="contact">
                        <img src={AddressMarkIcon} alt="phone" width={16} height={16} />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {contactInfoStatus === 'loading' ? (
                            <Skeleton
                              animation="wave"
                              height="100%"
                              width="200px"
                              sx={{
                                backgroundColor: 'grey.150',
                                marginLeft: '10px',
                              }}
                            />
                          ) : (
                            adressData?.map(item => {
                              const { id, key, text } = item;
                              return (
                                <Typography
                                  key={id}
                                  sx={{ marginLeft: '7px' }}
                                  fontSize={14}
                                  color="#4F4F4F"
                                >
                                  {key}: {text}
                                </Typography>
                              );
                            })
                          )}
                        </Box>
                      </Fb>
                    )}

                    {emailData && (
                      <div className="contact_wrap">
                        <img src={MailIcon} alt="phone" width={16} height={16} />
                        <div>
                          {contactInfoStatus === 'loading' ? (
                            <>
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                              <Skeleton
                                animation="wave"
                                height="100%"
                                width="200px"
                                sx={{
                                  backgroundColor: 'grey.150',
                                  marginLeft: '10px',
                                }}
                              />
                            </>
                          ) : (
                            emailData?.map(item => {
                              const { text, key, id } = item;
                              return (
                                <Link key={id} href={`mailto: ${text}`} underline="none">
                                  <Typography
                                    sx={{ py: 1, marginLeft: '7px' }}
                                    fontSize={14}
                                    color="#4F4F4F"
                                  >
                                    {key}: {text}
                                  </Typography>
                                </Link>
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}

                    {phoneData && (
                      <div className="phone_wrap">
                        <img src={PhoneIcon} alt="phone" width={16} height={16} />
                        <div>
                          {contactInfoStatus === 'loading' ? (
                            <Skeleton
                              animation="wave"
                              height="100%"
                              width="200px"
                              sx={{
                                backgroundColor: 'grey.150',
                                marginLeft: '10px',
                              }}
                            />
                          ) : (
                            phoneData?.map(item => {
                              const { text, key } = item;
                              return (
                                <Link key={item?.id} href={`tel:${text}`} underline="none">
                                  <Typography
                                    sx={{ py: 2, marginLeft: '7px' }}
                                    fontSize={14}
                                    color="#4F4F4F"
                                  >
                                    {key}: {text}
                                  </Typography>
                                </Link>
                              );
                            })
                          )}
                        </div>
                      </div>
                    )}
                  </Fb>
                  <Typography
                    sx={{
                      color: '#292929',
                      marginTop: phoneData || adressData || emailData ? '35px' : '0px',
                    }}
                    fontSize={20}
                    fontWeight={500}
                  >
                    Найти нас на:
                  </Typography>
                  <Fb
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      marginTop: '5px',
                    }}
                  >
                    {contactInfoStatus === 'loading' ? (
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Skeleton
                          animation="wave"
                          height="60px"
                          width="40px"
                          sx={{
                            backgroundColor: 'grey.150',
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height="60px"
                          width="40px"
                          sx={{
                            backgroundColor: 'grey.150',
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height="60px"
                          width="40px"
                          sx={{
                            backgroundColor: 'grey.150',
                          }}
                        />
                        <Skeleton
                          animation="wave"
                          height="60px"
                          width="40px"
                          sx={{
                            backgroundColor: 'grey.150',
                          }}
                        />
                      </Box>
                    ) : (
                      socialMediaData?.map(item => {
                        const { link, filePath } = item;
                        return (
                          <Link
                            key={item.id}
                            className="vk-fb"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={filePath || defaultImg}
                              alt="logo"
                              width="20px"
                              height="20px"
                              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                handleImageError(e, filePath, defaultImg);
                              }}
                            />
                          </Link>
                        );
                      })
                    )}
                  </Fb>
                </Grid>
              </Grid>
              <Box
                color="#4F4F4F"
                sx={{
                  pt: 3,
                  height: '100px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  marginTop: '30px',
                }}
              >
                <img className="payment-img" src={PaymentOptionsImage} alt="payment options" />
                <div className="hr"></div>
                <p className="footer-end">
                  © 2016-{new Date().getFullYear()} Маркетплейс нового поколения “MAXMARKET”
                </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <MobileFooter />
      )}
    </>
  );
};

export default Footer;
