import './style.scss';

import { ReactComponent as SpinnerSVG } from 'assets/icons/spinner.svg';

const Spinner = () => {
  return (
    <div className="maxmarket-spinner">
      <SpinnerSVG />
    </div>
  );
};

export default Spinner;
