import maintenance from 'assets/icons/maintenance.svg';

import './style.scss';

const Maintenance = () => {
  return (
    <div className="maintenance">
      <img src={maintenance} alt="обслуживания" />
      <p className="title">Ведутся технические работы</p>
      <p className="description">
        Уважаемые пользователи! Сообщаем Вам, что на сайте ведутся технические работы. Некоторая
        информация временно недоступна. Приносим свои извинения за возможные неудобства.
      </p>
    </div>
  );
};

export default Maintenance;
