import moment from 'moment';

import deliveryDates, { deliveryDatesLabels, PARTNER_DURATION_HOUR } from 'constants/deliveryDates';
import { REACT_APP_V2_PUBLISH_DATE } from 'constants/config';

const {
  DURATION_UNTIL_3_HOURS,
  DURATION_UNTIL_7_DAYS,
  DURATION_UNTIL_Tomorrow,
  DURATION_TODAY,
  DURATION_UNTIL_2_DAYS,
  DURATION_UNTIL_3_DAYS,
} = deliveryDates;

export const checkDateisBetween = (
  deliveryDate: string | null,
  typeDeliveryDate: number,
  afterTimeDate?: string
) => {
  const format = 'HH:mm:ss';
  let beforeTimeDate = '10:00:00';

  if (typeDeliveryDate === DURATION_TODAY) {
    beforeTimeDate = '00:00:01';
  }
  const date = deliveryDate ? moment(deliveryDate).format(format) : null;

  const beforeTime = moment(beforeTimeDate, format);
  const afterTime = moment(afterTimeDate || '16:00:00', format);
  const time: any = moment(date || new Date(), format);

  return time.isBetween(beforeTime, afterTime, 'hours');
};

const checkdeliveryDate = (duration: number, type: 'label' | 'duration' = 'label') => {
  if (!duration) {
    return type === 'label' ? deliveryDatesLabels[DURATION_UNTIL_7_DAYS] : DURATION_UNTIL_7_DAYS;
  }
  switch (duration) {
    case DURATION_UNTIL_3_HOURS: {
      if (checkDateisBetween(null, DURATION_UNTIL_3_HOURS)) {
        return type === 'label'
          ? deliveryDatesLabels[DURATION_UNTIL_3_HOURS]
          : DURATION_UNTIL_3_HOURS;
      } else {
        return type === 'label'
          ? deliveryDatesLabels[DURATION_UNTIL_Tomorrow]
          : DURATION_UNTIL_Tomorrow;
      }
    }
    case DURATION_TODAY: {
      if (checkDateisBetween(null, DURATION_TODAY, '18:00:00')) {
        return type === 'label' ? deliveryDatesLabels[DURATION_TODAY] : DURATION_TODAY;
      } else
        return type === 'label'
          ? deliveryDatesLabels[DURATION_UNTIL_Tomorrow]
          : DURATION_UNTIL_Tomorrow;
    }
    case DURATION_UNTIL_2_DAYS: {
      if (checkDateisBetween(null, DURATION_UNTIL_2_DAYS, '19:00:00')) {
        return type === 'label'
          ? deliveryDatesLabels[DURATION_UNTIL_2_DAYS]
          : DURATION_UNTIL_2_DAYS;
      } else {
        return type === 'label'
          ? deliveryDatesLabels[DURATION_UNTIL_3_DAYS]
          : DURATION_UNTIL_2_DAYS;
      }
    }
    default:
      return type === 'label' ? deliveryDatesLabels[duration] : duration;
  }
};

export default checkdeliveryDate;

export const checkDateOrderHistory = (
  deliveryDateTo: string | null,
  deliveryDateFrom: string | null
) => {
  let deliveryDateFormat = 'DD.MM.YYYY';

  if (!deliveryDateTo) {
    return !deliveryDateFrom
      ? null
      : moment(deliveryDateFrom).add(7, 'days').format(deliveryDateFormat);
  }

  const endDate = moment(deliveryDateTo);
  const startDate = moment(deliveryDateFrom);

  const duration = moment.duration(endDate.diff(startDate));
  const checkDateHour = duration.hours() && duration.hours() <= 3;
  const days = duration.days();

  if (checkDateHour && checkDateisBetween(deliveryDateTo, DURATION_UNTIL_3_HOURS, '19:00:00')) {
    deliveryDateFormat = 'DD.MM.YYYY HH:mm';
    return moment(endDate).format(deliveryDateFormat);
  } else if (checkDateHour) {
    startDate.add(1, 'days');
  } else if (days === 1 && !checkDateisBetween(deliveryDateFrom, DURATION_TODAY, '18:00:00')) {
    startDate.add(1, 'days');
  } else if (days === 2) {
    startDate.add(1, 'days');
  } else if (days === 3) {
    startDate.add(3, 'days');
  } else if (days === 7) {
    startDate.add(7, 'days');
  }

  return moment(startDate).format(deliveryDateFormat);
};

const getDaysText = (days: number) => {
  switch (days) {
    case 1: {
      return 'дня';
    }

    default:
      return 'дней';
  }
};

const getHoursText = (days: number) => {
  switch (days) {
    case 1:
      return 'часа';
    default:
      return 'часов';
  }
};

export const calculateDeliveryDate = (durationTo: number) => {
  const startTime = moment();
  const endTime = moment().add(durationTo || PARTNER_DURATION_HOUR, 'hours');
  const duration = moment.duration(
    moment(endTime, 'YYYY/MM/DD HH:mm').diff(moment(startTime, 'YYYY/MM/DD HH:mm'))
  );

  const days = duration.days();
  const hours = duration.hours();
  const months = duration.months();

  // const checkDateHour = hours && !days;

  // if (
  //   checkDateHour &&
  //   !checkDateisBetween(endTime.toString(), DURATION_UNTIL_3_HOURS, '19:00:00')
  // ) {
  //   days += 1;
  //   hours = 0;
  // }

  return (
    'до ' +
    [
      months ? months + ' ' + 'мес.' : '',
      days ? days + ' ' + getDaysText(+days) : '',
      hours ? hours + ' ' + getHoursText(+hours) : '',
    ]
      .filter(e => e)
      .join(', ')
  );
};

export const checkPublishDate = (createdAt: string) => {
  const createdAtDate: number = moment(createdAt, moment.defaultFormat).valueOf();
  const publishDate: number = moment(REACT_APP_V2_PUBLISH_DATE, moment.defaultFormat).valueOf();
  return createdAtDate - publishDate > 0;
};

export const checkDateOrderHistoryV2 = (
  deliveryDateTo: string | null,
  deliveryDateFrom: string | null
) => {
  let deliveryDateFormat = 'DD.MM.YYYY';

  if (!deliveryDateTo) {
    return !deliveryDateFrom
      ? null
      : moment(deliveryDateFrom).add(7, 'days').format(deliveryDateFormat);
  }

  const endDate = moment(deliveryDateTo);
  const startDate = moment(deliveryDateFrom);

  const duration = moment.duration(endDate.diff(startDate));

  const days = duration.days();
  const hours = duration.hours();
  const checkDateHour = hours && !days && hours <= 3;
  const deliveryDateToIsBetween = checkDateisBetween(
    deliveryDateTo,
    DURATION_UNTIL_3_HOURS,
    '19:00:00'
  );
  if (checkDateHour && deliveryDateToIsBetween) {
    deliveryDateFormat = 'DD.MM.YYYY HH:mm';
    return moment(endDate).format(deliveryDateFormat);
  }
  return moment(endDate).format(deliveryDateFormat);
};
