import { LazyExoticComponent, useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { withTransaction } from '@elastic/apm-rum-react';

import { LoginAndRegistrationRoute, PrivateRoute } from 'routes';

import routesMap from 'constants/routes';

type RouteParams = {
  component: LazyExoticComponent<any> | any;
  path?: string;
  isPrivate: boolean;
};

interface SwitchRouter {
  routes?: RouteParams[];
}

const renderRoute = (route: RouteParams, index: number): JSX.Element => {
  const { isPrivate, path, component: Component } = route;
  const { SIGN_IN, SIGN_UP } = routesMap;

  const element = isPrivate ? (
    <PrivateRoute component={Component} />
  ) : SIGN_IN === path || SIGN_UP === path ? (
    <LoginAndRegistrationRoute component={Component} />
  ) : (
    <Component />
  );

  return <Route key={index} path={path} element={element} />;
};

const SwitchRouter = ({ routes }: SwitchRouter): JSX.Element => {
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsInitialized(true);
  }, []);

  // Es stipvac em gnacel ays qaylin, for SEO, SSR (static index.html building)
  useLayoutEffect(() => {
    if (isInitialized) {
      window.location.reload();
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="*" element={<Navigate to={routesMap.NOT_FOUND} />} />
      {routes?.map(renderRoute)}
    </Routes>
  );
};

export default withTransaction('SwitchRouter', 'component')(SwitchRouter);
