import { forwardRef } from 'react';
import clsx from 'clsx';

import Box, { BoxProps } from '@mui/material/Box';

import useFlexStyles from './styles';

interface FbProps {
  className?: string;
  main?: boolean;
  row?: boolean;
  grow?: boolean;
  wrap?: boolean;
  inline?: boolean;
  noWrap?: boolean;
  column?: boolean;
  noGrow?: boolean;
  shrink?: boolean;
  alignEnd?: boolean;
  noShrink?: boolean;
  rowReverse?: boolean;
  alignStart?: boolean;
  justifyEnd?: boolean;
  alignCenter?: boolean;
  justifyStart?: boolean;
  justifyCenter?: boolean;
  justifyAround?: boolean;
  justifyEvenly?: boolean;
  justifyBetween?: boolean;
  columnReverse?: boolean;
  alignStretch?: boolean;
  flex1?: boolean;
  children?: JSX.Element | JSX.Element[] | boolean | string | number;
  onClick?: any;
}

const Fb = forwardRef(function Fb(props: FbProps & BoxProps, ref) {
  const flexClasses = useFlexStyles();
  const {
    row,
    wrap,
    grow,
    column,
    noGrow,
    shrink,
    noWrap,
    inline,
    children,
    alignEnd,
    noShrink,
    className,
    rowReverse,
    alignStart,
    justifyEnd,
    alignCenter,
    justifyStart,
    justifyCenter,
    justifyAround,
    justifyEvenly,
    justifyBetween,
    columnReverse,
    alignStretch,
    flex1,
    onClick,
    ...otherProps
  } = props;

  const combinedClassNames = clsx(className, {
    [flexClasses.main]: !inline,
    [flexClasses.inline]: inline,
    [flexClasses.row]: row,
    [flexClasses.wrap]: wrap,
    [flexClasses.grow]: grow,
    [flexClasses.column]: column,
    [flexClasses.noGrow]: noGrow,
    [flexClasses.shrink]: shrink,
    [flexClasses.noWrap]: noWrap,
    [flexClasses.alignEnd]: alignEnd,
    [flexClasses.noShrink]: noShrink,
    [flexClasses.rowReverse]: rowReverse,
    [flexClasses.alignStart]: alignStart,
    [flexClasses.justifyEnd]: justifyEnd,
    [flexClasses.alignCenter]: alignCenter,
    [flexClasses.justifyStart]: justifyStart,
    [flexClasses.justifyCenter]: justifyCenter,
    [flexClasses.justifyAround]: justifyAround,
    [flexClasses.justifyEvenly]: justifyEvenly,
    [flexClasses.justifyBetween]: justifyBetween,
    [flexClasses.columnReverse]: columnReverse,
    [flexClasses.alignStretch]: alignStretch,
    [flexClasses.flex1]: flex1,
  });

  return (
    <Box ref={ref} {...otherProps} className={combinedClassNames} onClick={onClick}>
      {children}
    </Box>
  );
});

Fb.defaultProps = {
  className: '',
  main: undefined,
  row: false,
  wrap: false,
  grow: false,
  inline: false,
  column: false,
  noGrow: false,
  shrink: false,
  noWrap: false,
  noShrink: false,
  alignEnd: false,
  rowReverse: false,
  justifyEnd: false,
  alignStart: false,
  alignCenter: false,
  justifyStart: false,
  justifyCenter: false,
  justifyAround: false,
  justifyEvenly: false,
  justifyBetween: false,
  columnReverse: false,
  alignStretch: false,
  flex1: false,
  onClick: () => {
    return false;
  },
};

export default Fb;
