import { Box, Modal } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { ModalInterface } from './types';

import './style.scss';

const style = {
  position: { xs: 'relative', md: 'absolute' },
  top: { xs: '0', md: '50%' },
  left: { xs: '0', md: '50%' },
  width: { xs: '100%', md: 'inherit' },
  height: { xs: '100%', md: 'inherit' },
  transform: { xs: 'none', md: 'translate(-50%, -50%)' },
  minWidth: { xs: 'none', md: 400 },
  background: '#FEFFFF',
  borderRadius: '8px',
  padding: { xs: '16px', md: '40px' },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '&:focus-visible': {
    outline: 'none',
  },

  '& .close-modal': {
    position: 'absolute',
    width: 40,
    height: 40,
    cursor: 'pointer',
    zIndex: 1,
    top: {
      sm: '-20px',
      xs: '-20px',
    },
    right: {
      sm: '-20px',
      xs: '-20px',
    },
    '& svg': {
      width: 'inherit',
      height: 'inherit',
      '&:hover': {
        svg: {
          path: {
            stroke: '#828282',
          },
        },
      },
    },
  },
};

const CustomModal = ({
  open,
  onClose,
  children,
  className,
  isFullScreen = false,
  bodyClassName,
  showCloseIcon = true,
}: ModalInterface) => {
  return (
    <Modal open={open} onClose={onClose} className={className}>
      <Box sx={style} className={bodyClassName}>
        {showCloseIcon && (
          <span
            onClick={onClose}
            className={classNames('close-modal', {
              full_screen: isFullScreen,
            })}
          >
            <CloseIcon />
          </span>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
