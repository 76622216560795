import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';
import { debounce } from 'lodash';

import {
  getProductsSuggestions,
  resetProductsSuggestions,
  useProductListData,
} from 'store/products';
// import { setSearchCategoryId, useProductListData } from 'store/brandCategory';
// import { SearchBy } from 'store/brandCategory/types';
import ROUTES from 'constants/routes';
// import { getProductByCategoriesListUrl } from 'helpers/getProductByCategoriesList';
import { useCurrentPath } from 'utils/useCurrentPath';
import useFiltration from 'utils/useFiltration';

// import SelectCategories from './SelectCategories';

import { ReactComponent as SearchIcon } from 'assets/icons/search-icone.svg';
import defaultProductImage from 'assets/images/default-product-imag.png';

interface Props {
  keyword: string;
  setSearchKeyword: any;
  isMobile?: boolean;
  setShowSearchBar?: any;
}

const SearchAutocomplete = ({
  keyword,
  setSearchKeyword,
  setShowSearchBar,
  isMobile = false,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productSuggestions } = useProductListData();
  // const { categoryPageSearchBy } = useProductListData();
  const { pathname, search } = useLocation();
  const { clearFiltration } = useFiltration();
  const searchParams = new URLSearchParams(search);

  const { PRODUCT_BY_CATEGORIES_LIST, PRODUCTS } = ROUTES;

  const {
    path: currentPath,
    // params: currentPathParams
  } = useCurrentPath([{ path: PRODUCT_BY_CATEGORIES_LIST }, { path: PRODUCTS }]);

  useEffect(() => {
    if (
      currentPath === PRODUCTS
      //  || currentPath === PRODUCT_BY_CATEGORIES_LIST
    ) {
      if (searchParams.has('q')) {
        const query = searchParams.get('q');
        // if (categoryPageSearchBy === SearchBy.Auto) {
        //   dispatch(setSearchCategoryId(SearchBy.All));
        // }
        setSearchKeyword(query);
      } else {
        setSearchKeyword('');
      }
    } else {
      setSearchKeyword('');
    }
  }, [pathname, search]);

  const options = useMemo(() => {
    return (
      productSuggestions.map(({ name = '', pictures = [] }: any) => ({
        name,
        image: pictures[0] || defaultProductImage,
      })) || []
    );
  }, [productSuggestions]);

  const debouncedSearch = useCallback(
    debounce(keyword => {
      dispatch(
        getProductsSuggestions({
          keyword,
        })
      );
    }, 500),
    []
  );

  useEffect(() => {
    if (keyword) {
      debouncedSearch(keyword);
    } else {
      dispatch(resetProductsSuggestions());
    }
  }, [keyword]);

  const handleChange = value => {
    setSearchKeyword(value);
  };

  const handleSearchProducts = (q: string) => {
    // if (value && categoryPageSearchBy === SearchBy.Auto) {
    //   dispatch(setSearchCategoryId(SearchBy.All));
    // }

    // const page =
    //   currentPath === PRODUCT_BY_CATEGORIES_LIST && currentPathParams.categoryID
    //     ? getProductByCategoriesListUrl(currentPathParams.categoryID)
    //     : undefined;

    setShowSearchBar(false);
    const params = new URLSearchParams(search);
    params.set('page', '1');
    !!q?.trim() && params.set('q', q);

    if (params.has('bannerToken')) {
      params.delete('bannerToken');
    }
    clearFiltration();
    navigate(`${ROUTES.PRODUCTS}?${params.toString()}`, { replace: true });
  };

  const onEnter = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  // const checkCategoriesPage = currentPath === PRODUCT_BY_CATEGORIES_LIST;

  return (
    <Autocomplete
      autoComplete={false}
      sx={{
        '.MuiOutlinedInput-root': {
          // padding: checkCategoriesPage ? '0 10px 0 16px' : '0 10px',
          padding: '0 10px',
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      className="w-100"
      freeSolo
      disableCloseOnSelect={false}
      disableClearable
      options={options}
      onChange={(_, value: any) => {
        handleSearchProducts(value.name);
      }}
      filterOptions={option => option}
      getOptionLabel={option => option?.name || keyword}
      value={keyword}
      renderOption={(props, { name, image }: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img loading="lazy" width="30" src={image} alt="" />
          {name}
        </Box>
      )}
      onInputChange={(_, newInputValue: any) => {
        handleChange(newInputValue);
      }}
      renderInput={params => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {/* {currentPath === PRODUCT_BY_CATEGORIES_LIST && (
            <SelectCategories keyword={keyword} categoryID={currentPathParams.categoryID} />
          )} */}
          <TextField
            autoComplete="off"
            onKeyUp={onEnter}
            {...params}
            sx={
              isMobile
                ? {
                    // marginLeft: checkCategoriesPage ? '8px' : '0',
                    borderRadius: '8px',
                    border: '1px solid #C6C6C6 !important',
                    '& path': {
                      stroke: '#C6C6C6',
                    },
                  }
                : {}
            }
            placeholder="Искать товары..."
            InputProps={{
              ...params.InputProps,
              endAdornment: isMobile ? (
                <InputAdornment position="end">
                  <SearchIcon
                    style={{
                      cursor: 'pointer',
                    }}
                    width={20}
                    height={20}
                    onClick={() => handleSearchProducts(keyword)}
                  />
                </InputAdornment>
              ) : null,
            }}
          />
        </Box>
      )}
    />
  );
};

export default SearchAutocomplete;
