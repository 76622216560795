import warningYandexMapIcon from 'assets/icons/warningYandexMapIcon.svg';

import './styles.scss';

const ErrorViewYandexMap = () => {
  return (
    <div className="error-view-map-wrap">
      <img src={warningYandexMapIcon} alt="" />
      <p className="error-title">Извините!</p>
      <div className="error-description">
        <p>Страница не может загрузить яндекс карту,</p>
        <p>Попробуйте попозже</p>
      </div>
    </div>
  );
};

export default ErrorViewYandexMap;
