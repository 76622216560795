import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

import { GetOfferProductsParams, OfferProductsState } from './types';

const getOfferProducts = async ({
  itemCode,
  productId,
  postalCode,
  enableDurationPostalCode,
  lat,
  lng,
}: GetOfferProductsParams) => {
  const params = {
    itemCode,
    postalCode: undefined,
    lat,
    lng,
    productId,
    isSimilar: true,
  };
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  const url = enableDurationPostalCode
    ? `${REACT_APP_BUYER_URL_V2}/products`
    : `${REACT_APP_BUYER_URL}/similar-products/${productId}`;

  if (enableDurationPostalCode) {
    delete params.lat;
    delete params.lng;
    params.postalCode = selectedPostalCode;
  } else {
    delete params.productId;
    delete params.isSimilar;
  }

  return await read(url, { params });
};

export const getOfferProductsThunk = createAsyncThunk('offerProducts/get', getOfferProducts);

const initialState = { data: [], meta: {}, status: 'idle' } as OfferProductsState;

const offerProductsSlice = createSlice({
  name: 'offerProducts',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(
        getOfferProductsThunk.fulfilled.type,
        (state: OfferProductsState, action: PayloadAction<OfferProductsState>) => {
          const { data } = action.payload;
          return {
            ...state,
            data,
            status: 'success',
          };
        }
      )
      .addCase(getOfferProductsThunk.pending.type, (state: OfferProductsState) => {
        return {
          ...state,
          status: 'loading',
        };
      });
  },
});

export const useProductOffersData = (): OfferProductsState =>
  useAppSelector((state: RootState) => state.currentProduct.offerProducts);

export const { reset } = offerProductsSlice.actions;
export default offerProductsSlice.reducer;
