import React from 'react';

import { Button, Typography } from '@mui/material';

import Modal from 'components/Modal';
import Fb from 'components/FB';
import failureSvg from 'assets/icons/failure.svg';

import './styles.scss';

interface AccountRecoverModalProps {
  showAccountRecoverModal: boolean;
  toggleAccountRecoverModal: () => void;
  recoverAccountAction: () => void;
}

const AccountRecoverModal: React.FC<AccountRecoverModalProps> = ({
  showAccountRecoverModal,
  toggleAccountRecoverModal,
  recoverAccountAction,
}) => {
  return (
    <Modal open={showAccountRecoverModal} onClose={toggleAccountRecoverModal}>
      <Fb justifyEvenly alignCenter column gap="30px">
        <Fb justifyCenter alignCenter column gap="30px">
          <Fb alignCenter justifyCenter className="iconWrapper">
            <img src={failureSvg} alt="failure" />
          </Fb>
          <Typography fontSize={32} fontWeight={600} color="#292929" textAlign="center">
            Данный аккаунт удалён
          </Typography>
          <Typography textAlign="center" fontSize={16} fontWeight={400} color="#676767">
            Запрашиваемый вами аккаунт был удалён, его можно восстановить в течении 30 дней после
            удаления. Вы подтверждаете восстонавление данного аккаунта?
          </Typography>
        </Fb>
        <Fb gap="20px" width="100%">
          <Button
            variant="outlined"
            color="inherit"
            onClick={toggleAccountRecoverModal}
            className="recoverCancelButton"
          >
            Отменить
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={recoverAccountAction}
            sx={{ flex: 1 }}
          >
            Восстановить
          </Button>
        </Fb>
      </Fb>
    </Modal>
  );
};

export default AccountRecoverModal;
