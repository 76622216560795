import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

interface GeneralInternalSettingsInterface {
  postalCode: string;
}

const initialState: GeneralInternalSettingsInterface = {
  postalCode: '',
};

const GeneralInternalSettingsSlice = createSlice({
  name: 'generalInternalSettings',
  initialState,
  reducers: {
    setGeneralInternalSettings: (
      state: GeneralInternalSettingsInterface,
      action: PayloadAction<any>
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export default GeneralInternalSettingsSlice.reducer;

export const useGeneralInternalSettingsData = (
  notReceiveUpdates?: boolean
): GeneralInternalSettingsInterface =>
  useAppSelector((state: RootState) =>
    notReceiveUpdates ? initialState : state.generalInternalSettings
  );

export const { setGeneralInternalSettings } = GeneralInternalSettingsSlice.actions;
