import { useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { Stack, Typography, Button, InputAdornment } from '@mui/material';

import { useAppDispatch } from 'store/hooks';
import { checkPhonePreLoginThunk, resetPreLogin, usePreLoginData } from 'store/auth/preLogin';
import Form, { FormInput, useForm } from 'components/Form';
import Checkbox from 'components/custom/Checkbox';
import RegistrationAndLoginButtons from 'components/RegistrationAndLoginButtons';
import Fb from 'components/FB';
import { loginSchema } from 'resolvers';
import { defaultParamsPreRegistrationAndLogin } from 'constants/auth/preRegistrationAndLogin';
import ROUTES from 'constants/routes';
import formattedPhone from 'helpers/formattedPhone';

import Popup from './OTPverification';
import AccountRecoverModal from './AccountRecoverModal';

const LoginPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [agreeWithTermsChecked, setAgreeWithTermsChecked] = useState<boolean>(true);
  const [showAccountRecoverModal, setShowAccountRecoverModal] = useState(false);
  const [hideErrorMessage, setHideErrorMessage] = useState<boolean>(false);
  const { status, errorMessage, phoneIsValid, errorCode } = usePreLoginData();

  const { redirectionUrl = '' } = state || {};

  const toggleAccountRecoverModal = () => setShowAccountRecoverModal(!showAccountRecoverModal);

  const {
    watch,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<{
    phoneNumber?: string;
  }>({
    resolver: loginSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });
  useEffect(() => {
    return () => {
      dispatch(resetPreLogin());
    };
  }, []);
  const phoneNumber = watch('phoneNumber') || '';

  const onSubmit = () => {
    setHideErrorMessage(true);
    dispatch(resetPreLogin());
    const isDeleted = errorCode === 707;
    dispatch(
      checkPhonePreLoginThunk({
        phoneNumber: +phoneNumber,
        ...defaultParamsPreRegistrationAndLogin,
        isDeleted,
      })
    );
  };

  useEffect(() => {
    if (phoneNumber.length < 10) {
      setHideErrorMessage(false);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (errorCode === 707) {
      toggleAccountRecoverModal();
    }
  }, [errorCode]);

  useEffect(() => {
    if (!showAccountRecoverModal) {
      dispatch(resetPreLogin());
    }
  }, [showAccountRecoverModal]);

  const validationProps = useCallback(
    (field: string) => {
      const fieldError = errors?.[field];
      return {
        name: field,
        error: fieldError,
        helperText: fieldError?.message,
      };
    },
    [errors]
  );

  return phoneIsValid ? (
    <Fb
      justifyCenter
      alignCenter
      flex1
      sx={{
        p: {
          xs: 1,
          md: 2,
        },
        maxWidth: '100%',
      }}
    >
      <Popup
        redirectionUrl={redirectionUrl}
        text={`Мы отправили код подтверждения на номер +7 ${
          formattedPhone(phoneNumber) || '000 000 00 00'
        }`}
        phoneNumber={+phoneNumber}
      />
    </Fb>
  ) : (
    <Fb justifyCenter alignCenter flex1 sx={{ backgroundColor: '#FFF', pt: 6, pb: 6 }}>
      <Fb
        sx={{
          maxWidth: '100%',
          border: '1px solid #000',
          px: {
            xs: 2,
            md: 8,
          },
          py: {
            xs: 3,
            md: 6,
          },
          borderRadius: 5,
        }}
      >
        <Form
          style={{
            maxWidth: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <RegistrationAndLoginButtons />
          <AccountRecoverModal
            showAccountRecoverModal={showAccountRecoverModal}
            toggleAccountRecoverModal={toggleAccountRecoverModal}
            recoverAccountAction={onSubmit}
          />
          <Fb justifyCenter sx={{ p: 3 }}>
            <Stack
              spacing={'40px'}
              sx={{
                maxWidth: '100%',
                width: '320px',
              }}
            >
              <Stack spacing="10px">
                <Typography>Введите ваш номер телефона</Typography>
                <FormInput
                  sx={{
                    maxWidth: '100%',
                  }}
                  resetField={resetField}
                  control={control}
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                  }}
                  {...validationProps('phoneNumber')}
                />
                <Stack spacing="6px">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={status === 'loading' || !agreeWithTermsChecked}
                    disableRipple
                  >
                    Продолжить
                  </Button>
                  <p
                    style={{
                      fontSize: '14px',
                      color: '#db287e',
                      height: '40px',
                    }}
                  >
                    {hideErrorMessage && errorMessage}
                  </p>
                </Stack>
              </Stack>

              <Fb alignStart>
                <Checkbox
                  checked={agreeWithTermsChecked}
                  onChange={(_, checked) => setAgreeWithTermsChecked(checked)}
                  sx={{ mt: '2px' }}
                />
                <Typography sx={{ fontWeight: '500', fontSize: '14px', color: '#676767' }}>
                  Согласен с условиями
                  <Link className="link" to={ROUTES.PLACINGANORDER} target="_blank">
                    <u>Правил пользования торговой площадкой и правилами возврата</u>
                  </Link>
                </Typography>
              </Fb>
            </Stack>
          </Fb>
        </Form>
      </Fb>
    </Fb>
  );
};

export default LoginPage;
