import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

import { ReactComponent as CheckIcon } from 'assets/icons/checkIcon.svg';
import { ReactComponent as CheckIconDisabled } from 'assets/icons/checkIconDisabled.svg';

import './style.scss';

const Icon = styled('span')(() => ({
  padding: '0px',
  background: '#FEFFFF',
  border: '1.5px solid #C6C6C6',
  borderRadius: '5px',
  height: '20px',
  width: '20px',
  'input:hover ~ &': {
    border: '1px solid #A8509F',
  },
  'input:disabled ~ &': {
    background: '#FEFFFF',
    border: '1.5px solid #DFDFE1',
  },
}));

interface ICheckbox {
  disabled: boolean;
}

const CheckedIcon = ({ disabled }: ICheckbox) => (disabled ? <CheckIconDisabled /> : <CheckIcon />);

const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon disabled={props.disabled} />}
      icon={<Icon />}
      {...props}
    />
  );
};

export default CustomCheckbox;
