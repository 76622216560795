import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store';
import { getDynumicPupUpThunk, useDynamicPupUpData } from 'store/dynumicPupUp';
import Modal from 'components/Modal';

import './style.scss';

const DynamicPopUp = () => {
  const [isOpen, setOpen] = useState<boolean>(() => !sessionStorage.getItem('pupUpId'));
  const dispatch = useDispatch<AppDispatch>();

  const { data } = useDynamicPupUpData();
  const { title, cannotClose = true, imagePath, text } = data || {};

  const onCloseModal = () => {
    setOpen(!cannotClose ? true : false);
  };

  useEffect(() => {
    if (!cannotClose) setOpen(true);
  }, [cannotClose]);

  useEffect(() => {
    const maxMoneyId = localStorage.getItem('uid');
    maxMoneyId && dispatch(getDynumicPupUpThunk());
  }, []);

  return (
    <>
      {data && (
        <Modal showCloseIcon={cannotClose} open={isOpen} onClose={onCloseModal}>
          <div className="dynamic_pop_up_wrapper">
            <img className="image" src={imagePath} alt="dynamicPopUpImage" />
            <div className="title">{title}</div>
            <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default DynamicPopUp;
