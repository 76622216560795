import { createSlice, createAsyncThunk, current, AnyAction } from '@reduxjs/toolkit';

import { read, create } from 'utils/api';
import { REACT_APP_SELLER_URL } from 'constants/config';

import { PurchaseInterface } from './types';

const getPurchaseData = async ({ page, price, difference }) => {
  let sortBy = 'order=';
  if (price?.trim()) {
    sortBy += `price:${price},`;
  }
  if (difference?.trim()) {
    sortBy += `difference:${difference}`;
  }
  const response = await read(`${REACT_APP_SELLER_URL}/wholesaler/products?${sortBy}&page=${page}`);
  return response;
};

export const getPurchaseDataThunk = createAsyncThunk('wholesaler/purchase', getPurchaseData);

const buyProducts = async ({ data, callbeck }, { dispatch, getState }: any) => {
  const {
    seller: {
      wholesalerPurchase: {
        sortBy: { price, difference },
      },
    },
  } = getState();

  const response = await create(`${REACT_APP_SELLER_URL}/wholesaler/products`, data);
  dispatch(resetSelectedItems());
  dispatch(getPurchaseDataThunk({ page: 1, price, difference }));
  callbeck && callbeck();
  return response;
};

export const buyProductsThunk = createAsyncThunk('wholesaler/buyProducts', buyProducts);

const initialState: PurchaseInterface = {
  data: [],
  sortBy: {
    price: '',
    difference: 'ASC',
  },
  meta: {
    currentPage: 1,
  },
  selectedItems: [],
};

const PurchaseSlice = createSlice({
  name: 'wholesalerPurchase',
  initialState,
  reducers: {
    changeSortBy: (state: PurchaseInterface, action) => {
      const { type, value } = action.payload;
      return {
        ...state,
        sortBy: {
          ...state.sortBy,
          [type]: value,
        },
      };
    },
    addToSelectedItems: (state: PurchaseInterface, action) => {
      const { count, id: productId } = action.payload;
      const { selectedItems } = current(state);
      const newSelectedItems = [...selectedItems];
      const currentItemIndex = newSelectedItems.findIndex(({ id }) => id === productId);
      if (currentItemIndex === -1) {
        newSelectedItems.push(action.payload);
      } else {
        if (count === 0) {
          newSelectedItems.splice(currentItemIndex, 1);
        } else {
          const currentItem = newSelectedItems[currentItemIndex];
          newSelectedItems[currentItemIndex] = { ...currentItem, count };
        }
      }

      return {
        ...state,
        selectedItems: newSelectedItems,
      };
    },
    resetSelectedItems: (state: PurchaseInterface) => {
      return {
        ...state,
        selectedItems: [],
      };
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getPurchaseDataThunk.fulfilled.type,
      (state: PurchaseInterface, action: AnyAction) => {
        const { data, meta } = action.payload;
        let newData = data;
        if (action.meta.arg.page > 1) {
          newData = [...state.data, ...data];
        }

        return {
          ...state,
          data: newData,
          meta,
        };
      }
    );
  },
});
export default PurchaseSlice.reducer;

export const { addToSelectedItems, resetSelectedItems, changeSortBy, reset } =
  PurchaseSlice.actions;
