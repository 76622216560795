import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { MetaTemplates, MetaTemplatesPayloadAction } from './types';

const initialState: MetaTemplates = {
  data: [],
  meta: {},
  status: 'idle',
};

const getMetaTemplatesList = async () => {
  return await axios.get(`${REACT_APP_BUYER_URL}/meta-templates`);
};

export const getMetaTemplatesListThunk = createAsyncThunk(
  'metaTemplates/get',
  getMetaTemplatesList
);

const metaTemplatesSlice = createSlice({
  name: 'metaTemplates',
  initialState,
  reducers: {
    resetStatus: state => {
      return { ...state, status: 'idle' };
    },
    resetStore: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMetaTemplatesListThunk.pending.type, (state: MetaTemplates) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(
        getMetaTemplatesListThunk.fulfilled.type,
        (state: MetaTemplates, action: PayloadAction<MetaTemplatesPayloadAction>) => {
          const {
            data: { data },
          } = action.payload;
          return {
            ...state,
            data,
            status: 'success',
          };
        }
      )
      .addCase(getMetaTemplatesListThunk.rejected.type, (state: MetaTemplates) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export default metaTemplatesSlice.reducer;
