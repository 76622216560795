import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { Status } from 'store/types';
import { read, update, remove, create } from 'utils/api';
import { setCount } from 'store/cart';
import { NOTIFICATION_TYPES } from 'sections/AccountDetails/PersonalInfo/Buyer/StatusNotifications/constants';
import {
  StatusNotifictionsData,
  UnsubscribedType,
  UserDataEditParams,
  UserDataResponse,
} from './types';

const { EMAIL, SMS } = NOTIFICATION_TYPES;

export type UserDataActions = {
  read: () => void;
  updateData: (data: UserDataEditParams) => void;
};

export interface UserDataState {
  data: UserDataResponse;
  status: Status;
  updateStatus: Status;
  notificationStatus: Status;
  updateUserDataSuccess: boolean;
}

const initialState: UserDataState = {
  data: {},
  status: 'idle',
  updateStatus: 'idle',
  notificationStatus: 'idle',
  updateUserDataSuccess: false,
};

const readUserData = async (enableDurationPostalCode: boolean, { dispatch, signal }) => {
  let url = REACT_APP_BUYER_URL;
  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
  }
  const response: any = await read(`${url}/users/my`, { signal });
  const { productCount } = response;
  dispatch(setCount(+productCount));
  return response;
};

export const readUserDataThunk = createAsyncThunk('userData/readData', readUserData);

const updateUserDataAPI = (params: UserDataEditParams) =>
  update(`${REACT_APP_BUYER_URL}/users/my `, params);

export const updateUserData = createAsyncThunk('userData/updateData', updateUserDataAPI);

const createStatusNotifications = async (data: StatusNotifictionsData) => {
  const { notifications, defaultUnsubscribedFrom } = data;
  try {
    const newData = {
      notifications: notifications.map((notification: UnsubscribedType, index: number) => ({
        type: index + 1,
        unsubscribe: notification.unsubscribe,
      })),
    };

    const findIndexToRemove = defaultUnsubscribedFrom.findIndex(
      (item: UnsubscribedType, index: number) =>
        item.unsubscribe === notifications[index].unsubscribe
    );

    if (findIndexToRemove !== -1) {
      newData.notifications.splice(findIndexToRemove, 1);
    } else {
      newData.notifications.forEach((notification: UnsubscribedType, index: number) => {
        notification.unsubscribe = notifications[index].unsubscribe;
      });
    }
    await create(`${REACT_APP_BUYER_URL}/user-notifications`, newData);
  } catch (error) {
    return error;
  }
};

export const createStatusNotificationsThunk = createAsyncThunk(
  'editUserData/notificationData',
  createStatusNotifications
);

const deleteUserAccountAPI = async ({ callback }) => {
  await remove(`${REACT_APP_BUYER_URL}/users/me`);
  callback();
};

export const deleteUserAccount = createAsyncThunk(
  'userData/deleteUserAccount',
  deleteUserAccountAPI
);
export const editBuyerDataSlice = createSlice({
  name: 'editUserData',
  initialState,
  reducers: {
    increaseWishlistProductsCount: (state: UserDataState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductCount: (state.data.wishlistProductCount || 0) + 1,
        },
      };
    },
    decreaseWishlistProductsCount: (state: UserDataState) => {
      return {
        ...state,
        data: {
          ...state.data,
          wishlistProductCount: (state.data.wishlistProductCount || 0) - 1,
        },
      };
    },
    resetUpdateUserStatus: (state: UserDataState) => ({
      ...state,
      updateUserDataSuccess: false,
    }),
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(readUserDataThunk.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(
        readUserDataThunk.fulfilled.type,
        (state: UserDataState, action: PayloadAction<UserDataResponse>) => {
          return {
            ...state,
            status: 'success',
            data: action.payload,
          };
        }
      )
      .addCase(readUserDataThunk.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          data: initialState.data,
          status: 'failed',
        };
      })
      .addCase(createStatusNotificationsThunk.pending.type, (state: UserDataState) => {
        return {
          ...state,
          notificationStatus: 'loading',
        };
      })
      .addCase(
        createStatusNotificationsThunk.fulfilled.type,
        (state: UserDataState, action: any) => {
          const { data } = state;
          const { notifications } = action.meta.arg;
          const newUnsubscribedFrom = [];

          notifications.forEach((not: UnsubscribedType) => {
            const emailType = not.type === EMAIL && not.unsubscribe;
            const smsType = not.type === SMS && not.unsubscribe;

            if (emailType && smsType) {
              newUnsubscribedFrom.push(EMAIL, SMS);
            } else {
              if (emailType) newUnsubscribedFrom.push(EMAIL);
              if (smsType) newUnsubscribedFrom.push(SMS);
              if (!emailType && !smsType) newUnsubscribedFrom.slice(Infinity);
            }
          });

          return {
            ...state,
            notificationStatus: 'success',
            data: {
              ...data,
              unsubscribedFrom: newUnsubscribedFrom,
            },
          };
        }
      )
      .addCase(createStatusNotificationsThunk.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          notificationStatus: 'failed',
        };
      })
      .addCase(updateUserData.pending.type, (state: UserDataState) => {
        return {
          ...state,
          updateStatus: 'loading',
          updateUserDataSuccess: false,
        };
      })
      .addCase(updateUserData.fulfilled.type, (state: UserDataState, action: any) => {
        const { data } = state;
        return {
          ...state,
          updateStatus: 'success',
          data: {
            ...data,
            ...action.payload,
            avatar: action.meta.arg.avatarPath,
          },
          updateUserDataSuccess: true,
        };
      })
      .addCase(updateUserData.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          data: initialState.data,
          updateStatus: 'failed',
          updateUserDataSuccess: false,
        };
      });
  },
});

export const useBuyerData = (): UserDataState => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.editBuyerData);
  return reducerState;
};

export const {
  increaseWishlistProductsCount,
  decreaseWishlistProductsCount,
  resetUpdateUserStatus,
  reset,
} = editBuyerDataSlice.actions;

export default editBuyerDataSlice.reducer;
