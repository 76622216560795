import { Suspense } from 'react';
import SwitchRouter from './SwitchRouter';
import { authRoutes } from './routes';
import MaxMarketLoader from 'components/MaxMarketLoader';

const AppLayout = () => (
  <Suspense fallback={<MaxMarketLoader />}>
    <SwitchRouter routes={authRoutes} />
  </Suspense>
);

export default AppLayout;
