import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { MaxMonyHistoryList } from './types';

const getMaxMonyHistory = async (page: number) => {
  const response = await read(`${REACT_APP_BUYER_URL}/maxmoney-history?page=${page}&limit=10`);
  return response;
};

export const getMaxMonyHistoryThunk = createAsyncThunk('maxmoneyHistory/get', getMaxMonyHistory);

const initialState: MaxMonyHistoryList = {
  data: [],
  meta: {},
  status: 'idle',
};

const MaxMonyHistorySlice = createSlice({
  name: 'maxmoneyHistory',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        getMaxMonyHistoryThunk.fulfilled.type,
        (state: MaxMonyHistoryList, action: PayloadAction<MaxMonyHistoryList>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data: [...state.data, ...data],
            meta,
            status: 'success',
          };
        }
      )
      .addCase(getMaxMonyHistoryThunk.pending.type, (state: MaxMonyHistoryList) => {
        state.status = 'loading';
      })
      .addCase(getMaxMonyHistoryThunk.rejected.type, (state: MaxMonyHistoryList) => {
        state.status = 'failed';
      });
  },
  reducers: {
    resetMaxmoneyHistory() {
      return initialState;
    },
  },
});

export const useMaxmoneyHistoryData = (): MaxMonyHistoryList => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.maxmoneyHistory);
  return reducerState;
};

export const { resetMaxmoneyHistory } = MaxMonyHistorySlice.actions;
export default MaxMonyHistorySlice.reducer;
