import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { read, update } from 'utils/api';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

import { GetInterestedProductsParams } from './types';

const markUsViewed = (id: number) => update(`${REACT_APP_BUYER_URL}/products/${id}/viewed`);

export const markAsViewedThunk = createAsyncThunk('product/markUsViewed', markUsViewed);

const getInterestedProductsList = ({
  limit,
  enableDurationPostalCode,
  postalCode,
}: GetInterestedProductsParams) => {
  let url = `${REACT_APP_BUYER_URL}/products/viewed?limit=${limit}`;
  if (enableDurationPostalCode) {
    const selectedPostalCode =
      postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;
    url = `${REACT_APP_BUYER_URL_V2}/products?postalCode=${selectedPostalCode}&isViewed=true`;
  }
  return read(url);
};

export const getInterestedProductsListThunk = createAsyncThunk(
  'user/interestedProductsList',
  getInterestedProductsList
);

const initialState = {
  data: [],
  status: 'idle',
};

const InterestedSlice = createSlice({
  name: 'interested',
  initialState,
  reducers: {
    changWishlistProductIdInInterestedProducts: (state, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;
      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
    removeFromWishlistInInterestedProducts: (state, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        data,
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(getInterestedProductsListThunk.fulfilled.type, (state, action: any) => {
      return {
        ...state,
        data: action.payload.data,
        status: 'success',
      };
    });
  },
});

export default InterestedSlice.reducer;

export const {
  changWishlistProductIdInInterestedProducts,
  removeFromWishlistInInterestedProducts,
} = InterestedSlice.actions;
