import { dispatch } from 'store';

import { Button, Grid, Typography } from '@mui/material';

import Modal from 'components/Modal';
import {
  hideUserSuspendedDialog,
  useUserSuspendedDialogState,
} from 'store/buyer/userSuspendedDialog';

import lockIcon from 'assets/icons/lock.svg';

const SuspendedUserModal = () => {
  const isOpen = useUserSuspendedDialogState();

  const onCloseModal = () => {
    dispatch(hideUserSuspendedDialog());
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal}>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="20px"
        height="100%"
      >
        <Grid item xs={12} textAlign="center">
          <img
            src={lockIcon}
            alt="User Suspended"
            style={{ maxWidth: 200, width: '100%', height: 'auto' }}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography fontWeight={600} fontSize={32} color="#292929">
            Ваш аккаунт заблокирован!
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography fontWeight={400} fontSize={16} color="#676767">
            Ваша учетная запись приостановлена, для дальнейших действий обратитесь к администрацию
            сайта.
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" mt="10px">
          <Button
            variant="outlined"
            color="inherit"
            sx={{ width: 'auto', color: '#676767', borderColor: '#C6C6C6', borderRadius: 2 }}
            href="mailto:retail@maxmarket24.ru"
          >
            Связаться с нами
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SuspendedUserModal;
