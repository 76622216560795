import { useLocation, useSearchParams } from 'react-router-dom';

import { useFlag } from '@unleash/proxy-client-react';

import { useAppDispatch } from 'store/hooks';
import {
  setLabels,
  setSavedLabelsForLater,
  setFilteringObject,
  useProductListData,
  setSavedFilteringDataObjectForLater,
  resetRangeValue,
} from 'store/products';
import useCheckScreen from 'utils/useCheckScreen';
import {
  RADIO_BUTTON_TYPE,
  BRAND_ID_FILTER_NAME,
  BRAND_ID_FILTER_NAME_V2,
  TAG_ID_FILTER_NAME,
  TAG_ID_FILTER_NAME_V2,
  OUT_OF_STOCK_FILTER_NAME,
  CHECKBOX_TYPE,
  CATEGORIES_FILTER_NAME_IN_BANNER_PAGE,
  RANGE_TYPE,
} from 'constants/filtration';
import { useMemo } from 'react';

interface CheckboxChangeParams {
  checked: boolean;
  filterName: string;
  value: string | number;
  id: any;
  type?: string;
  filteredInUrl?: boolean;
  callback?: any;
}

const useFiltration = () => {
  const enableDurationPostalCode = useFlag('enable_duration_postal_code');
  const enableFiltrationV2 = useFlag('enable_filtration_v2');
  const dispatch = useAppDispatch();
  const {
    labels,
    filteringDataObject: filtersData,
    savedFilteringDataObjectForLater,
    savedLabelsForLater,
    filters: { getFiltersLoading = false },
  } = useProductListData();
  const { search, state } = useLocation() as { search: string; state: any };
  const [, setSearchParams] = useSearchParams();
  const searchParams = new URLSearchParams(search);
  const page = searchParams.get('page');
  const orderFieldFromURL = searchParams.get('order')?.trim();

  const filteringDataObject = useMemo(() => {
    const newFiltersData = structuredClone(filtersData);
    if (orderFieldFromURL && !newFiltersData?.order) {
      newFiltersData.order = orderFieldFromURL;
    }

    return newFiltersData;
  }, [filtersData, orderFieldFromURL]);

  const isSmallTablet = useCheckScreen({
    type: 'smallTablet',
  });

  const setFirstPage = () => {
    if (page !== '1') {
      searchParams.set('page', '1');
      setSearchParams(searchParams, {
        state,
        replace: true,
        preventScrollReset: true,
      });
    }
  };

  const currentFilteringObject = isSmallTablet
    ? savedFilteringDataObjectForLater
    : filteringDataObject;

  const currentLabels = isSmallTablet ? savedLabelsForLater : labels;

  const { tagIdFilterName, brandIdFilterName } = enableDurationPostalCode
    ? { tagIdFilterName: TAG_ID_FILTER_NAME_V2, brandIdFilterName: BRAND_ID_FILTER_NAME_V2 }
    : { tagIdFilterName: TAG_ID_FILTER_NAME, brandIdFilterName: BRAND_ID_FILTER_NAME };

  const setOutOfStockFilterInUrl = (checked: boolean) => {
    if (checked) {
      const pageParam = searchParams.get('page');
      if (pageParam && pageParam !== '1') {
        searchParams.set('page', '1');
      }
      searchParams.set(OUT_OF_STOCK_FILTER_NAME, `${true}`);
    } else {
      searchParams.delete(OUT_OF_STOCK_FILTER_NAME);
    }
    if (`?${searchParams.toString()}` !== search) {
      setSearchParams(searchParams, {
        state,
        replace: true,
        preventScrollReset: true,
      });
    }
  };

  const handleCallbackSetFiltering = ({ filterData, exclude = undefined, outOfStockFilter }) => {
    if (filterData[OUT_OF_STOCK_FILTER_NAME] && !(exclude === OUT_OF_STOCK_FILTER_NAME)) {
      setOutOfStockFilterInUrl(outOfStockFilter);
    } else {
      setFirstPage();
    }
  };

  const setCurrentLabels = (labels, filteredInUrl = false) => {
    if (isSmallTablet && !filteredInUrl) {
      dispatch(setSavedLabelsForLater(labels));
    } else {
      dispatch(setSavedLabelsForLater(labels));
      dispatch(setLabels(labels));
    }
  };

  const setCurrentFilteringObject = (
    filteringObject,
    saveFiltering = false,
    filteredInUrl = false
  ) => {
    if (isSmallTablet && !saveFiltering && !filteredInUrl) {
      dispatch(setSavedFilteringDataObjectForLater(filteringObject));
    } else {
      dispatch(setSavedFilteringDataObjectForLater(filteringObject));
      dispatch(
        setFilteringObject({
          filteringData: filteringObject,
          callback: () => {
            if (isSmallTablet) return;
            if (filteringObject[OUT_OF_STOCK_FILTER_NAME]) {
              setOutOfStockFilterInUrl(true);
            } else if (!filteredInUrl) {
              setOutOfStockFilterInUrl(false);
              setFirstPage();
            }
          },
        })
      );
    }
  };

  const resetSavedFilters = () => {
    dispatch(setSavedLabelsForLater(labels));
    const { maxPrice, minPrice } = filteringDataObject;
    const rangeValues = minPrice && maxPrice ? [minPrice, maxPrice] : undefined;
    dispatch(resetRangeValue({ field: 'price', values: rangeValues }));
    dispatch(setSavedFilteringDataObjectForLater(filteringDataObject));
  };

  const saveFilters = () => {
    dispatch(setLabels(savedLabelsForLater));
    dispatch(
      setFilteringObject({
        filteringData: savedFilteringDataObjectForLater,
        callback: () =>
          handleCallbackSetFiltering({
            filterData: savedFilteringDataObjectForLater,
            outOfStockFilter: savedFilteringDataObjectForLater[OUT_OF_STOCK_FILTER_NAME],
          }),
      })
    );
  };

  const onRadioButtonChange = ({ filterName, value, id }) => {
    const labelsData = [
      {
        name: filterName,
        value,
        type: RADIO_BUTTON_TYPE,
        id,
      },
      ...currentLabels.filter(({ name: labelName }) => labelName !== filterName),
    ];
    setCurrentLabels(labelsData);
    setCurrentFilteringObject({ ...currentFilteringObject, [filterName]: value });
  };

  const onCheckboxChange = ({
    checked,
    filterName,
    value,
    id,
    type = CHECKBOX_TYPE,
    callback,
    filteredInUrl = false,
  }: CheckboxChangeParams) => {
    const prevSelectedOptions = currentFilteringObject[filterName] || '';
    const splitSenville =
      [tagIdFilterName, brandIdFilterName, CATEGORIES_FILTER_NAME_IN_BANNER_PAGE].includes(
        filterName
      ) ||
      (!enableDurationPostalCode && !enableFiltrationV2)
        ? ','
        : '|~';
    let selectedOptions = prevSelectedOptions?.split(splitSenville);

    const valueInFiltration = [
      brandIdFilterName,
      tagIdFilterName,
      CATEGORIES_FILTER_NAME_IN_BANNER_PAGE,
      OUT_OF_STOCK_FILTER_NAME,
    ].includes(filterName)
      ? id
      : value;

    selectedOptions = selectedOptions.filter(option => {
      return valueInFiltration != option && option.trim();
    });
    const lable = {
      name: filterName,
      value,
      type: type,
      id,
    };

    if (checked) {
      setCurrentLabels([lable, ...currentLabels], filteredInUrl);
      selectedOptions.push(valueInFiltration);
    } else {
      if (filterName === OUT_OF_STOCK_FILTER_NAME) {
        selectedOptions = [];
      }
      setCurrentLabels(
        currentLabels.filter(
          ({ value: labelValue, name: labelName }) =>
            !(labelValue == value && filterName == labelName)
        ),
        filteredInUrl
      );
    }

    const data = { ...currentFilteringObject };
    if (selectedOptions.length) {
      data[filterName] = selectedOptions.join(splitSenville);
    } else {
      delete data[filterName];
    }
    setCurrentFilteringObject(data, false, filteredInUrl);
    callback && callback();
  };

  const removeLabel = (removedName, removedValue, type) => {
    let currentLabels = [...labels];
    const splitSenville =
      [tagIdFilterName, brandIdFilterName, CATEGORIES_FILTER_NAME_IN_BANNER_PAGE].includes(
        removedName
      ) ||
      (!enableDurationPostalCode && !enableFiltrationV2)
        ? ','
        : '|~';
    const data = { ...currentFilteringObject };

    const fieldToCheck =
      removedName === brandIdFilterName ||
      removedName === tagIdFilterName ||
      removedName === CATEGORIES_FILTER_NAME_IN_BANNER_PAGE
        ? 'id'
        : 'value';

    currentLabels = currentLabels.filter(
      label => !(removedValue === label[fieldToCheck] && label.name === removedName)
    );

    dispatch(setLabels(currentLabels));
    dispatch(setSavedLabelsForLater(currentLabels));
    if (removedName === OUT_OF_STOCK_FILTER_NAME || type === RADIO_BUTTON_TYPE) {
      delete data[removedName];
    }

    if (type === CHECKBOX_TYPE) {
      let filterData = data[removedName] || '';
      if (filterData) {
        filterData = filterData
          ?.split(splitSenville)
          .filter(data => String(data) !== String(removedValue));
        if (filterData.length) {
          data[removedName] = filterData.join(splitSenville);
        } else {
          delete data[removedName];
        }
      }
    }

    if (type.includes(RANGE_TYPE)) {
      if (removedName === 'price') {
        delete data.minPrice;
        delete data.maxPrice;
      } else {
        const values = data[RANGE_TYPE] || '';
        const newRangeValues = values
          .split('|')
          .filter(elm => !elm.includes(removedName))
          .join('|');
        if (newRangeValues.trim().length) {
          data[RANGE_TYPE] = newRangeValues;
        } else {
          delete data[RANGE_TYPE];
        }
      }
      dispatch(resetRangeValue({ field: removedName }));
    }

    dispatch(
      setFilteringObject({
        filteringData: data,
        callback: () =>
          handleCallbackSetFiltering({
            filterData: currentFilteringObject,
            outOfStockFilter: false,
          }),
      })
    );
    dispatch(setSavedFilteringDataObjectForLater(data));
  };

  const clearFiltration = (params?: any) => {
    const { exclude } = params || {};
    const filteringObject = exclude ? { [exclude]: currentFilteringObject[exclude] } : {};
    const labels = exclude ? currentLabels.filter(({ name }) => name === exclude) : [];

    dispatch(
      setFilteringObject({
        filteringData: filteringObject,
        callback: () =>
          handleCallbackSetFiltering({
            filterData: currentFilteringObject,
            exclude,
            outOfStockFilter: false,
          }),
      })
    );
    dispatch(setSavedFilteringDataObjectForLater(filteringObject));
    dispatch(setLabels(labels));
    dispatch(setSavedLabelsForLater(labels));
    dispatch(resetRangeValue({ field: 'all' }));
  };

  const onRangeChange = ({ values, name, id, type, isPriceRange }) => {
    let [minValue, maxValue] = values;
    if (maxValue < minValue) {
      maxValue = +minValue;
    }
    if (minValue > maxValue) {
      minValue = +maxValue;
    }
    let newLabels = [...currentLabels];
    if (isPriceRange) {
      setCurrentFilteringObject({
        ...currentFilteringObject,
        minPrice: minValue,
        maxPrice: maxValue,
      });
    } else {
      const rangeData: any = currentFilteringObject?.range || '';
      let newRangeParams = '';
      if (!rangeData) {
        newRangeParams = `${name}:${minValue};${maxValue}`;
      } else {
        const paramsArr = rangeData?.split('|');
        const filterIndex = paramsArr.findIndex(elm => elm.includes(`${name}:`));
        if (filterIndex > -1) {
          paramsArr[filterIndex] = `${name}:${minValue};${maxValue}`;
        } else {
          paramsArr.push(`${name}:${minValue};${maxValue}`);
        }
        newRangeParams = paramsArr.join('|');
      }
      setCurrentFilteringObject({
        ...currentFilteringObject,
        range: newRangeParams,
      });
    }

    newLabels = newLabels.filter(
      ({ name: labelName, type: labelType }) => labelName !== name && type !== labelType
    );

    newLabels.push({
      id,
      name,
      value: `${isPriceRange ? 'Цена' : name}: от ${+minValue} до ${+maxValue}`,
      type: `${type}${name}`,
    });

    setCurrentLabels(newLabels);
  };

  return {
    labels: labels,
    currentLabels,
    filteringObject: filteringDataObject,
    getFiltersLoading,
    setLabels: setCurrentLabels,
    setFilteringObject: setCurrentFilteringObject,
    clearFiltration,
    onCheckboxChange,
    onRadioButtonChange,
    removeLabel,
    resetSavedFilters,
    saveFilters,
    setFirstPage,
    onRangeChange,
    enableDurationPostalCode,
    enableFiltrationV2,
  };
};
export default useFiltration;
