import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Modal from 'components/Modal';

import cookiseSvg from 'assets/icons/cookies.svg';

import './style.scss';

export const CookiesModal = (): JSX.Element => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [showCookies, setShowCookies] = useState<boolean>(false);

  const openModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('cookiesid')) {
      setShowCookies(true);
    }
  }, []);

  const toggleCookies = () => {
    localStorage.setItem('cookiesid', uuidv4());
    setShowCookies(false);
  };

  return (
    <>
      {showCookies && (
        <div className="cookies_wrap">
          <div className="cookies_container">
            <div className="cookies_text">
              <img width={44} height={44} src={cookiseSvg} alt="cookiseSvg" />
              <h2>Cookie-файлы</h2>
              <p>
                Мы используем cookie. <span onClick={openModal}>Подробнее</span>
              </p>
            </div>
            <button onClick={toggleCookies}>Принять</button>
          </div>
        </div>
      )}
      <Modal
        className="cookies_modal_wrapper"
        bodyClassName="cookies_modal"
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            fontWeight: 400,
            color: '#222',
            marginTop: '20px',
            fontSize: {
              xs: '10px',
              md: '16px',
            },
          }}
        >
          <p>
            Сookies представляют собой небольшие фрагменты данных, которые веб-сайты запрашивают у
            браузера, используемого на компьютере или мобильном устройстве Пользователя. Сookies
            хранятся локально на устройстве пользователя сети Интернет. Оператор собирает и
            обрабатывает cookies в отношении Пользователей, посещающих сайт.
          </p>
          <p>
            Сookies обрабатываются Оператором исключительно с целью исполнения заключённых с
            Пользователями соглашений по использованию сайта, на условиях и в порядке, определённых
            настоящей Политикой, в частности на основании данных, полученных с помощью файлов
            cookies, Оператор разрабатывает наиболее полезный функционал для Сервиса, доступный
            Пользователю, проводит статистические исследования, исправляет ошибки в сайте и
            тестирует новые функции для повышения производительности сайта, персонализирует и
            показывает наиболее релевантную для Пользователя информацию.
          </p>
          <p>
            Пользователь может отказаться от обработки cookies в настройках своего браузера. В
            указанном случае Оператор будет использовать только те cookies, которые строго
            необходимы предоставления функциональных возможностей сайта.
          </p>
        </Box>
      </Modal>
    </>
  );
};

export default CookiesModal;
