import { useState, useRef, memo, FC } from 'react';

import { Typography } from '@mui/material';
import classNames from 'classnames';
import { YMaps, Map, ZoomControl, GeolocationControl, Placemark } from 'react-yandex-maps';

import Modal from 'components/Modal';
import Fb from 'components/FB';
import CustomButton from 'components/Button';
import ErrorViewYandexMap from 'components/ErrorViewYandexMap';
import getAddressName, { getPostalCode, getAddressDetails } from 'helpers/getAddressNameByCoords';
import { REACT_APP_YANDEX_MAP_KEY, REACT_APP_YANDEX_SUGGESTION_API_KEY } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import { errorMessages } from 'resolvers/constants';

import { ReactComponent as AddressMarkIcon } from 'assets/icons/address-marker.svg';

import {
  useRefreshDataFromLocation,
  defaultLocation,
  getSelectedCoordinates,
} from './useRefreshDataFromLocation';

import './style.scss';

const { ERROR_REQUIRED_MESSAGE } = errorMessages;

interface Props {
  showLocationContent?: boolean;
  open?: boolean;
  setOpenGeolocationMap?: () => void;
}

const GeolocationMap: FC<Props> = ({
  open = false,
  setOpenGeolocationMap,
  showLocationContent = true,
}) => {
  const mapRef = useRef<any>();
  const addresInputRef = useRef<HTMLInputElement>(null);
  const [showMap, setShowMap] = useState(false);
  const [errorViewMap, setErrorViewMap] = useState<boolean>(false);
  const {
    location,
    selectedLocation,
    saveCurrentLocation,
    setCurrentLocation,
    enableDurationPostalCode,
  } = useRefreshDataFromLocation();
  const [mapState, setMapState] = useState({
    center: [MOSCOW_LAT, MOSCOW_LNG],
    zoom: 9,
  });

  const { latitude, longitude, postalCode, address, city } = location;

  const closeModal = (reset = true) => {
    reset && setCurrentLocation(selectedLocation);
    setShowMap(false);
    setErrorViewMap(false);
    setOpenGeolocationMap && open && setOpenGeolocationMap();
  };

  const openMapModal = () => {
    setShowMap(true);
  };

  const loadSuggest = async ymaps => {
    const suggestView = new ymaps.SuggestView('suggest');

    const [selectedLatitude, selectedLongitude] = getSelectedCoordinates();

    const lat = selectedLatitude || defaultLocation.latitude;
    const long = selectedLongitude || defaultLocation.longitude;

    const { city, postalCode } = selectedLocation;
    const checkGPSAndCity = !selectedLatitude || !selectedLongitude || !city;
    const checkLocation = enableDurationPostalCode
      ? checkGPSAndCity || !postalCode
      : checkGPSAndCity;
    if (checkLocation) {
      saveCurrentLocation(defaultLocation);
      setCurrentLocation(defaultLocation);
      if (addresInputRef.current) {
        addresInputRef.current.value = defaultLocation.city;
      }
      const { latitude, longitude } = defaultLocation;
      setMapState({
        ...mapState,
        center: [latitude, longitude],
      });
    } else if (lat && long) {
      setMapState({
        ...mapState,
        center: [lat, long],
      });
    }

    suggestView.events.add('select', async e => {
      const address = e.get('item');

      const { value } = address;
      const {
        latitude,
        longitude,
        city,
        address: fullAddress,
      } = (await getAddressDetails(value, false)) || {};

      if (latitude && longitude) {
        const postalCode = enableDurationPostalCode
          ? await getPostalCode([latitude, longitude])
          : '';

        setCurrentLocation({ address: fullAddress, city, latitude, longitude, postalCode });
        setMapState({
          ...mapState,
          center: [+latitude, +longitude],
        });
      }
    });
  };

  const saveLocation = () => {
    saveCurrentLocation();
    closeModal(false);
  };

  const onMapClick = async e => {
    const coords = e.get('coords');
    const [latitude, longitude] = coords;
    const { city, address, countryCode } = await getAddressName([latitude, longitude], 'city');

    setMapState({
      ...mapState,
      center: [+latitude, +longitude],
    });

    const postalCode = enableDurationPostalCode ? await getPostalCode(coords) : '';

    const currentLocation = {
      latitude,
      longitude,
      postalCode,
      address,
      city,
    };

    if (countryCode !== 'RU') {
      setCurrentLocation(defaultLocation);
    } else {
      setCurrentLocation(currentLocation);
    }

    if (addresInputRef.current) {
      addresInputRef.current.value = address;
      addresInputRef.current.focus();
    }
  };

  const getCurrentLocation = async e => {
    try {
      e.stopPropagation();
      const [latitude, longitude] = e.originalEvent.position;

      setMapState({
        ...mapState,
        center: [+latitude, +longitude],
      });

      const { city, address, countryCode } = await getAddressName([latitude, longitude], 'city');

      const postalCode = enableDurationPostalCode ? await getPostalCode([latitude, longitude]) : '';

      const currentLocation = {
        latitude,
        longitude,
        postalCode,
        address,
        city,
      };

      if (countryCode !== 'RU') {
        setCurrentLocation(defaultLocation);
      } else {
        setCurrentLocation(currentLocation);
      }

      if (addresInputRef.current) {
        addresInputRef.current.value = address;
      }
    } catch (error) {
      setCurrentLocation(defaultLocation);
      if (addresInputRef.current) {
        addresInputRef.current.value = defaultLocation.city;
      }
    } finally {
      if (mapRef?.current) {
        mapRef.current.setZoom(15, { duration: 500 });
      }
    }
  };

  const handleErrorViewMap = () => {
    setErrorViewMap(true);
  };

  const handleOnBlur = () => {
    if (addresInputRef.current) {
      if (address === addresInputRef.current.value || !addresInputRef.current.value) {
        addresInputRef.current.value = address;
      }
    }
  };

  const handleOnFocus = () => {
    if (addresInputRef.current) {
      addresInputRef.current.value = '';
    }
  };

  const handleChangePostalCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (/^[0-9]+$/.test(value) || value === '') {
      setCurrentLocation({ ...location, postalCode: value });
    }
  };

  let isDisabledSaveButton =
    !city ||
    !postalCode ||
    (city === selectedLocation.city && postalCode === selectedLocation.postalCode);
  if (!enableDurationPostalCode) {
    isDisabledSaveButton =
      !city || (latitude === selectedLocation.latitude && longitude === selectedLocation.longitude);
  }

  return (
    <>
      {showLocationContent && (
        <Fb className="location-wrap">
          <Fb className="location-info" gap="9px" onClick={openMapModal}>
            <AddressMarkIcon className="addres-mark" />
            <Typography className="location">{selectedLocation.city || 'Москва'}</Typography>
          </Fb>
          <Typography className="loc-name">Принимаем заказы по всей России</Typography>
        </Fb>
      )}
      <Modal bodyClassName="geolocation-map" open={showMap || open} onClose={closeModal}>
        <Fb className="h-100 w-100">
          <Fb className="address-part" column justifyBetween alignStretch>
            <div>
              <h2 className="mapModalTitle"> Адрес доставки</h2>
              <input
                type="text"
                defaultValue={city}
                onClick={handleOnFocus}
                onBlur={handleOnBlur}
                color="warning"
                id="suggest"
                className="address-input"
                placeholder="Адрес"
                ref={addresInputRef}
              />
              <div className="selected-address-wrap">
                <AddressMarkIcon />
                <div>
                  <p>{address || city}</p>
                  {enableDurationPostalCode && (
                    <div>
                      <label htmlFor="postalCode">Почтовый индекс:</label>
                      <div>
                        <input
                          className={classNames({ error: !postalCode })}
                          id="postalCode"
                          value={postalCode}
                          maxLength={6}
                          onChange={handleChangePostalCode}
                          autoComplete="off"
                        />
                        {!postalCode && (
                          <span className="error-message">{ERROR_REQUIRED_MESSAGE}</span>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <CustomButton
              label="Сохранить"
              onClick={saveLocation}
              disabled={isDisabledSaveButton}
              size="fullWidth"
              className="save-button"
            />
          </Fb>
          {errorViewMap ? (
            <ErrorViewYandexMap />
          ) : (
            <YMaps
              enterprise
              query={{
                apikey: REACT_APP_YANDEX_MAP_KEY,
                suggest_apikey: REACT_APP_YANDEX_SUGGESTION_API_KEY,
              }}
            >
              <Map
                onError={handleErrorViewMap}
                onClick={onMapClick}
                onLoad={ymaps => loadSuggest(ymaps)}
                width="100%"
                height="100%"
                state={{ ...mapState, center: [+mapState.center[0], +mapState.center[1]] }}
                instanceRef={(ref: any) => {
                  mapRef.current = ref;
                }}
                modules={['SuggestView']}
              >
                {latitude && longitude && <Placemark geometry={[+latitude, +longitude]} />}
                <GeolocationControl
                  options={{
                    noPlacemark: true,
                  }}
                  onLocationChange={getCurrentLocation}
                />
                <ZoomControl />
              </Map>
            </YMaps>
          )}
        </Fb>
      </Modal>
    </>
  );
};

export default memo(GeolocationMap);
