import { read, update } from 'utils/api';
import { REACT_APP_SELLER_URL } from 'constants/config';

const URL = REACT_APP_SELLER_URL;

export const getUserData = () => read(`${URL}/users/my/account`);

export const updatePersonalInfo = (params: unknown) => update(`${URL}/users/my/account`, params);

export const updateLegalInfo = (params: unknown) => update(`${URL}/users/my/legal-info`, params);
