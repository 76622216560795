import userTypes from 'constants/userTypes';
import { AppDispatch } from 'store/index';
import { reset as sellerReset } from 'store/account/seller';
import { reset as buyerReset } from 'store/buyer/editBuyerData';
import { reset as oneOrderReset } from 'store/buyer/oneOrder';
import { reset as buyerOrdersReset } from 'store/buyer/orders';
import { reset as cartReset } from 'store/cart';
import { reset as orderReset } from 'store/order';
import { reset as oneRequestReset } from 'store/request/oneRequest';
import { reset as requestsReset } from 'store/request/requests';
import { reset as cataloguesReset } from 'store/seller/catalogues';
import { reset as ordersReset } from 'store/seller/orders';
import { reset as statisticsReset } from 'store/seller/statistics';
import { reset as wholesalerReset } from 'store/seller/wholesaler/purchase';
import { reset as userCreditCardReset } from 'store/userCreditCard';
import { reset as deliveryAddressReset } from 'store/user/deliveryAddress';
import { reset as recieversReset } from 'store/user/recievers';

const resetStoreOnLogout = (userType: string, dispatch: AppDispatch) => {
  const { buyer } = userTypes;
  dispatch(oneRequestReset());
  dispatch(requestsReset());
  if (buyer === userType) {
    dispatch(buyerReset());
    dispatch(cartReset());
    dispatch(orderReset());
    dispatch(userCreditCardReset());
    dispatch(deliveryAddressReset());
    dispatch(recieversReset());
    dispatch(oneOrderReset());
    dispatch(buyerOrdersReset());
  } else {
    dispatch(sellerReset());
    dispatch(cataloguesReset());
    dispatch(statisticsReset());
    dispatch(wholesalerReset());
    dispatch(ordersReset());
  }
  dispatch(oneRequestReset());
  dispatch(requestsReset());
};
export default resetStoreOnLogout;
