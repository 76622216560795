import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { BuyerOrders } from './types';
import { getBuyerOrders } from './api';

export const getBuyerOrdersDataThunk = createAsyncThunk('buyerOrder/list', getBuyerOrders);

const initialState: BuyerOrders = {
  data: [],
  meta: {},
  status: 'idle',
};

const buyerOrdersSlice = createSlice({
  name: 'buyerOrders',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getBuyerOrdersDataThunk.fulfilled.type,
        (state: BuyerOrders, action: PayloadAction<BuyerOrders>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            meta,
            status: 'success',
          };
        }
      )
      .addCase(getBuyerOrdersDataThunk.pending.type, (state: BuyerOrders) => {
        return {
          ...state,
          status: 'loading',
        };
      });
  },
});

export const useBuyerOrdersData = (): BuyerOrders => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.buyerOrders);
  return reducerState;
};

export const { reset } = buyerOrdersSlice.actions;

export default buyerOrdersSlice.reducer;
