export const {
  REACT_APP_NODE_ENV,
  REACT_APP_BUYER_URL,
  REACT_APP_SELLER_URL,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_YANDEX_MAP_KEY,
  REACT_APP_YANDEX_SUGGESTION_API_KEY,
  REACT_APP_FNS_URL,
  REACT_APP_FNS_KEY,
  REACT_APP_HANDLE_FNS,
  REACT_APP_ELASTIC_URL,
  REACT_APP_UNLEASH_HOST,
  REACT_APP_UNLEASH_CLIENT_KEY,
  REACT_APP_UNLEASH_APPNAME,
  REACT_APP_PRODUCTS_SEARCH_API,
  REACT_APP_COMPLETED_SURVEY_MAXMONEY,
  REACT_APP_V2_PUBLISH_DATE,
} = process.env;

export const REACT_APP_BUYER_URL_V2 = REACT_APP_BUYER_URL.replace(/v1/g, 'v2');
