import ROUTES from 'constants/routes';

import { getAccountTabRoute } from 'helpers/routeHelper';
import accountTabs from 'constants/accountTabs';

import { ReactComponent as homeSvg } from 'assets/icons/home.svg';
import { ReactComponent as ShoppingCartSvg } from 'assets/icons/shoppingCartHeader.svg';
import { ReactComponent as UserSvg } from 'assets/icons/user.svg';
import { ReactComponent as CatalogSvg } from 'assets/icons/catalog.svg';

const { PERSONAL_INFO } = accountTabs;

export const accountPersonalInfoRoute = getAccountTabRoute(PERSONAL_INFO);

const { DASHBOARD, MOBILECATALOG, CART } = ROUTES;

export const items = [
  {
    path: DASHBOARD,
    Icon: homeSvg,
  },
  {
    path: MOBILECATALOG,
    Icon: CatalogSvg,
  },
  {
    path: CART,
    Icon: ShoppingCartSvg,
  },
  {
    path: accountPersonalInfoRoute,
    Icon: UserSvg,
  },
];
