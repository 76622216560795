const handleErrorMessage = (code: number) => {
  switch (code) {
    case 602:
      return 'Пользователь с таким номером телефона не найден. Пожалуйста, пройдите  регистрацию';
    case 605:
      return 'Неправильный код.';
    case 606:
      return 'Код истек.';
    case 607:
      return 'Пользователь с таким номером телефона уже существует.';
    case 608:
      return 'Запрос на регистрацию пользователя запрещен.';
    case 609:
      return 'Неправильный формат файла.';
    case 610:
      return 'Файл слишком большой.';
    case 611:
      return 'Товар не найден.';
    case 613:
      return 'Корзина пуста.';
    case 615:
      return 'Недостаточно МаксМани на вашем балансе';
    case 616:
      return 'Кредитная карта не найдена';
    case 617:
      return 'Неправильная роль пользователя';
    case 618:
      return 'Такой адрес уже существует';
    case 619:
      return 'Пользователь с таким телефонным номером уже существует';
    case 623:
      return 'Недостаточно продукта.';
    case 624:
      return 'категория не найдена';
    case 625:
      return 'Такой товар уже существует.';
    case 631:
      return 'Баланс не найден.';
    case 632:
      return 'Недостаточный баланс.';
    case 636:
      return 'Такой электронный адрес уже существует.';
    case 637:
      return 'Пользователь не активен';
    case 648:
      return 'Такая категория уже существует.';
    case 649:
      return 'Такая роль уже существует.';
    case 651:
      return 'Такой бренд уже существует.';
    case 654:
      return 'Файл пустой.';
    case 669:
      return 'Баннерная страница не найдена';
    case 800:
      return 'Транзакция не удалась.';
    case 717:
      return 'Город  не найден';
    case 716:
      return ' По выбранному Вами адресу курьерская доставка не осуществляется, пожалуйста замените Ваш город доставки.';
    default:
      return 'Что-то пошло не так';
  }
};

export default handleErrorMessage;
