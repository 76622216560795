import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { Meta, Status } from 'store/types';
import { read } from 'utils/api';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import formatFilterObject from 'helpers/formatFilterObject';

import { Brand, GetBrandsParams } from './types';

const getBrands = async (params: GetBrandsParams, { getState }) => {
  const {
    brands: { data: oldBrands },
    products: { partnerDeliveryData, storageDeliveryDurations },
  } = getState();

  const { enableDurationPostalCode, enableFiltrationV2 = false, postalCode, categoryId } = params;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  let url = REACT_APP_BUYER_URL;

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.categoryIds = categoryId;
    params.categoryId = undefined;
    params.postalCode = selectedPostalCode;
  }

  let selectedBrandIds = [];

  if (enableFiltrationV2) {
    url = REACT_APP_BUYER_URL_V2;

    if (params.filteringObject) {
      const { brandId } = params.filteringObject;
      selectedBrandIds = brandId ? brandId.split(',') : [];

      const newFilters = formatFilterObject(params.filteringObject);
      delete params.filteringObject;

      params = {
        ...params,
        ...newFilters,
      };

      if (params.brandId) {
        delete params.brandId;
      }

      if (params.categoryId) {
        params.categoryIds = params.categoryId;
        delete params.categoryId;
      }

      if (params.tagId) {
        params.tagIds = params.tagId;
        delete params.tagId;
      }
    }

    if (!enableDurationPostalCode) {
      params.withoutPostalCode = true;
    }
  }

  const { courierDeliveryDuration, deliveryDuration } = params;
  const deliveryDurations = deliveryDuration?.split('|~') || [];

  if (courierDeliveryDuration && !deliveryDurations?.includes('-3')) {
    deliveryDurations.push(-3);
    params.deliveryDuration = deliveryDurations.join('|~');
    delete params.courierDeliveryDuration;
  }

  if (
    params.deliveryDuration &&
    ((storageDeliveryDurations.includes(partnerDeliveryData[0]) &&
      params.deliveryDuration?.toString().includes(partnerDeliveryData[0])) ||
      params.deliveryDuration?.toString().includes('|~'))
  ) {
    delete params.deliveryDuration;
  }

  const response = await read(`${url}/brands`, {
    params: { ...params, enableDurationPostalCode: undefined, enableFiltrationV2: undefined },
  });

  if (enableFiltrationV2 && selectedBrandIds.length) {
    const oldSelectedBrands = oldBrands.filter(({ id }) => selectedBrandIds.includes(`${id}`));
    return { ...response, oldSelectedBrands };
  }

  return response;
};

interface BrandsState {
  data: Array<Brand>;
  meta: Meta;
  status: Status;
}

export const getBrandsList = createAsyncThunk('brands/list', getBrands);

const initialState = { data: [], meta: {}, status: 'idle' } as BrandsState;

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    resetBrands: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(getBrandsList.fulfilled.type, (state: any, action: any) => {
        const {
          payload: { meta, data, oldSelectedBrands = [] },
          meta: {
            arg: { page },
          },
        } = action;

        let newBrands = [];

        if (page === 1) {
          newBrands = [...oldSelectedBrands, ...data];
        } else {
          newBrands = [...state.data, ...data];
        }
        newBrands = newBrands.filter(
          (value, index, self) => index === self.findIndex(t => t.id === value.id)
        );
        return {
          ...state,
          status: 'success',
          data: newBrands,
          meta,
        };
      })
      .addCase(getBrandsList.pending.type, (state: BrandsState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getBrandsList.rejected.type, (state: BrandsState) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export const useBrandsData = (): BrandsState => useAppSelector((state: RootState) => state.brands);

export const { resetBrands } = brandsSlice.actions;

export default brandsSlice.reducer;
