import checkUserType from 'helpers/checkUserType';
import { read, create, update } from 'utils/api';

import { NewRequestParams, MakeIssueSeenParams, GetRequestsParams } from './types';

export const getRequests = async ({ userType, page }: GetRequestsParams) => {
  const response = await read(`${checkUserType(userType)}/issues?page=${page}&limit=10`);
  return response;
};

export const handleNewRequest = async ({ data, userType }: NewRequestParams) => {
  return create(`${checkUserType(userType)}/issues`, data);
};

export const handleMakeIssueSeen = async ({ userType, issueId }: MakeIssueSeenParams) => {
  const response = await update(`${checkUserType(userType)}/issues/${issueId}/seen`);
  return response;
};
