export const CHECKBOX_TYPE = 'checkbox';
export const RADIO_BUTTON_TYPE = 'radio';
export const RANGE_TYPE = 'range';
export const DEFAULT_FILTER_TYPE = 'default';

export const COLOR_FILTER_TYPE = 'color';
export const CATEGORIES_FILTER_NAME_IN_BANNER_PAGE = 'categoryIds';
export const BRAND_ID_FILTER_NAME = 'brandId';
export const TAG_ID_FILTER_NAME = 'tagId';
export const BRAND_ID_FILTER_NAME_V2 = 'brandIds';
export const TAG_ID_FILTER_NAME_V2 = 'tagIds';
export const OUT_OF_STOCK_FILTER_NAME = 'showNotExistingProducts';
