import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { BrandCategory } from './types';
import { Meta } from 'store/types';

interface BrandCategoriesState {
  data: Array<BrandCategory>;
  meta: Meta;
}

const initialState = { data: [], meta: {} } as BrandCategoriesState;

const getBrandCategories = id => read(`${REACT_APP_BUYER_URL}/brand-categories/${id}`);

export const getBrandCategoriesAction = createAsyncThunk(
  'currentBrand/categories',
  getBrandCategories
);
const BrandCategoriesSlice = createSlice({
  name: 'brandCategories',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getBrandCategoriesAction.fulfilled.type,
      (state: BrandCategoriesState, action: PayloadAction<BrandCategoriesState>) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          data,
          meta,
        };
      }
    );
  },
});

export const useBrandCategoriesData = (): BrandCategoriesState => {
  const reducerState = useAppSelector((state: RootState) => state.currentBrand.categories);
  return reducerState;
};

export const { reset } = BrandCategoriesSlice.actions;

export default BrandCategoriesSlice.reducer;
