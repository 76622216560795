import { combineReducers } from 'redux';
import info from './ProductInfo';
import similarProducts from './SimilarProducts';
import reviews from './ProductReviews';
import offerProducts from './ProductOffers';

const CurrentProductReducer = combineReducers({
  info,
  similarProducts,
  reviews,
  offerProducts,
});

export default CurrentProductReducer;
