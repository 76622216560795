import { useLocation, matchRoutes } from 'react-router-dom';

interface Routes {
  path: string;
}
export const useCurrentPath = (routes: Routes[]) => {
  const location = useLocation();
  const [{ route, params = {} }] = matchRoutes(routes, location) || [
    { route: { path: '' }, params: {} },
  ];

  return { path: route?.path, params };
};
