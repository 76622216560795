import { createAsyncThunk, createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { getUserData, updatePersonalInfo, updateLegalInfo } from './api';
import { UserDataEditParams, UserDataResponse, UserDataState } from './types';

import MaxMarketIcon from 'assets/icons/MaxMarket_icon.svg';
import userTypes from 'constants/userTypes';

const { wholesaler, franchise } = userTypes;

const initialState: UserDataState = {
  data: {},
  status: 'idle',
  updateUserDataSuccess: false,
};

export const readUserData = createAsyncThunk('userData/readData', () => getUserData());

export const updatePersonalInfoData = createAsyncThunk(
  'userData/updateData',
  async (data: UserDataEditParams) => {
    const response = await updatePersonalInfo({ ...data, city: 'Moscow' });
    return response;
  }
);

export const updateLegalInfoData = createAsyncThunk(
  'userData/updateLegalInfoData',
  (data: UserDataEditParams) => updateLegalInfo(data)
);

export const editUserDataSlice = createSlice({
  name: 'editUserData',
  initialState,
  reducers: {
    resetUpdateUserStatus: (state: UserDataState) => ({
      ...state,
      updateUserDataSuccess: false,
    }),
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(readUserData.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(
        readUserData.fulfilled.type,
        (state: UserDataState, action: PayloadAction<UserDataResponse>) => {
          const {
            role: { type },
          } = action.payload;
          if (type === +wholesaler || type === +franchise) {
            action.payload.avatar = MaxMarketIcon;
          }
          return {
            ...state,
            status: 'success',
            data: action.payload,
          };
        }
      )
      .addCase(readUserData.rejected.type, () => {
        return {
          ...initialState,
          status: 'failed',
        };
      })
      .addCase(updatePersonalInfoData.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(updatePersonalInfoData.fulfilled.type, (state: UserDataState, action: AnyAction) => {
        const data = cloneDeep(state.data);
        const {
          meta: { arg },
        } = action;
        const { logo, shopName: name } = arg;
        return {
          ...state,
          status: 'success',
          data: {
            ...data,
            ...arg,
            shop: {
              name,
              logo,
            },
          },
        };
      })
      .addCase(updatePersonalInfoData.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(updateLegalInfoData.pending.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(updateLegalInfoData.fulfilled.type, (state: UserDataState, action: any) => {
        const {
          meta: { arg },
        } = action;
        const data = cloneDeep(state.data);
        const { bankInfo, ...rest } = arg;
        return {
          status: 'success',
          updateUserDataSuccess: true,

          data: { ...data, bankInfo, userInfo: rest },
        };
      })
      .addCase(updateLegalInfoData.rejected.type, (state: UserDataState) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
});

export const useSellerData = (): UserDataState => {
  const reducerState = useAppSelector((state: RootState) => state.editSellerData);
  return reducerState;
};

export const { resetUpdateUserStatus, reset } = editUserDataSlice.actions;

export default editUserDataSlice.reducer;
