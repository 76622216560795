import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { read, create, edit, remove } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { changeOrderFields } from 'store/order';
import { Receiver } from 'store/user/recievers/types';

import { AddReceiverRequestInterface, Receivers } from './types';

const addReceiver = async (data: AddReceiverRequestInterface, { rejectWithValue, dispatch }) => {
  try {
    const response: any = await create(`${REACT_APP_BUYER_URL}/order-receivers`, data);
    const { id } = response;
    dispatch(
      changeOrderFields({
        field: 'mainReceiver',
        value: { ...data, id, isMain: true },
      })
    );
    return response;
  } catch (err: any) {
    const { response: { data: { details: { message = '' } = {} } = {} } = {} } = err;
    return rejectWithValue(message);
  }
};

const getReceivers = async (_, { dispatch }) => {
  const response = await read(`${REACT_APP_BUYER_URL}/order-receivers`);
  const { data } = response;

  const mainReceiver = data.find(({ isMain }) => isMain) || {};
  dispatch(
    changeOrderFields({
      field: 'mainReceiver',
      value: mainReceiver,
    })
  );
  return response;
};

const setMainReceiver = async (data: Receiver, { dispatch }) => {
  const response = await edit(`${REACT_APP_BUYER_URL}/order-receivers/${data.id}/main`);
  dispatch(
    changeOrderFields({
      field: 'mainReceiver',
      value: { ...data },
      isMain: true,
    })
  );
  return response;
};

const deleteReceivers = async (id: number) => {
  const response = await remove(`${REACT_APP_BUYER_URL}/order-receivers/${id}`);
  return response;
};

export const addReceiverThunk = createAsyncThunk('receiver/add', addReceiver);
export const getReceiversThunk = createAsyncThunk('receiver/get', getReceivers);
export const setMainReceiverThunk = createAsyncThunk('receiver/setAsMain', setMainReceiver);
export const deleteReceiversThunk = createAsyncThunk('receivers/delete', deleteReceivers);

const initialState: Receivers = {
  data: [],
  meta: {},
};

const newReceiversData = (state, mainReceiverId) => {
  const addressesCopy = [...state];
  return addressesCopy.map(address => {
    const { id } = address;
    return {
      ...address,
      isMain: mainReceiverId === id,
    };
  });
};
const ReceiversSlice = createSlice({
  initialState,
  name: 'receivers',
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(deleteReceiversThunk.fulfilled.type, (state: Receivers, action: any) => {
        const {
          meta: { arg: receiverId },
        } = action;
        const { data } = current(state);

        const receiver = data.filter(e => e.id !== receiverId);
        return {
          ...state,
          data: receiver,
        };
      })
      .addCase(addReceiverThunk.fulfilled.type, (state: Receivers, action: any) => {
        const {
          payload: { id },
          meta: { arg },
        } = action;
        const data = newReceiversData([{ ...arg, id }, ...state.data], id);
        return {
          ...state,
          data,
        };
      })
      .addCase(addReceiverThunk.rejected.type, (state: Receivers, action: any) => {
        const { payload } = action;
        toast.error(payload);
      })
      .addCase(
        getReceiversThunk.fulfilled.type,
        (state: Receivers, action: PayloadAction<Receivers>) => {
          const {
            payload: { data, meta },
          } = action;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(setMainReceiverThunk.fulfilled.type, (state: Receivers, action: any) => {
        const {
          arg: { id },
        } = action.meta;
        const data = newReceiversData(state.data, id);
        return {
          ...state,
          data,
        };
      });
  },
});

export const useUserRecieversData = (): Receivers => {
  const reducerState = useAppSelector((state: RootState) => state.user.receivers);
  return reducerState;
};

export const { reset } = ReceiversSlice.actions;

export default ReceiversSlice.reducer;
