import { createSlice, createAsyncThunk, current, AnyAction } from '@reduxjs/toolkit';

import { changeOrderFields } from 'store/order';
import { create, read, update, remove } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { updatedDeliveryDurationProductsThunk } from 'store/cart';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import handleErrorMessage from 'helpers/handleErroreMessage';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import { Address } from 'sections/Checkout/Delivery/DeliveryAddresses/types';

import { DeliveryAddressesState, DeliveryAddressRequestInterface } from './types';

const setMainDeliveryAddress = async (
  { data, enableDurationPostalCode }: { data: Address; enableDurationPostalCode: boolean },
  { dispatch }
) => {
  const { lat, lng, zipCode } = data;

  const response = await update(`${REACT_APP_BUYER_URL}/main-delivery-address`, {
    addressId: data.id,
  });

  if (lat && lng) {
    dispatch(
      updatedDeliveryDurationProductsThunk({
        lat,
        lng,
        postalCode: zipCode,
        enableDurationPostalCode,
      })
    );
  }

  dispatch(
    changeOrderFields({
      field: 'mainAddress',
      value: {
        ...data,
        isMain: true,
      },
    })
  );

  return response;
};

const getDeliveryAddresses = async (enableDurationPostalCode: boolean, { dispatch }) => {
  const urlEnc = enableDurationPostalCode ? REACT_APP_BUYER_URL_V2 : REACT_APP_BUYER_URL;

  const response = await read(`${urlEnc}/delivery-addresses?limit=100`);
  const { data } = response;

  const mainAddress = data.find(({ isMain }) => isMain);
  const { lat, lng, zipCode } = mainAddress || {};

  if (lat && lng) {
    dispatch(
      updatedDeliveryDurationProductsThunk({
        lat,
        lng,
        postalCode: zipCode,
        enableDurationPostalCode,
      })
    );
  }

  dispatch(
    changeOrderFields({
      field: 'mainAddress',
      value: mainAddress || {},
    })
  );

  return response;
};

const addDeliveryAddress = async (
  {
    data,
    enableDurationPostalCode,
  }: { data: DeliveryAddressRequestInterface; enableDurationPostalCode: boolean },
  { dispatch, rejectWithValue }
) => {
  try {
    const urlEnc = enableDurationPostalCode ? REACT_APP_BUYER_URL_V2 : REACT_APP_BUYER_URL;

    const response: any = await create(`${urlEnc}/delivery-addresses`, data);
    const { id } = response;

    const { lat, lng, zipCode } = data || {};

    if (lat && lng) {
      dispatch(
        updatedDeliveryDurationProductsThunk({
          lat,
          lng,
          postalCode: zipCode,
          enableDurationPostalCode,
        })
      );
    }

    dispatch(
      changeOrderFields({
        field: 'mainAddress',
        value: { ...data, id, isMain: true },
      })
    );

    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

const getUserAddresses = async () => await read(`${REACT_APP_BUYER_URL}/users/addresses`);

const deleteDeliveryAddresse = async (
  { id, enableDurationPostalCode }: { id: number; enableDurationPostalCode: boolean },
  { dispatch }: any
) => {
  const response = await remove(`${REACT_APP_BUYER_URL}/delivery-addresses/${id}`);

  const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
  const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
  if (lat && lng) {
    dispatch(
      updatedDeliveryDurationProductsThunk({ lat, lng, postalCode, enableDurationPostalCode })
    );
  }
  return response;
};

export const deleteDeliveryAddresseThunk = createAsyncThunk(
  'deliveryAddress/delete',
  deleteDeliveryAddresse
);

const editDeliveryAddress = async (
  { data, id, enableDurationPostalCode }: any,
  { dispatch, rejectWithValue }: any
) => {
  try {
    const response = await update(`${REACT_APP_BUYER_URL_V2}/delivery-addresses/${id} `, data);
    const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
    const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
    if (lat && lng) {
      dispatch(
        updatedDeliveryDurationProductsThunk({ lat, lng, postalCode, enableDurationPostalCode })
      );
      dispatch(
        changeOrderFields({
          field: 'mainAddress',
          value: { ...data, id, isMain: true },
        })
      );
    }
    dispatch(getDeliveryAddressesThunk(enableDurationPostalCode));
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const editDeliveryAddressThunk = createAsyncThunk(
  'userData/updateData',
  editDeliveryAddress
);

export const addDeliveryAddressThunk = createAsyncThunk('address/add', addDeliveryAddress);
export const getDeliveryAddressesThunk = createAsyncThunk('address/get', getDeliveryAddresses);
export const setMainDeliveryAddressThunk = createAsyncThunk(
  'address/setMain',
  setMainDeliveryAddress
);
export const getUsersAddressesThunk = createAsyncThunk('usersAddresses', getUserAddresses);

const initialState: DeliveryAddressesState = {
  data: [],
  shopAddresses: [],
  meta: {},
  createAndEditAddressErrorMessage: '',
  createAddressStatus: 'idle',
  editAddressStatus: 'idle',
};

const newAddressData = (state, mainAddressId) => {
  const addressesCopy = [...state];
  return addressesCopy.map(address => {
    const { id } = address;
    return {
      ...address,
      isMain: mainAddressId === id,
    };
  });
};

const deliveryAddress = createSlice({
  name: 'deliveryAddress',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetAddressErrorMessage: (state: DeliveryAddressesState) => {
      state.createAndEditAddressErrorMessage = '';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        deleteDeliveryAddresseThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const {
            meta: {
              arg: { id: addressId },
            },
          } = action;
          const { data } = current(state);

          const deliveryAddress = data.filter(e => e.id !== addressId);
          return {
            ...state,
            data: deliveryAddress,
          };
        }
      )
      .addCase(
        addDeliveryAddressThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const {
            payload: { id },
            meta: { arg: { data: deliveryAddressData = {} } = {} },
          } = action;

          const data = newAddressData([{ ...deliveryAddressData, id }, ...state.data], id);
          return {
            ...state,
            data,
            createAddressStatus: 'success',
          };
        }
      )
      .addCase(addDeliveryAddressThunk.pending.type, (state: DeliveryAddressesState) => {
        return {
          ...state,
          createAddressStatus: 'loading',
        };
      })
      .addCase(
        addDeliveryAddressThunk.rejected.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const createAndEditAddressErrorMessage = handleErrorMessage(action.payload);

          return {
            ...state,
            createAddressStatus: 'failed',
            createAndEditAddressErrorMessage,
          };
        }
      )
      .addCase(editDeliveryAddressThunk.fulfilled.type, (state: DeliveryAddressesState) => {
        const { data } = state;
        return {
          ...state,
          data,
          editAddressStatus: 'success',
        };
      })
      .addCase(editDeliveryAddressThunk.pending.type, (state: DeliveryAddressesState) => {
        return {
          ...state,
          editAddressStatus: 'loading',
        };
      })
      .addCase(
        editDeliveryAddressThunk.rejected.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const createAndEditAddressErrorMessage = handleErrorMessage(action.payload);
          return {
            ...state,
            editAddressStatus: 'failed',
            createAndEditAddressErrorMessage,
          };
        }
      )
      .addCase(
        getDeliveryAddressesThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const {
            payload: { data, meta },
          } = action;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(
        setMainDeliveryAddressThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: any) => {
          const { id } = action.meta.arg.data;
          const data = newAddressData(state.data, id);
          return {
            ...state,
            data,
          };
        }
      )
      .addCase(
        getUsersAddressesThunk.fulfilled.type,
        (state: DeliveryAddressesState, action: AnyAction) => {
          const { data } = action.payload;
          const shopAddresses = data.filter(({ latitude, longitude }) => latitude && longitude);

          return {
            ...state,
            shopAddresses,
          };
        }
      );
  },
});

export const useUserDeliveryAddressData = (): DeliveryAddressesState =>
  useAppSelector((state: RootState) => state.user.deliveryAddress);

export const { reset, resetAddressErrorMessage } = deliveryAddress.actions;

export default deliveryAddress.reducer;
