import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import bannerTypes from 'constants/bannerTypes';

import { BannerInterface } from './types';

const getBrandBanners = async (entityId: string) => {
  const response = read(
    `${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_BRAND_BANNER}/${entityId}`
  );
  return response;
};

export const getBrandBannersThunk = createAsyncThunk('banners/brand', getBrandBanners);

export const getCategoryBanners = async (entityId: number) => {
  const response = await read(
    `${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_CATEGORY_BANNER}/${entityId}`
  );
  return response;
};

const initialState: BannerInterface = {
  brandBanners: {
    data: [],
    status: 'idle',
  },
};

const BannersSlice = createSlice({
  name: 'banners',
  reducers: {
    resetBrandBanners(state: BannerInterface) {
      return {
        ...state,
        brandBanners: initialState.brandBanners,
      };
    },
  },
  initialState,
  extraReducers: builder => {
    builder.addCase(
      getBrandBannersThunk.fulfilled.type,
      (state: BannerInterface, action: AnyAction) => {
        const { data } = action.payload;
        return {
          ...state,
          brandBanners: {
            ...state.brandBanners,
            data,
          },
        };
      }
    );
  },
});

export const useBannersData = (): BannerInterface => {
  const reducerState = useAppSelector((state: RootState) => state.banner);
  return reducerState;
};

export const { resetBrandBanners } = BannersSlice.actions;
export default BannersSlice.reducer;
