import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  notificationsList: {
    width: 400,
    position: 'fixed',
    zIndex: 3000,
    top: 15,
    margin: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}));
