import { createContext, useState, useCallback, ReactChild } from 'react';

import Stack from '@mui/material/Stack';
import Alert, { AlertColor } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import noop from 'utils/noop';
import useStyles from './style';

interface ToasterProviderProps {
  children?: JSX.Element | JSX.Element[] | ReactChild | ReactChild[];
}

type NotificationData = {
  id?: number;
  type?: AlertColor;
  title?: string;
  message?: string;
};

interface ToasterProps {
  notification?: NotificationData;
  onClose: (id?: number) => void;
}

const ToasterContext = createContext<[NotificationData[], (not?: NotificationData) => void]>([
  [], // inital state
  noop,
]);

const TIMEOUT = 3600;
const SHOW_COUNT = 5;

const Toaster = ({ notification, onClose }: ToasterProps): JSX.Element => {
  return (
    <Alert
      severity={notification?.type}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => onClose(notification?.id)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {notification?.message}
    </Alert>
  );
};

const ToasterProvider = ({ children }: ToasterProviderProps): JSX.Element => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState<NotificationData[]>([]);

  const remove = useCallback((notId?: number) => {
    setNotifications(prev => prev.filter(({ id }) => id !== notId));
  }, []);

  const setter = useCallback(
    not => {
      const notId = Date.now();
      setNotifications(prev => {
        if (prev[prev?.length - 1]?.message !== not.message) {
          const next = [
            ...prev,
            {
              id: notId,
              type: 'success',
              ...not,
            },
          ];
          if (next.length >= SHOW_COUNT) {
            next.pop();
          }
          return next;
        }
        return prev;
      });

      setTimeout(() => remove(notId), not.deleteDelay || TIMEOUT);
    },
    [remove]
  );

  return (
    <ToasterContext.Provider value={[notifications, setter]}>
      <Stack className={classes.notificationsList} spacing={2}>
        {notifications?.length > 0 &&
          notifications.map(not => <Toaster key={not.id} notification={not} onClose={remove} />)}
      </Stack>
      {children}
    </ToasterContext.Provider>
  );
};

export { ToasterContext };
export default ToasterProvider;
