const formattedPhone = (phoneNumber: string) => {
  if (phoneNumber) {
    const phoneNumberStr = `${phoneNumber}`;
    const result = [
      phoneNumberStr.slice(0, 3),
      phoneNumberStr.slice(3, 6),
      phoneNumberStr.slice(6, 8),
      phoneNumberStr.slice(8, 10),
    ];
    return result.join(' ');
  } else {
    return '';
  }
};

export default formattedPhone;
