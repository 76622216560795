import { FC } from 'react';

import { Tooltip } from '@mui/material';

import { ADD_TO_CART_TOOLTIP_MESSAGE } from 'constants/defaults';
import userTypes from 'constants/userTypes';
import { authStorage } from 'store/storage';

interface Props {
  children: JSX.Element;
  disabled: boolean;
  tooltipTitle?: string;
  showTooltipTitle?: boolean;
}

const AddToShoppingCartTooltip: FC<Props> = ({
  children,
  disabled,
  tooltipTitle,
  showTooltipTitle = true,
}): JSX.Element => {
  const userType = +localStorage.getItem('userType');
  const isLoggedIn = !!authStorage.get().accessToken;
  const isBuyerType = +userType !== +userTypes.buyer;

  return showTooltipTitle ? (
    <Tooltip
      placement="top"
      title={
        disabled && !(isBuyerType && isLoggedIn) ? tooltipTitle || ADD_TO_CART_TOOLTIP_MESSAGE : ''
      }
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default AddToShoppingCartTooltip;
