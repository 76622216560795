export default {
  PERSONAL_INFO: 'personal-info',
  ORDERS: 'orders',
  CONNECT: 'connect',
  LEGAL_INFO: 'legal-info',
  STATISTIC: 'statistic',
  BALANCE: 'balance',
  UPLOAD_CATALOG: 'upload-catalog',
  WHOLESALER_ORDERS: 'wholesaler-orders',
  WISHLIST: 'wishlist',
};
