import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { Mactuses } from './types';

const getMactuses = async () => {
  const response = await read(`${REACT_APP_BUYER_URL}/mactuses`);
  return response;
};

export const getMactusesThunk = createAsyncThunk('mactuses/get', getMactuses);

const initialState: Mactuses = {
  data: [],
  status: 'idle',
  selectedMactus: {
    name: '',
    path: '',
  },
};

const MactusesSlice = createSlice({
  name: 'mactueses',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getMactusesThunk.fulfilled.type, (state: Mactuses, action: AnyAction) => {
        const { data } = action.payload;
        return {
          ...state,
          data,
          status: 'success',
        };
      })
      .addCase(getMactusesThunk.pending.type, (state: Mactuses) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(getMactusesThunk.rejected.type, (state: Mactuses) => {
        return {
          ...state,
          status: 'failed',
        };
      });
  },
  reducers: {
    setSelectedMactus: (state: Mactuses, action: any) => {
      return {
        ...state,
        selectedMactus: action.payload,
      };
    },
  },
});

export const useMactusesData = (): Mactuses => {
  const reducerState = useAppSelector((state: RootState) => state.buyer.mactuses);
  return reducerState;
};

export default MactusesSlice.reducer;
export const { setSelectedMactus } = MactusesSlice.actions;
