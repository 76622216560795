import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { getCategoryBanners } from 'store/banners';
import { setRedirectionUrl } from 'store/routerRedirect';
import ROUTES from 'constants/routes';

import {
  ParentCategories,
  SubCategoryInterface,
  SubCategoriesInterface,
  ProductsPageSubCategoryInterface,
  Category,
} from './types';

const getParentCategories = async (page: number) => {
  return await read(`${REACT_APP_BUYER_URL}/main-categories?page=${page}&limit=100`);
};

const getSubCategory = async (id: number, { getState, signal }: any) => {
  const {
    catalog: { subCategories },
  } = getState();
  const subCategory = subCategories.find(item => id === item.subCategory.id);

  if (subCategory) {
    return subCategory;
  } else {
    const response = await read(`${REACT_APP_BUYER_URL}/categories/${id}`, { signal });
    const bannerResponse = await getCategoryBanners(id);
    const banner = bannerResponse.data[0] || null;
    return { subCategory: response, banner };
  }
};

const getSubCategoryProductsPage = async (id: number, { dispatch }) => {
  try {
    return await read(`${REACT_APP_BUYER_URL}/categories/${id}`);
  } catch (err: any) {
    const { response: { data: { status = 0 } = {} } = {} } = err;
    status === 404 && dispatch(setRedirectionUrl(ROUTES.DASHBOARD));
  }
};

interface CatalogState {
  parentCategories: ParentCategories;
  subCategory: SubCategoryInterface;
  productsPageSubCategory: ProductsPageSubCategoryInterface;
  subCategories: SubCategoriesInterface[];
}

export const getParentCategoriesThunk = createAsyncThunk('catalog/list', getParentCategories);

export const getSubCategoryThunk = createAsyncThunk('catalog/subCategorieslist', getSubCategory);

export const getSubCategoryProductsPageThunk = createAsyncThunk(
  'productsPage/subCategory',
  getSubCategoryProductsPage
);

const initialState = {
  subCategory: { data: null, status: 'idle', banner: null },
  subCategories: [],
  productsPageSubCategory: { data: null, status: 'idle' },
  parentCategories: { data: [], meta: {}, status: 'idle' },
} as CatalogState;

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    resetSubCategories: (state: CatalogState) => {
      return {
        ...state,
        subCategory: {
          ...initialState.subCategory,
          data: null,
        },
      };
    },
    resetProductsPageSubCategory: (state: CatalogState) => {
      state.productsPageSubCategory = initialState.productsPageSubCategory;
    },
    setSubCategories: (state: CatalogState, action: PayloadAction<any>) => {
      const { subCategory, banner } = action.payload;

      return {
        ...state,
        subCategories: [
          ...state.subCategories,
          {
            subCategory,
            banner,
          },
        ],
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getParentCategoriesThunk.fulfilled.type,
        (state: CatalogState, action: PayloadAction<ParentCategories>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            parentCategories: {
              data: [...state.parentCategories.data, ...data],
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(getParentCategoriesThunk.pending.type, (state: CatalogState) => {
        return {
          ...state,
          parentCategories: {
            ...state.parentCategories,
            status: 'loading',
          },
        };
      })
      .addCase(getParentCategoriesThunk.rejected.type, (state: CatalogState) => {
        return {
          ...state,
          parentCategories: {
            ...state.parentCategories,
            status: 'failed',
          },
        };
      })
      .addCase(
        getSubCategoryThunk.fulfilled.type,
        (state: CatalogState, action: PayloadAction<SubCategoriesInterface>) => {
          const { subCategory, banner } = action.payload;

          return {
            ...state,
            subCategory: {
              data: subCategory,
              banner,
              status: 'success',
            },
            subCategories: [
              ...state.subCategories,
              {
                subCategory,
                banner,
              },
            ],
          };
        }
      )
      .addCase(getSubCategoryThunk.pending.type, (state: CatalogState) => {
        return {
          ...state,
          subCategory: {
            ...state.subCategory,
            status: 'loading',
          },
        };
      })
      .addCase(
        getSubCategoryProductsPageThunk.fulfilled.type,
        (state: CatalogState, action: PayloadAction<Category>) => {
          return {
            ...state,
            productsPageSubCategory: {
              data: action.payload,
              status: 'success',
            },
          };
        }
      )
      .addCase(getSubCategoryProductsPageThunk.pending.type, (state: CatalogState) => {
        return {
          ...state,
          productsPageSubCategory: {
            ...state.productsPageSubCategory,
            status: 'loading',
          },
        };
      })
      .addCase(getSubCategoryProductsPageThunk.rejected.type, (state: CatalogState) => {
        return {
          ...state,
          productsPageSubCategory: {
            ...state.productsPageSubCategory,
            status: 'failed',
          },
        };
      });
  },
});

export const useCatalogData = (): CatalogState => {
  const reducerState = useAppSelector((state: RootState) => state.catalog);
  return reducerState;
};

export const { resetSubCategories, setSubCategories, resetProductsPageSubCategory } =
  catalogSlice.actions;
export default catalogSlice.reducer;
