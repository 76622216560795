import { NavLink } from 'react-router-dom';

import classNames from 'classnames';

import { authStorage } from 'store/storage';
import { useShoppingCart } from 'store/cart';
import useCheckScreen from 'utils/useCheckScreen';
import ROUTES from 'constants/routes';
import { useCurrentPath } from 'utils/useCurrentPath';

import { items, accountPersonalInfoRoute } from './constants';

import './index.scss';

const MobileFooterNavBar = () => {
  const { count } = useShoppingCart();
  const { CART, ACCOUNT_MOBILE, MOBILECATALOG, PRODUCTS_CATEGORIES, DASHBOARD, SIGN_IN, ACCOUNT } =
    ROUTES;
  const isLoggedIn = !!authStorage.get().accessToken;

  const isTablet = useCheckScreen({
    type: 'tablet',
  });

  const isMobile = useCheckScreen({
    type: 'mobile',
  });

  const { path: currentPath } = useCurrentPath([
    { path: DASHBOARD },
    { path: ACCOUNT_MOBILE },
    { path: SIGN_IN },
    { path: ACCOUNT },
    { path: CART },
    { path: MOBILECATALOG },
    { path: PRODUCTS_CATEGORIES },
  ]);

  return (
    <div className="mobile_footer">
      {items.map(el => {
        const { path, Icon } = el;
        let isActive = path === currentPath;

        if (path === accountPersonalInfoRoute || path === SIGN_IN) {
          isActive = [ACCOUNT_MOBILE, SIGN_IN, ACCOUNT].includes(currentPath);
        }

        if (path === MOBILECATALOG) {
          isActive = [MOBILECATALOG, PRODUCTS_CATEGORIES].includes(currentPath);
        }
        const chekeAccountRoute = (isMobile || isTablet) && path === accountPersonalInfoRoute;

        const to = chekeAccountRoute ? (isLoggedIn ? ACCOUNT_MOBILE : SIGN_IN) : path;

        return (
          <NavLink key={path} to={to}>
            <div className={classNames('mobile_cart', { isActive })}>
              {path === CART && <div className="mobile_count">{count >= 99 ? `99+` : count}</div>}
              <Icon />
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default MobileFooterNavBar;
