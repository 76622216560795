import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

import { DynamicPupUpInterface } from './type';

const getDynumicPupUp = async () => {
  const response = await read(`${REACT_APP_BUYER_URL}/dynamic-popup`);
  return response;
};

export const getDynumicPupUpThunk = createAsyncThunk('dynamicPupUp', getDynumicPupUp);

const initialState: DynamicPupUpInterface = {
  data: null,
};

const DynamicPupUpSlice = createSlice({
  name: 'dynamicPupUp',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getDynumicPupUpThunk.fulfilled.type,
      (state: DynamicPupUpInterface, action: any) => {
        const { payload } = action;
        const pupUpId = +sessionStorage.getItem('pupUpId');
        if (pupUpId || pupUpId !== payload.id || !payload.cannotClose) {
          sessionStorage.setItem('pupUpId', payload.id);
          return {
            ...state,
            data: payload,
          };
        } else {
          return state;
        }
      }
    );
  },
});

export const useDynamicPupUpData = (): DynamicPupUpInterface =>
  useAppSelector((state: RootState) => state.dynamicPupUp);

export default DynamicPupUpSlice.reducer;
