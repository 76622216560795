import { combineReducers } from 'redux';

import orders from 'store/seller/orders';
import statistics from 'store/seller/statistics';
import catalogues from 'store/seller/catalogues';
import wholesalerPurchase from 'store/seller/wholesaler/purchase';
import balance from 'store/seller/balance';
import wholesalerB2BOrders from './wholesalerB2BOrders';

export default combineReducers({
  orders,
  catalogues,
  statistics,
  wholesalerPurchase,
  balance,
  wholesalerB2BOrders,
});
