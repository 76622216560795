import { ReactComponent as MarkerSvg } from 'assets/icons/marker.svg';
import { ReactComponent as DeliveryTruckSvg } from 'assets/icons/deliveryDate.svg';

const deliveryMethods = {
  pickUp: 2,
  delivery: 1,
};

export const deliveryMethodData = {
  [deliveryMethods.delivery]: {
    label: 'Курьером',
    icon: DeliveryTruckSvg,
  },
  [deliveryMethods.pickUp]: {
    label: 'Самовывоз',
    icon: MarkerSvg,
  },
};

export default deliveryMethods;
