import { useCallback, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography, InputAdornment } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';

import { REACT_APP_NODE_ENV } from 'constants/config';
import Form, { FormInput, useForm } from 'components/Form';
import Fb from 'components/FB';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import RegistrationAndLoginButtons from 'components/RegistrationAndLoginButtons';
import RadioButton from 'components/custom/RadioButton/index';
import Checkbox from 'components/custom/Checkbox';
import { defaultParamsPreRegistrationAndLogin } from 'constants/auth/preRegistrationAndLogin';
import ROUTES from 'constants/routes';
import { checkPhonePreRegistrationThunk, resetPreRegistration } from 'store/auth/preRegistration';
import VerificationPopup from './OTPverification';
import { registrationSchema } from 'resolvers';
import formattedPhone from 'helpers/formattedPhone';

import useStyles from './styles';
import './style.scss';

const RegistrationPage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState(1);
  const [agreeWithTermsChecked, setAgreeWithTermsChecked] = useState<boolean>(true);
  const [hideErrorMessage, setHideErrorMessage] = useState<boolean>(false);

  const {
    preRegistration: { status, errorMessage, phoneIsValid },
  } = useAppSelector((state: RootState) => state);

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useForm<{
    phoneNumber?: string;
  }>({
    resolver: registrationSchema,
    defaultValues: {
      phoneNumber: '',
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetPreRegistration());
    };
  }, []);

  const phoneNumber = watch('phoneNumber');

  const handleChangeType = event => setType(event.target.value);

  const onSubmit = () => {
    setHideErrorMessage(true);
    dispatch(resetPreRegistration());
    dispatch(
      checkPhonePreRegistrationThunk({
        phoneNumber: +phoneNumber,
        ...defaultParamsPreRegistrationAndLogin,
      })
    );
  };

  useEffect(() => {
    if (phoneNumber.length < 10) {
      setHideErrorMessage(false);
    }
  }, [phoneNumber]);

  const validationProps = useCallback(
    (field: string) => {
      const fieldError = errors?.[field];
      return {
        name: field,
        type: field,
        error: !!fieldError,
        helperText: fieldError?.message,
      };
    },
    [errors]
  );

  return phoneIsValid ? (
    <Fb justifyCenter alignCenter flex1 sx={{ p: 2 }}>
      <VerificationPopup
        text={`Мы отправили код подтверждения на номер +7 ${
          formattedPhone(phoneNumber) || '000 000 00 00'
        }`}
        phoneNumber={+phoneNumber}
        type={type}
      />
    </Fb>
  ) : (
    <Fb justifyCenter alignCenter flex1 sx={{ backgroundColor: '#FFF', pt: 6, pb: 6 }}>
      <Fb
        sx={{
          maxWidth: '100%',
          border: '1px solid #000',
          px: {
            xs: 2,
            md: 8,
          },
          py: {
            xs: 3,
            md: 6,
          },
          borderRadius: 5,
        }}
      >
        <Form
          style={{
            maxWidth: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <RegistrationAndLoginButtons />
          <Fb justifyCenter column sx={{ p: 3 }}>
            <Stack
              spacing={'40px'}
              sx={{
                maxWidth: '100%',
                width: '320px',
              }}
            >
              <Stack spacing="10px">
                <Typography>Введите ваш номер телефона</Typography>
                <FormInput
                  sx={{
                    maxWidth: '100%',
                  }}
                  resetField={resetField}
                  control={control}
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+7</InputAdornment>,
                  }}
                  {...validationProps('phoneNumber')}
                />
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={type}
                  onChange={handleChangeType}
                >
                  <FormControlLabel
                    value="1"
                    label="Я покупатель"
                    control={<RadioButton className={classes.radioButton} />}
                  />
                  {REACT_APP_NODE_ENV === 'production' ? (
                    <a
                      className="salesman"
                      href="https://maxmarket24.ru/personal/login/regsaler.php"
                    >
                      <div className="radio"></div>Я предприниматель
                    </a>
                  ) : (
                    <FormControlLabel
                      value="2"
                      label="Я предприниматель"
                      control={<RadioButton className={classes.radioButton} />}
                    />
                  )}
                </RadioGroup>
              </Stack>
              <Stack spacing="6px" sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={status === 'loading' || !agreeWithTermsChecked}
                  disableRipple
                >
                  Продолжить
                </Button>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#db287e',
                    height: '40px',
                  }}
                >
                  {hideErrorMessage && errorMessage}
                </p>
              </Stack>

              <Fb alignStart>
                <Checkbox
                  checked={agreeWithTermsChecked}
                  onChange={(_, checked) => setAgreeWithTermsChecked(checked)}
                  sx={{ mt: '2px' }}
                />
                <Typography sx={{ fontWeight: '500', fontSize: '14px', textAlign: 'center' }}>
                  Согласен с условиями{' '}
                  <Link className="link" to={ROUTES.PLACINGANORDER} target="_blank">
                    <u>Правил пользования торговой площадкой и правилами возврата</u>
                  </Link>
                </Typography>
              </Fb>
            </Stack>
          </Fb>
        </Form>
      </Fb>
    </Fb>
  );
};

export default RegistrationPage;
