import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { read, create } from 'utils/api';
import { REACT_APP_BUYER_URL } from 'constants/config';

import { PassSurveyParams, SurveyState } from './types';

const initialState: SurveyState = {
  data: [],
  selectedSurveyId: null,
  selectedSurveyBonus: null,
  getStatus: 'idle',
  sendStatus: 'idle',
};

export const getSurveyDataThunk = createAsyncThunk('surveys/list', async () => {
  return await read(`${REACT_APP_BUYER_URL}/surveys`);
});

export const passSurveyThunk = createAsyncThunk(
  'surveys/pass',
  async (values: PassSurveyParams) => {
    return await create(`${REACT_APP_BUYER_URL}/survey`, { ...values });
  }
);

const Survey = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    resetSurveyData: () => initialState,
    removePassedSurvey: (state: SurveyState) => {
      const { selectedSurveyId } = state;
      const newData = state.data.filter(item => item.id !== selectedSurveyId);
      const { id, bonus } = newData[0] || { id: null, bonus: null };

      return {
        ...state,
        selectedSurveyId: id,
        selectedSurveyBonus: bonus,
        data: newData,
      };
    },
    setSelectedSurvey: (
      state: SurveyState,
      action: PayloadAction<{ selectedSurveyBonus: number; selectedSurveyId: number }>
    ) => {
      const { selectedSurveyBonus, selectedSurveyId } = action.payload;
      state.selectedSurveyId = selectedSurveyId;
      state.selectedSurveyBonus = selectedSurveyBonus;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSurveyDataThunk.fulfilled.type, (state: SurveyState, action: any) => {
        const { payload: { data = [] } = {} } = action;
        const activeSurvey = data?.[0] || { id: null, bonus: null };
        const { id, bonus } = activeSurvey;
        state.data = data;
        state.selectedSurveyId = id;
        state.selectedSurveyBonus = bonus;
        state.getStatus = 'success';
      })
      .addCase(getSurveyDataThunk.pending.type, (state: SurveyState) => {
        state.getStatus = 'loading';
      })
      .addCase(getSurveyDataThunk.rejected.type, (state: SurveyState) => {
        state.getStatus = 'failed';
      })
      .addCase(passSurveyThunk.fulfilled.type, (state: SurveyState) => {
        state.sendStatus = 'success';
      })
      .addCase(passSurveyThunk.pending.type, (state: SurveyState) => {
        state.sendStatus = 'loading';
      })
      .addCase(passSurveyThunk.rejected.type, (state: SurveyState) => {
        state.sendStatus = 'failed';
      });
  },
});

export const useGetSurveyData = (): SurveyState => {
  const reducerState = useAppSelector((state: RootState) => state.survey);
  return reducerState;
};

export const { resetSurveyData, setSelectedSurvey, removePassedSurvey } = Survey.actions;

export default Survey.reducer;
