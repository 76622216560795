import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import preLogin from './auth/preLogin';
import login from './auth/login';
import preRegistration from './auth/preRegistration';
import sellerRegistration from './auth/registration/sellerRegistration';
import sellerPreRegistration from './auth/registration/sellerOTP';
import buyerRegistration from './auth/registration/buyerRegistration';
import editSellerData from './account/seller';
import brands from './brands';
import currentBrand from './currentBrand';
import currentProduct from './currentProduct';
import userCreditCards from './userCreditCard';
import order from './order';
import routerRedirect from './routerRedirect';
import cart from './cart';
import user from './user';
import buyer from './buyer';
import dashboard from './dashboard';
import seller from './seller';
import products from './products';
import request from './request';
import catalog from './catalog';
import banner from './banners';
import promocodes from './promocodes';
import categories from './categories';
import wishlist from './wishlist';
import maintenance from './maintenance';
import { BoxBerryPointsApi } from './boxBerryPoints';
import { CdekPointsApi } from './CdekPoints';
import interested from './interested';
import dynamicPages from './dynamicPages';
import dynamicPupUp from './dynumicPupUp';
import metaTemplates from './metaTemplates';
import orderDiscount from './order/orderDiscount';
import survey from './survey';
import generalInternalSettings from './generalInternalSettingsState';
import contactInformation from './ContactInformation/ContactInformation';

export const store = configureStore({
  reducer: {
    login,
    brands,
    preLogin,
    currentBrand,
    editSellerData,
    currentProduct,
    cart,
    catalog,
    preRegistration,
    buyerRegistration,
    sellerRegistration,
    sellerPreRegistration,
    userCreditCards,
    routerRedirect,
    order,
    user,
    buyer,
    dashboard,
    seller,
    products,
    request,
    banner,
    promocodes,
    categories,
    wishlist,
    maintenance,
    interested,
    dynamicPages,
    dynamicPupUp,
    metaTemplates,
    orderDiscount,
    survey,
    generalInternalSettings,
    contactInformation,
    [BoxBerryPointsApi.reducerPath]: BoxBerryPointsApi.reducer,
    [CdekPointsApi.reducerPath]: CdekPointsApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(BoxBerryPointsApi.middleware, CdekPointsApi.middleware),
});

export const dispatch = store.dispatch;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
