import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface Props {
  titleName?: string;
  metaDescription?: string;
  children?: React.ReactNode;
}

export const HelmetComponent: FC<Props> = ({ titleName, metaDescription, children }) => {
  const location = useLocation();
  const pagesForReloadRegExp = /product|category|categories|error/;

  if (metaDescription) {
    document.querySelector('meta[name="description"]')?.setAttribute('content', metaDescription);
  }

  if (!pagesForReloadRegExp.test(location.pathname)) {
    document.title = titleName ? titleName : 'MaxMarket';
  }
  useEffect(() => {
    setTimeout(() => {
      try {
        const retailrocket = window['retailrocket'];
        if (retailrocket) {
          retailrocket.markup.render();
        }
      } catch (e) {
        return;
      }
    }, 3000);

    return () => {
      document.title = 'MaxMarket';
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', 'Маркетплейс нового поколения');
    };
  }, []);

  return <Helmet>{children}</Helmet>;
};

export default HelmetComponent;
