import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { read } from 'utils/api';

import { getOrderDiscountsInterface, OrderDiscountState } from './types';

const getOrderDiscounts = async () => {
  return await read(`${REACT_APP_BUYER_URL}/order-discounts`);
};

export const getOrderDiscountsThunk = createAsyncThunk('cart/getOrderDiscounts', getOrderDiscounts);

const initialState: OrderDiscountState = {
  data: {
    pickup_discount: {
      description: '',
      isActive: false,
      name: '',
      value: 0,
    },
    online_discount: {
      description: '',
      isActive: false,
      name: '',
      value: 0,
    },
    deliveryPriceBtCity: 0,
    currentCityPrice: 0,
  },
};

const OrderDiscountSlice = createSlice({
  name: 'orderDiscount',
  initialState,
  reducers: {
    setDeliveryPrice: (state: OrderDiscountState, action: PayloadAction<{ price: number }>) => {
      return {
        ...state,
        data: {
          ...state.data,
          deliveryPriceBtCity: action.payload.price,
        },
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getOrderDiscountsThunk.fulfilled.type,
      (state: OrderDiscountState, action: any) => {
        const { data } = action.payload;

        const transformedData = {};

        data.forEach((discount: getOrderDiscountsInterface) => {
          const { name } = discount;
          transformedData[`${name}`] = discount;
        });

        return {
          ...state,
          data: { ...state.data, ...transformedData },
        };
      }
    );
  },
});

export const { setDeliveryPrice } = OrderDiscountSlice.actions;

export const useOrderDiscount = (): any =>
  useAppSelector((state: RootState) => state.orderDiscount.data);

export default OrderDiscountSlice.reducer;
