import checkUserType from 'helpers/checkUserType';
import { read, create } from 'utils/api';

import { NewMessageData } from './types';

interface Params {
  userType: string;
  issueId: number;
  page: number;
}

export const getIssueMessages = async ({ userType, issueId, page }: Params) => {
  const response = await read(`${checkUserType(userType)}/issues/${issueId}/messages?page=${page}`);
  return response;
};

export const handleNewMessage = async ({
  data,
  userType,
}: {
  data: NewMessageData;
  userType: string;
}) => {
  const { files } = data;
  return create(`${checkUserType(userType)}/issues`, { ...data, files: files.map(e => e.path) });
};
