import CircularProgress from '@mui/material/CircularProgress';

import Fb from 'components/FB';

interface Props {
  className?: string;
}

const MaxMarketLoader = ({ className }: Props) => {
  return (
    <Fb className={className} flex1 alignCenter justifyCenter sx={{ width: '100%' }}>
      <CircularProgress size={45} sx={{ color: '#C6C6C6' }} />
    </Fb>
  );
};

export default MaxMarketLoader;
