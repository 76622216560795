import { Ref, forwardRef } from 'react';

import { Controller, Control } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/inputErrorIcon.svg';

import './style.scss';

interface FormInputProps {
  control: Control;
  mask?: string;
  maskChar?: string;
  resetField?: any;
  maskPlaceholder?: null;
}

const FormInput = forwardRef(
  (
    {
      name,
      disabled,
      label,
      type,
      control,
      placeholder,
      resetField,
      maskPlaceholder,
      mask,
      InputProps,
      ...props
    }: FormInputProps & TextFieldProps,
    ref: Ref<HTMLInputElement>
  ): JSX.Element => {
    if (!name || !control) {
      return <></>;
    }

    const { error, select, className } = props;

    if (error) {
      InputProps = {
        ...InputProps,
        endAdornment: (
          <InputAdornment
            className={classNames({ 'select-error-icon': select && error })}
            position="end"
          >
            <ErrorIcon className="cursor-pointer" onClick={() => resetField(name)} />
          </InputAdornment>
        ),
      };
    }

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { value, ...field } }) =>
          mask ? (
            <InputMask
              mask={mask}
              maskPlaceholder={maskPlaceholder}
              value={value}
              {...field}
              disabled={disabled}
            >
              <TextField
                autoComplete="off"
                {...props}
                InputProps={InputProps}
                ref={ref}
                type={type}
                label={label}
                disabled={disabled}
                placeholder={placeholder}
                className={classNames(`account_input form-input ${className}`, {
                  'select-text-field': select,
                })}
              />
            </InputMask>
          ) : (
            <TextField
              autoComplete="off"
              {...props}
              {...field}
              InputProps={InputProps}
              ref={ref}
              type={type}
              label={label}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              className={classNames(`account_input ${className}`, { 'select-text-field': select })}
            />
          )
        }
      />
    );
  }
);

FormInput.displayName = 'FormInput';

FormInput.defaultProps = {
  fullWidth: true,
};

export default FormInput;
