import { useEffect, useState } from 'react';

import { useOrderData } from 'store/order';
import paymentMethods from 'constants/paymentMethod';
import { installmentPlanLimit } from 'constants/defaults';
import { tooltipInstallmentLimitTitle } from 'components/MaxMoney/constants';

import alertTriangleIcon from 'assets/icons/alert-triangle.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

const CheckInstallmentLimitAlert = (): JSX.Element => {
  const [show, setShow] = useState<boolean>(true);

  const { paymentMethod, totalPrice } = useOrderData();

  useEffect(() => {
    return () => {
      setShow(true);
    };
  }, [paymentMethod, totalPrice]);

  const { onInstallmentPlan } = paymentMethods;

  const closeAlert = () => {
    setShow(false);
  };

  return (
    paymentMethod === onInstallmentPlan &&
    totalPrice < installmentPlanLimit &&
    show && (
      <div className="alert-warning">
        <div className="alert-warning-info">
          <img src={alertTriangleIcon} />
          <div className="alert-body-wrap">
            <p className="alert-title">Предупреждение</p>
            <p className="alert-description"> {tooltipInstallmentLimitTitle} </p>
          </div>
          <img className="cursor-pointer" onClick={closeAlert} src={DeleteAlertIcon} />
        </div>
      </div>
    )
  );
};

export default CheckInstallmentLimitAlert;
