import { combineReducers } from 'redux';

import requests from './requests';
import oneRequest from './oneRequest';

const requestReducer = combineReducers({
  requests,
  oneRequest,
});

export default requestReducer;
