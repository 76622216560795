import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_SELLER_URL } from 'constants/config';
import { read, create } from 'utils/api';
import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';

import { CatalogListState, CatalogRequestData } from './types';

const getSellerCatalogues = async () => {
  const response = await read(`${REACT_APP_SELLER_URL}/product-catalogues`);
  return response;
};

const handleUploadCatalog = async (data: CatalogRequestData) => {
  return create(`${REACT_APP_SELLER_URL}/product-catalogues`, data);
};

export const getSellerCataloguesThunk = createAsyncThunk(
  'seller/getCatalogues',
  getSellerCatalogues
);

export const handleUploadCatalogThunk = createAsyncThunk(
  'seller/upload-catalog',
  handleUploadCatalog
);

const initialState: CatalogListState = {
  data: [],
  meta: {},
};

const SellerCataloguesList = createSlice({
  name: 'seller/catalogues',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getSellerCataloguesThunk.fulfilled.type,
        (state: CatalogListState, action: PayloadAction<CatalogListState>) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            meta,
          };
        }
      )
      .addCase(handleUploadCatalogThunk.fulfilled.type, (state: CatalogListState, action: any) => {
        const { payload, meta } = action;
        return {
          ...state,
          data: [{ ...payload, ...meta.arg, status: 1 }, ...state.data],
        };
      });
  },
});

export const useSellerCataloguesData = (): CatalogListState => {
  const reducerState = useAppSelector((state: RootState) => state.seller.catalogues);
  return reducerState;
};

export const { reset } = SellerCataloguesList.actions;

export default SellerCataloguesList.reducer;
