const formatFilterObject = (filteringObject: any) => {
  // if (filteringObject) {
  //   const newFilters = structuredClone(filteringObject);
  //
  //   // iteration for convert russian letter data with double quotes
  //   Object.keys(newFilters).map(pr => {
  //     if (isNaN(newFilters[pr]) && pr !== 'order') {
  //       const items: Array<string> = newFilters[pr].split(',');
  //       newFilters[pr] = items.map(itm => (isNaN(Number(itm)) ? `"${itm}"` : itm)).join(',');
  //     }
  //   });
  //
  //   return newFilters;
  // }
  //
  // return null

  return filteringObject;
};

export default formatFilterObject;
