import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFlag } from '@unleash/proxy-client-react';

import { AppDispatch } from 'store';
import { authStorage } from 'store/storage';
import {
  getShoppingCartThunk,
  getShoppingCartDurationsForLoggedOutUserThunk,
  useShoppingCart,
} from 'store/cart';
import { getProductDurationThunk } from 'store/currentProduct/ProductInfo';
import { useOrderData } from 'store/order';
import { setGeneralInternalSettings } from 'store/generalInternalSettingsState';
import getAddressName, {
  defaultCity,
  defaultPostalCode,
  getPostalCode,
} from 'helpers/getAddressNameByCoords';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import routes from 'constants/routes';
import { useCurrentPath } from 'utils/useCurrentPath';
import accountTabs from 'constants/accountTabs';
import { getAccountTabRoute } from 'helpers/routeHelper';

const accountWishlistRoute = getAccountTabRoute(accountTabs.WISHLIST);

const {
  DASHBOARD,
  PRODUCT,
  CART,
  PRODUCTS,
  BRAND,
  BRANDS,
  BRAND_CATEGORIES_LIST,
  PRODUCT_BY_CATEGORIES_LIST,
  BRAND_ACTUAL_OFFERS_LIST,
  BRAND_RANDOM_OFFERS_LIST,
} = routes;

const initialLocationState = {
  city: '',
  address: '',
  latitude: '',
  longitude: '',
  postalCode: '',
};

interface Location {
  city: string;
  address: string;
  latitude: string | number;
  longitude: string | number;
  postalCode: string;
}

export const defaultLocation = {
  address: defaultCity,
  city: defaultCity,
  latitude: MOSCOW_LAT,
  longitude: MOSCOW_LNG,
  postalCode: defaultPostalCode,
};

const { PERSONAL_INFO } = accountTabs;

export const accountPersonalInfoRoute = getAccountTabRoute(PERSONAL_INFO);

export const getSelectedCoordinates = () => {
  try {
    const selectedCoordinates = sessionStorage.getItem('GPS') || '[]';
    const [latitude = '', longitude = ''] = JSON.parse(selectedCoordinates);
    return [latitude, longitude];
  } catch (error) {
    return ['', ''];
  }
};

export const useRefreshDataFromLocation = () => {
  const [location, setLocation] = useState<Location>(initialLocationState);
  const [saveNewLocation, setSaveNewLocation] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const enableDurationPostalCode = useFlag('enable_duration_postal_code');

  const {
    lat: pickUpLat,
    lng: pickUpLng,
    mainAddress: { lat: mainAddressLat, lng: mainAddressLng } = {},
  } = useOrderData();
  const { count } = useShoppingCart();

  const isLoggedIn = authStorage.get().accessToken;

  const selectedCity = sessionStorage.getItem('city') || '';
  const selectedPostalCode = sessionStorage.getItem('postalCode') || '';
  const [latitude, longitude] = getSelectedCoordinates();

  const selectedLocation = {
    city: selectedCity,
    address: selectedCity,
    latitude,
    longitude,
    postalCode: selectedPostalCode,
  };

  const currentPath = useCurrentPath([
    { path: PRODUCT },
    { path: CART },
    { path: DASHBOARD },
    { path: PRODUCTS },
    { path: BRAND },
    { path: BRANDS },
    { path: BRAND_CATEGORIES_LIST },
    { path: PRODUCT_BY_CATEGORIES_LIST },
    { path: BRAND_ACTUAL_OFFERS_LIST },
    { path: BRAND_RANDOM_OFFERS_LIST },
    { path: accountWishlistRoute },
  ]);

  useEffect(() => {
    if (!saveNewLocation || (pickUpLat && pickUpLng) || (mainAddressLat && mainAddressLng)) return;
    const { latitude, longitude, postalCode } = location;
    const { path, params } = currentPath;
    if (latitude && longitude) {
      const lat = latitude.toString();
      const lng = longitude.toString();

      if (path === CART) {
        if (isLoggedIn && count > 0) {
          dispatch(getShoppingCartThunk({ lat, lng, postalCode, enableDurationPostalCode }));
        } else {
          let cartProducts: any = localStorage.getItem('cartProducts');
          const products = [];

          if (cartProducts) {
            cartProducts = JSON.parse(cartProducts);

            cartProducts = Object.values(cartProducts) || [];
            cartProducts.forEach(data => {
              data.forEach(({ id, count, isActive }) => {
                products.push({
                  id,
                  count,
                  isActive,
                });
              });
            });
          }

          if (products.length) {
            dispatch(
              getShoppingCartDurationsForLoggedOutUserThunk({
                lat: latitude.toString(),
                lng: longitude.toString(),
                products,
                showLoading: true,
                enableDurationPostalCode,
              })
            );
          }
        }
      } else if (path === PRODUCT && params.id && !enableDurationPostalCode) {
        const { id } = params;
        dispatch(getProductDurationThunk({ id, lat, lng }));
      } else if (
        [
          DASHBOARD,
          PRODUCT,
          PRODUCTS,
          BRAND,
          BRANDS,
          BRAND_CATEGORIES_LIST,
          PRODUCT_BY_CATEGORIES_LIST,
          BRAND_ACTUAL_OFFERS_LIST,
          BRAND_RANDOM_OFFERS_LIST,
          accountWishlistRoute,
        ].includes(path) &&
        enableDurationPostalCode
      ) {
        dispatch(setGeneralInternalSettings({ postalCode }));
      }
    }
  }, [location, enableDurationPostalCode, saveNewLocation]);

  useEffect(() => {
    return () => {
      setCurrentLocation({ ...location, address: '' });
      resetSaveNewLocation();
    };
  }, [saveNewLocation]);

  useEffect(() => {
    if (selectedCity) {
      setCurrentLocation(selectedLocation);
    } else if (navigator.geolocation && !selectedCity) {
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          const { latitude, longitude } = coords;
          const { city, address, countryCode } = await getAddressName(
            [latitude, longitude],
            'city'
          );
          const postalCode = enableDurationPostalCode
            ? await getPostalCode([latitude, longitude])
            : '';

          const currentLocation = {
            latitude,
            longitude,
            postalCode,
            address,
            city,
          };
          if (countryCode !== 'RU') {
            setDefaultLocation();
          } else {
            setCurrentLocationInSessionStorage(currentLocation);
            setLocation(currentLocation);
          }
        },
        () => setDefaultLocation()
      );
    } else {
      setDefaultLocation();
    }
  }, [enableDurationPostalCode]);

  const saveCurrentLocation = (defaultLocation?: Location) => {
    setSaveNewLocation(true);
    setCurrentLocationInSessionStorage(defaultLocation || location);
  };

  const setCurrentLocationInSessionStorage = (location: Location) => {
    const { latitude, longitude, city, postalCode } = location;
    sessionStorage.setItem('GPS', JSON.stringify([latitude, longitude]));
    sessionStorage.setItem('city', city);
    sessionStorage.setItem('postalCode', postalCode);
  };

  const setCurrentLocation = (location: Location) => {
    setLocation(location);
  };

  const resetSaveNewLocation = () => {
    setSaveNewLocation(false);
  };

  const setDefaultLocation = () => {
    setLocation(defaultLocation);
    setCurrentLocationInSessionStorage(defaultLocation);
  };

  return {
    location,
    selectedLocation,
    saveCurrentLocation,
    setCurrentLocation,
    enableDurationPostalCode,
  };
};
