export const errorMessages = {
  ERROR_REQUIRED_MESSAGE: 'Обязательно к заполнению',
  ERROR_NUMBER_MESSAGE: 'Пожалуйста укажите номер',
  ERROR_NUMBER_MESSAGE_STARTS_WITH_0: 'Номер не должен начинаться с цифры 0',
  ERROR_NOT_RUSSIAN: 'Пожалуйста заполните на русском языке',
  ERROR_INVALID_EMAIL: 'Недействительный адрес электронной почты',
  ERRROR_INVALID_BANK_NAME: 'Название банка не должно содержать цифр',
  ERRROR_INVALID_ADDRESS: 'Неправильный юридический адрес',
};

export const NUMBER_REGEX = /^[0-9]*\d$/;
export const RUSSIAN_REGEX_CITY = /[а-яА-ЯЁё.-]$/;
export const RUSSIAN_REGEX_ADDRESS = /[а-яА-ЯЁё,().-]$/;
export const BANK_NAME_REGEX = /^[A-Z a-zа-я А-ЯЁё]*$/;
export const ADDRESS_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Zа-я А-ЯЁё0-9]+$/;
export const RUSSIAN_ADDRESS_WITH_NUMBER = /^[А-Яа-яёЁ .0-9_,!()-=]+$/;
export const NUMBER_REGEX_SPACES = /^[0-9 -=_.,]+$/;
