import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Link, Skeleton } from '@mui/material';

import { ContactGroupInformationType } from 'store/ContactInformation/type';
import { useContactInformationData } from 'store/ContactInformation/ContactInformation';
import handleImageError from 'helpers/handleImageError';
import { footerLinks } from 'sections/Footer/constants';
import FooterAccordion from 'sections/Footer/MobileFooterSection/FooterAccordion/FooterAccordion';
import ROUTES from 'constants/routes';

import PaymentOptionsImage from 'assets/icons/payment-options.svg';
import MaxMarketLogo from 'assets/icons/maxmarket-header-logo.svg';
import defaultImg from 'assets/icons/defaultImg.svg';

import './style.scss';

const MobileFooterSection = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { DASHBOARD } = ROUTES;
  const { forBuyers, forPartners, newsCompanies }: any = footerLinks;
  const { data, contactInfoStatus } = useContactInformationData();

  const { socialMediaData } = data as ContactGroupInformationType;

  return (
    <div className={pathname === '/catalog' ? 'footer_none' : 'mobile_footer_section_wrap'}>
      <div className="logo_wrap">
        <img
          className="cursor-pointer"
          src={MaxMarketLogo}
          alt="MaxMarket Logo"
          width="160px"
          onClick={() => navigate(DASHBOARD)}
        />
      </div>
      <FooterAccordion name="Покупателям" data={forBuyers} />
      {/* <FooterAccordion name="Магазинам" data={forStores} /> */}
      <FooterAccordion name="Партнерам" data={forPartners} />
      <FooterAccordion name="Новости" data={newsCompanies} />
      <FooterAccordion
        name="Наши контакты"
        type="contract"
        data={data}
        contactInfoStatus={contactInfoStatus}
      />

      <div className="hr"></div>
      <div className="payment_img_wrap">
        <img className="payment-img" src={PaymentOptionsImage} alt="payment options" />
      </div>
      <p className="footer-end">
        © 2016-{new Date().getFullYear()} Маркетплейс нового поколения “MAXMARKET”
      </p>
      <div className="vk_fb">
        {contactInfoStatus === 'loading' ? (
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <Skeleton
              animation="wave"
              height="60px"
              width="40px"
              sx={{
                backgroundColor: 'grey.150',
              }}
            />
            <Skeleton
              animation="wave"
              height="60px"
              width="40px"
              sx={{
                backgroundColor: 'grey.150',
              }}
            />
            <Skeleton
              animation="wave"
              height="60px"
              width="40px"
              sx={{
                backgroundColor: 'grey.150',
              }}
            />
            <Skeleton
              animation="wave"
              height="60px"
              width="40px"
              sx={{
                backgroundColor: 'grey.150',
              }}
            />
          </Box>
        ) : (
          socialMediaData?.map(item => {
            const { link, filePath, id } = item;
            return (
              <Link
                key={id}
                className="vk-fb"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={filePath || defaultImg}
                  alt="Instagram"
                  width="20px"
                  height="20px"
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    handleImageError(e, filePath, defaultImg);
                  }}
                />
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default MobileFooterSection;
