import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Typography, Stack } from '@mui/material';
import classNames from 'classnames';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import Fb from 'components/FB';
import OtpTimer from 'components/OtpTimer';
import OtpInput from 'components/OtpInput';

import ROUTES from 'constants/routes';

import { icons } from './constants';

import './styles.scss';

interface Props {
  text: string;
  phoneNumber: number | string;
  handleOTPVerification: (otp: string) => void;
  type?: number;
  orderWithoutLoggingIn?: boolean;
  showPrivacyPolicy: boolean;
}

const OTPVerificationBox = ({
  text,
  phoneNumber,
  handleOTPVerification,
  type,
  orderWithoutLoggingIn,
  showPrivacyPolicy = true,
}: Props): JSX.Element => {
  const {
    login: { loginErrorMessage },
    order: { otpErrorMessage },
    buyerRegistration: { buyerRegisterErrorMessage },
    sellerPreRegistration: { sellerRegisterErrorMessage },
  } = useAppSelector((state: RootState) => state);

  const [otp, setOtp] = useState<string>('');

  const { checkCircle } = icons;

  useEffect(() => {
    if (!orderWithoutLoggingIn && otp.length === 4) {
      handleOTPVerification(otp);
    }
  }, [otp, orderWithoutLoggingIn]);

  const onChange = (value: string) => {
    if (orderWithoutLoggingIn) {
      handleOTPVerification(value);
    }
    setOtp(value);
  };
  const errorMessage =
    loginErrorMessage || otpErrorMessage || buyerRegisterErrorMessage || sellerRegisterErrorMessage;

  return (
    <Fb
      justifyCenter
      alignCenter
      column
      className={classNames('otp-verification-box', {
        orderWithoutLoggingIn,
      })}
    >
      <Stack spacing="20px">
        <Typography className="text">
          <span>{text}</span>
          <OtpTimer
            orderWithoutLoggingIn={orderWithoutLoggingIn}
            phoneNumber={phoneNumber}
            type={type}
            onChangeOtp={onChange}
          />
        </Typography>
        <OtpInput errorMessage={errorMessage} value={otp} onChange={onChange} />
      </Stack>
      {!!showPrivacyPolicy && (
        <Fb alignStart sx={{ mt: 5 }}>
          <img src={checkCircle} alt="check-circle-icon" className="check-circle-icon" />
          <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>
            Согласен с условиями{' '}
            <Link className="link" to={ROUTES.PLACINGANORDER} target="_blank">
              <u>Правил пользования торговой площадкой и правилами возврата</u>
            </Link>
          </Typography>
        </Fb>
      )}
    </Fb>
  );
};

export default OTPVerificationBox;
