import { FC, MouseEventHandler } from 'react';

import AddToShoppingCartTooltip from 'components/AddToShoppingCartTooltip';
import Spinner from 'components/Spinner';

import './style.scss';

interface Props {
  label?: any;
  size?: 'small' | 'medium' | 'large' | 'big' | 'fullWidth';
  variant?: 'outlined' | 'filled';
  disabled?: boolean;
  tooltip?: boolean;
  loading?: boolean;
  tooltipTitle?: string;
  className?: string;
  showTooltipTitle?: boolean;
  onClick?: MouseEventHandler<Element>;
}

const CustomButton: FC<Props> = ({
  label,
  size,
  variant,
  onClick,
  disabled,
  tooltip,
  loading,
  tooltipTitle,
  className = '',
  showTooltipTitle = true,
}) => {
  return (
    <div className={`custom_btn_wrap ${className}`}>
      {tooltip || (variant && disabled) ? (
        <AddToShoppingCartTooltip
          disabled={true}
          tooltipTitle={tooltipTitle}
          showTooltipTitle={showTooltipTitle}
        >
          <span className={variant ? `disabled-btn-variant ${size}` : `disabled-btn  ${size}`}>
            {loading && <Spinner />} {label}
          </span>
        </AddToShoppingCartTooltip>
      ) : (
        <button
          disabled={disabled}
          onClick={onClick}
          className={disabled ? `disabled-btn ${size}` : `custom-button ${variant} ${size}`}
        >
          {loading && <Spinner />} {label}
        </button>
      )}
    </div>
  );
};

export default CustomButton;
