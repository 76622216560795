import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLoginData } from 'store/auth/login';
import { useOrderData } from 'store/order';
import Modal from 'components/Modal';
import CustomButton from 'components/Button';
import ROUTES from 'constants/routes';

import { accountPersonalInfoRoute } from './constants';

import congratulateIcon from 'assets/icons/congratulate.svg';

import './style.scss';

const { ACCOUNT_MOBILE } = ROUTES;

const BuyerCongratulateModal = () => {
  const { status, isDeletedUser } = useLoginData();

  const { shopppingCartCreatedStatus } = useOrderData();
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      (status === 'success' || shopppingCartCreatedStatus === 'success') &&
      isDeletedUser === true
    ) {
      setOpen(true);
    }
  }, [status, isDeletedUser, shopppingCartCreatedStatus]);

  const moveToAccount = (e: React.MouseEvent<HTMLElement>) => {
    const { innerWidth } = e.view as any;
    const path = innerWidth <= 1200 ? ACCOUNT_MOBILE : accountPersonalInfoRoute;
    navigate(path);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={closeModal} bodyClassName="congratulate-modal-wrap">
      <div className="content-wrap">
        <img src={congratulateIcon} alt="Поздравляю" />
        <p className="title">Рады видеть вас на нашем сайте!</p>
        <p className="description">
          Поздравляем, Вы успешно зарегистрированы. Можете заполнить еще немного данных о себе для
          более удобной работы с сайтом.
        </p>
        <CustomButton label="Личный кабинет" onClick={moveToAccount} size="medium" />
      </div>
    </Modal>
  );
};

export default BuyerCongratulateModal;
