import { createSlice, PayloadAction, createAsyncThunk, current, AnyAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { getShoppingCart, changeProductStatusThunk } from 'store/cart';
import deliveryMethods from 'constants/deliveryMethod';
import paymentMethods from 'constants/paymentMethod';
import paymentTypes from 'constants/paymentType';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import handleErrorMessage from 'helpers/handleErroreMessage';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import { create, edit } from 'utils/api';

import {
  OrderSliceInterface,
  OrderRequestData,
  CreateShoppingCartRequestData,
  SelectedDeliveryPoint,
} from './types';

const changeOrderStatus = async ({ orderId }: { orderId: string }) => {
  const response = await edit(`${REACT_APP_BUYER_URL}/orders/payment-status`, {
    billingOrderId: orderId,
  });
  return response;
};

export const changeOrderStatusThunk = createAsyncThunk(
  'order/changePaymentStatus',
  changeOrderStatus
);

const handleOrder = async (
  { data, enableDurationPostalCode }: { data: OrderRequestData; enableDurationPostalCode: boolean },
  { rejectWithValue }
) => {
  try {
    let url = REACT_APP_BUYER_URL;
    if (enableDurationPostalCode) {
      url = REACT_APP_BUYER_URL_V2;
    }
    return await create(`${url}/order`, data);
  } catch (err: any) {
    const { response: { data: { details: { message = '', code = 0 } = {} } = {} } = {} } = err;
    return rejectWithValue({ message, code });
  }
};

export const orderThunk = createAsyncThunk('create', handleOrder);

const createShoppingCart = async (
  data: CreateShoppingCartRequestData,
  { dispatch, rejectWithValue }: any
) => {
  try {
    const {
      callBack,
      enableDurationPostalCode,
      phoneNumber,
      products,
      otp,
      countryCode,
      restActiveProducts,
      buyNow,
      buyNowProductId,
    } = data;

    const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;

    const payloadData = {
      otp,
      phoneNumber,
      products,
      countryCode,
    } as {
      otp: number;
      phoneNumber: number;
      countryCode: string;
      products: {
        id: number;
        count: number;
        isActive: boolean;
      }[];
      postalCode?: string;
    };

    let url = REACT_APP_BUYER_URL;

    if (enableDurationPostalCode) {
      url = REACT_APP_BUYER_URL_V2;
      payloadData.postalCode = postalCode;
    }
    const response = await create(`${url}/users/shopping-carts`, payloadData);

    if (response) {
      localStorage.removeItem('cartProducts');

      Object.entries(response).forEach(([key, value]) => {
        localStorage.setItem(key, `${value}`);
      });
      localStorage.setItem('phoneNumber', `${phoneNumber}`);

      if (buyNow) {
        const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];

        const { data: cartData } = await getShoppingCart({
          lat,
          lng,
          enableDurationPostalCode,
          postalCode,
        });

        let activeItems = [];
        const data = [];
        Object.values(cartData).forEach((products: any) => {
          products.forEach(({ id, isActive, product: { id: productId } }) => {
            activeItems.push({ id, productId, isActive });
            if (restActiveProducts.includes(productId)) {
              data.push({
                id,
                isActive: true,
                productId,
              });
            } else {
              data.push({
                id,
                isActive,
                productId,
              });
            }
          });
        });

        if (activeItems.length) {
          if (buyNowProductId) {
            const index = activeItems.findIndex(
              ({ productId, isActive }) => productId === buyNowProductId && isActive
            );
            activeItems.splice(index, 1);
          }
          activeItems = activeItems.filter(({ isActive }) => isActive).map(({ id }) => id);
          if (activeItems.length) {
            const result = {
              isActive: false,
              shopingCartIds: activeItems,
            };
            dispatch(changeProductStatusThunk({ data: result, enableDurationPostalCode }));
          }
        }
        localStorage.setItem('buyNowProductId', String(buyNowProductId));
        localStorage.setItem('shoppingCartPreviousData', JSON.stringify(data));
      }
      callBack && callBack();
    }
    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const createShoppingCartThunk = createAsyncThunk('shopping-cart/create', createShoppingCart);

export const checkAddCards = () => {
  create(`${REACT_APP_BUYER_URL}/check-and-add-cards`);
};

const initialState: OrderSliceInterface = {
  deliveryMethod: deliveryMethods.pickUp,
  deliveryAddress: '',
  pickUpPointId: null,
  lat: '',
  lng: '',
  maxmoney: 0,
  paymentMethod: paymentMethods.online,
  paymentType: paymentTypes.creditCard,
  mainCard: {},
  selectedCity: '',
  selectedCityLatLng: [],
  mainAddress: {},
  mainReceiver: {},
  showCongratulationsModal: false,
  showFailureModal: false,
  shopppingCartCreated: false,
  orderId: undefined,
  withBoxberry: false,
  withCdek: false,
  payedVia: 'sberbank',
  showCheckShoppingCartWarning: false,
  checkShoppingCartText: '',
  orderStatus: 'idle',
  showOrderModalWithoutLogingIn: false,
  otpErrorMessage: null,
  saveCard: false,
  totalPrice: 0,
  totalPriceWithoutDiscount: 0,
  phoneNumber: 0,
  postalCode: '',
  showRequiredFieldsError: {
    mainReceiver: false,
    mainAddress: false,
    deliveryAddress: false,
  },
  selectedDeliveryPoint: {
    pointId: null,
    address: '',
    type: '',
    workSchedule: '',
    postalCode: '',
  },
  shopppingCartCreatedStatus: 'idle',
  courierDeliverySettingsId: 0,
  courierDeliverySettingsIdModal: 0,
  courierDeliveryDurationDate: 0,
};

const OrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setTotalPrice: (
      state: OrderSliceInterface,
      action: PayloadAction<{ totalPriceWithoutDiscount: number; totalPrice: number }>
    ) => {
      const { totalPriceWithoutDiscount = 0, totalPrice = 0 } = action.payload;
      state.totalPriceWithoutDiscount = totalPriceWithoutDiscount;
      state.totalPrice = totalPrice;
    },
    setCourierDeliveryId: (
      state: OrderSliceInterface,
      action: PayloadAction<{
        id: number;
        durationDate?: number;
      }>
    ) => {
      return {
        ...state,
        courierDeliverySettingsId: action.payload.id,
        courierDeliveryDurationDate: action.payload.durationDate,
      };
    },
    setCourierDeliveryIdModal: (
      state: OrderSliceInterface,
      action: PayloadAction<{
        id: number;
      }>
    ) => {
      return {
        ...state,
        courierDeliverySettingsIdModal: action.payload.id,
      };
    },
    setShowShoppingCartWarning: (state: OrderSliceInterface, action) => {
      const { checkShoppingCartText, showCheckShoppingCartWarning } = action.payload;
      return {
        ...state,
        showCheckShoppingCartWarning,
        checkShoppingCartText,
      };
    },
    setRequiredFieldsError: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      return {
        ...state,
        showRequiredFieldsError: action.payload,
      };
    },
    resetOtpErrorMessage: (state: OrderSliceInterface) => {
      const { otpErrorMessage } = initialState;
      return {
        ...state,
        otpErrorMessage,
      };
    },
    setShowOrderModalWithoutLogingIn: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      return {
        ...state,
        otpErrorMessage: null,
        showOrderModalWithoutLogingIn: action.payload,
      };
    },
    setSelectedDeliveryPoint: (
      state: OrderSliceInterface,
      action: PayloadAction<SelectedDeliveryPoint>
    ) => {
      state.selectedDeliveryPoint = action.payload;
    },
    changeOrderFields: (state: OrderSliceInterface, action: PayloadAction<any>) => {
      const { showRequiredFieldsError } = current(state);
      const {
        payload: { field, value },
      } = action;

      // here update address delivery status
      return {
        ...state,
        showRequiredFieldsError: showRequiredFieldsError[field]
          ? { ...showRequiredFieldsError, [field]: false }
          : showRequiredFieldsError,
        [field]: value,
      };
    },
    showCongratulationsModalAction: (state: OrderSliceInterface) => {
      state.showCongratulationsModal = true;
    },
    hideCongratulationsModal: (state: OrderSliceInterface) => {
      state.showCongratulationsModal = false;
    },
    hideFailureModal: (state: OrderSliceInterface) => {
      state.showFailureModal = false;
    },
    showFailureModalAction: (state: OrderSliceInterface) => {
      state.showFailureModal = true;
    },
    setSaveCard: (state: OrderSliceInterface, action: PayloadAction<boolean>) => {
      state.saveCard = action.payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createShoppingCartThunk.fulfilled.type, (state: OrderSliceInterface) => {
        return {
          ...state,
          shopppingCartCreatedStatus: 'success',
          shopppingCartCreated: true,
        };
      })
      .addCase(
        createShoppingCartThunk.rejected.type,
        (state: OrderSliceInterface, action: AnyAction) => {
          const otpErrorMessage = handleErrorMessage(action.payload);
          return {
            ...state,
            otpErrorMessage,
            shopppingCartCreatedStatus: 'failed',
            shopppingCartCreated: true,
          };
        }
      )
      .addCase(orderThunk.rejected.type, (state: OrderSliceInterface, action: AnyAction) => {
        const { code = null, message = '' } = action.payload;

        const result = {
          ...state,
          orderStatus: 'rejected',
        };
        if (code === 623) {
          result.showCheckShoppingCartWarning = true;
        }
        if (code == 800) {
          toast.error(message);
        }
        return result;
      })
      .addCase(orderThunk.pending.type, (state: OrderSliceInterface) => {
        return {
          ...state,
          orderStatus: 'loading',
        };
      })
      .addCase(orderThunk.fulfilled.type, (state: OrderSliceInterface, action: any) => {
        const {
          payload: { id, formUrl = '' },
        } = action;

        localStorage.removeItem('orderNotes');
        if (formUrl) {
          window.open(formUrl, '_self', 'noreferrer');
        }

        return {
          ...state,
          showCongratulationsModal: !formUrl,
          shopppingCartCreated: false,
          orderId: id,
          orderStatus: 'success',
        };
      });
  },
});

export const useOrderData = (): OrderSliceInterface =>
  useAppSelector((state: RootState) => state.order);

export default OrderSlice.reducer;
export const {
  setShowOrderModalWithoutLogingIn,
  changeOrderFields,
  hideCongratulationsModal,
  showCongratulationsModalAction,
  reset,
  hideFailureModal,
  setRequiredFieldsError,
  setShowShoppingCartWarning,
  showFailureModalAction,
  resetOtpErrorMessage,
  setSaveCard,
  setTotalPrice,
  setSelectedDeliveryPoint,
  setCourierDeliveryId,
  setCourierDeliveryIdModal,
} = OrderSlice.actions;
