import CookiesModal from 'components/CookiesModal';
import MaxMoneyInfoModal from 'components/MaxMoneyInfoModal';

const CookiesAndMaxMoneyModal = () => {
  return (
    <div>
      <CookiesModal />
      <MaxMoneyInfoModal />
    </div>
  );
};

export default CookiesAndMaxMoneyModal;
