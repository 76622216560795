import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { authStorage } from 'store/storage';
import ROUTES from 'constants/routes';

interface PropType {
  component: React.FC;
}

const LoginAndRegistrationRoute: FC<PropType> = ({ component: Component }) => {
  const { accessToken } = authStorage.get();

  if (accessToken) return <Navigate replace to={ROUTES.DASHBOARD} />;
  return <Component />;
};

export default LoginAndRegistrationRoute;
