import { createSlice, createAsyncThunk, AnyAction } from '@reduxjs/toolkit';

import { REACT_APP_SELLER_URL } from 'constants/config';
import { read } from 'utils/api';

import { WholesalerB2BOrdersState } from './types';

const getWholesalerB2BOrders = async (page: number) => {
  const response = await read(`${REACT_APP_SELLER_URL}/wholesaler-orders?page=${page}`);
  return response;
};

export const getWholesalerB2BOrdersThunk = createAsyncThunk(
  'WholesalerB2B/getOrders',
  getWholesalerB2BOrders
);

const initialState: WholesalerB2BOrdersState = {
  data: [],
  meta: {},
  status: 'idle',
};

const wholesalerB2BOrdersSlice = createSlice({
  name: 'wholesalerB2B/orders',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getWholesalerB2BOrdersThunk.fulfilled.type,
        (state: WholesalerB2BOrdersState, action: AnyAction) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            data,
            status: 'success',
            meta,
          };
        }
      )
      .addCase(getWholesalerB2BOrdersThunk.pending.type, (state: WholesalerB2BOrdersState) => {
        return {
          ...state,
          status: 'loading',
        };
      });
  },
});

export const { reset } = wholesalerB2BOrdersSlice.actions;

export default wholesalerB2BOrdersSlice.reducer;
