import { NavLink } from 'react-router-dom';
import { FC, useState } from 'react';

import { Collapse, Typography, Link, Box, Skeleton } from '@mui/material';
import { FooterLinkInterface, footerIcons } from 'sections/Footer/constants';
import classNames from 'classnames';

import ROUTES from 'constants/routes';
import PhoneIcon from 'assets/icons/phoneFooter.svg';
import arrow from 'assets/icons/arrow-account.svg';

interface Params {
  name: string;
  data?: any;
  type?: string;
  contactInfoStatus?: string;
}

const FooterAccordion: FC<Params> = ({ name, data, type, contactInfoStatus }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const { AddressMarkIcon, MailIcon } = footerIcons;

  const handleChangeAccordion = () => {
    setChecked(prev => !prev);
  };

  const { phoneData, emailData, adressData } = data;

  return (
    <>
      {type !== 'contract' ? (
        <>
          <div className="accordion_name" onClick={handleChangeAccordion}>
            <div className="name_footer"> {name}</div>
            <div className="accordion-header">
              <img
                width={16}
                className={classNames('cursor-pointer', { rotate: checked })}
                src={arrow}
              />
            </div>
          </div>
          <Collapse in={checked}>
            {data.map(({ link, label }: FooterLinkInterface, index) => (
              <NavLink to={link} className="footer_title" key={'footer-key-forBuyers-' + index}>
                {label}
              </NavLink>
            ))}
          </Collapse>
        </>
      ) : (
        <>
          {(emailData || phoneData || adressData) && (
            <div className="accordion_name" onClick={handleChangeAccordion}>
              <div className="name_footer"> {name}</div>
              <div className="accordion-header">
                <img
                  width={16}
                  className={classNames('cursor-pointer', { rotate: checked })}
                  src={arrow}
                />
              </div>
            </div>
          )}
          <Collapse in={checked}>
            {adressData && (
              <div className="contact">
                <img src={AddressMarkIcon} alt="phone" width={16} height={16} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {contactInfoStatus === 'loading' ? (
                    <Skeleton
                      animation="wave"
                      height="100%"
                      width="200px"
                      sx={{
                        backgroundColor: 'grey.150',
                        marginLeft: '10px',
                      }}
                    />
                  ) : (
                    adressData?.map(item => {
                      const { id, key, text } = item;
                      return (
                        <Typography
                          key={id}
                          sx={{ marginLeft: '7px' }}
                          fontSize={14}
                          color="#4F4F4F"
                        >
                          {key}: {text}
                        </Typography>
                      );
                    })
                  )}
                </Box>
              </div>
            )}
            {emailData && (
              <div className="contact_wrap">
                <img src={MailIcon} alt="phone" width={16} height={16} />
                <div>
                  {contactInfoStatus === 'loading' ? (
                    <>
                      <Skeleton
                        animation="wave"
                        height="100%"
                        width="200px"
                        sx={{
                          backgroundColor: 'grey.150',
                          marginLeft: '10px',
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        height="100%"
                        width="200px"
                        sx={{
                          backgroundColor: 'grey.150',
                          marginLeft: '10px',
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        height="100%"
                        width="200px"
                        sx={{
                          backgroundColor: 'grey.150',
                          marginLeft: '10px',
                        }}
                      />
                    </>
                  ) : (
                    emailData?.map(item => {
                      const { text, key, id } = item;
                      return (
                        <Link key={id} href={`mailto: ${text}`} underline="none">
                          <Typography
                            sx={{ py: 1, marginLeft: '7px' }}
                            fontSize={14}
                            color="#4F4F4F"
                          >
                            {key}: {text}
                          </Typography>
                        </Link>
                      );
                    })
                  )}
                </div>
              </div>
            )}
            {phoneData && (
              <div className="phone_wrap">
                <img src={PhoneIcon} alt="phone" width={16} height={16} />
                <div>
                  {contactInfoStatus === 'loading' ? (
                    <Skeleton
                      animation="wave"
                      height="100%"
                      width="200px"
                      sx={{
                        backgroundColor: 'grey.150',
                        marginLeft: '10px',
                      }}
                    />
                  ) : (
                    phoneData?.map(item => {
                      const { text, key } = item;
                      return (
                        <Link key={item?.id} href={`tel:${text}`} underline="none">
                          <Typography
                            sx={{ py: 2, marginLeft: '7px' }}
                            fontSize={14}
                            color="#4F4F4F"
                          >
                            {key}: {text}
                          </Typography>
                        </Link>
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </Collapse>
          <div className="accordion_name">
            <NavLink to={ROUTES.SECURITY} className="name_footer">
              Охрана труда
            </NavLink>
          </div>
          <div className="accordion_name">
            <NavLink to={ROUTES.REQUISITES} className="name_footer">
              Реквизиты
            </NavLink>
          </div>
        </>
      )}
    </>
  );
};

export default FooterAccordion;
