import { createSlice } from '@reduxjs/toolkit';
import { RouterRedirect } from './types';

const initialState: RouterRedirect = {
  redirectionUrl: '',
};

const routerRedirectSlice = createSlice({
  name: 'routerRedirect',
  initialState,
  reducers: {
    setRedirectionUrl: (state: RouterRedirect, action) => {
      const { payload: redirectionUrl } = action;
      return {
        ...state,
        redirectionUrl,
      };
    },
  },
});

export default routerRedirectSlice.reducer;
export const { setRedirectionUrl } = routerRedirectSlice.actions;
