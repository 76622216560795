import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { create } from 'utils/api';
import { Status } from 'store/types';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { setIsDeletedUser } from 'store/auth/login';

import { REACT_APP_BUYER_URL } from 'constants/config';
import handleErrorMessage from 'helpers/handleErroreMessage';

import { CheckPhoneParams, PreAuthRequestParams } from './types';

export interface PreLoginState {
  status: Status;
  preAuthSuccess: boolean;
  phoneIsValid: boolean;
  phoneNumber: string;
  errorMessage: null | string;
  errorCode: null | string | number;
}

const initialState: PreLoginState = {
  status: 'idle',
  preAuthSuccess: false,
  phoneIsValid: false,
  errorMessage: null,
  errorCode: null,
  phoneNumber: '0000000000',
};

const checkPhonePreLogin = async (body: CheckPhoneParams, { rejectWithValue, dispatch }: any) => {
  let isDeleted = false;

  if (body.isDeleted) {
    isDeleted = body.isDeleted;
    delete body.isDeleted;
  }

  try {
    const response = await create(`${REACT_APP_BUYER_URL}/pre-login?isDeleted=${isDeleted}`, body);
    if (isDeleted) {
      dispatch(setIsDeletedUser(isDeleted));
    }

    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const checkPhonePreLoginThunk = createAsyncThunk('preLogin/checkPhone', checkPhonePreLogin);

const preAuthLoggedOut = async (
  body: PreAuthRequestParams & CheckPhoneParams,
  { rejectWithValue, dispatch }: any
) => {
  try {
    let isDeleted = false;

    if (body.isDeleted) {
      isDeleted = body.isDeleted;
      delete body.isDeleted;
    }
    const response = await create(`${REACT_APP_BUYER_URL}/pre-auth?isDeleted=${isDeleted}`, {
      ...body,
    });

    if (isDeleted) {
      dispatch(setIsDeletedUser(isDeleted));
    }

    return response;
  } catch (error: any) {
    const { response: { data: { details: { code = 0 } = {} } = {} } = {} } = error;
    return rejectWithValue(code);
  }
};

export const preAuthLoggedOutThunk = createAsyncThunk('logout/pre-login', preAuthLoggedOut);

export const PreLoginSlice = createSlice({
  name: 'preLogin',
  initialState,
  reducers: {
    resetPreLogin: () => initialState,
    resetErrorCode: state => ({
      ...state,
      errorCode: null,
    }),
    resetPreAuthState: (state: PreLoginState) => {
      const { preAuthSuccess } = initialState;
      return {
        ...state,
        preAuthSuccess,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(preAuthLoggedOutThunk.fulfilled.type, (state: PreLoginState, action: any) => {
        const {
          meta: {
            arg: { phoneNumber },
          },
        } = action;

        return {
          ...state,
          preAuthSuccess: true,
          phoneNumber,
          errorCode: null,
        };
      })
      .addCase(preAuthLoggedOutThunk.rejected.type, (state: PreLoginState, action: AnyAction) => {
        const errorCode = action.payload;
        return {
          ...state,
          errorCode,
        };
      })
      .addCase(checkPhonePreLoginThunk.fulfilled.type, (state: PreLoginState) => {
        return {
          ...state,
          phoneIsValid: true,
          status: 'success',
          errorCode: null,
        };
      })
      .addCase(checkPhonePreLoginThunk.pending.type, (state: PreLoginState) => {
        return {
          ...state,
          status: 'loading',
          errorCode: null,
        };
      })
      .addCase(checkPhonePreLoginThunk.rejected.type, (state: PreLoginState, action: AnyAction) => {
        const errorCode = action.payload;
        const errorMessage = handleErrorMessage(errorCode);
        return {
          ...state,
          errorMessage,
          errorCode,
          status: 'failed',
        };
      });
  },
});

export const usePreLoginData = (): PreLoginState =>
  useAppSelector((state: RootState) => state.preLogin);

export default PreLoginSlice.reducer;
export const { resetPreAuthState, resetPreLogin, resetErrorCode } = PreLoginSlice.actions;
