import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useProductReviewsData } from 'store/currentProduct/ProductReviews';

import alertTriangleIcon from 'assets/icons/alert-triangle.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

const CreateProductReviewAlert = (): JSX.Element => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const { createStatus } = useProductReviewsData();

  useEffect(() => {
    if (createStatus === 'loading') {
      setOpen(false);
    }
    return () => {
      setOpen(false);
    };
  }, [pathname, createStatus]);

  useEffect(() => {
    if (createStatus === 'success') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [createStatus]);

  const closeAlert = () => {
    setOpen(false);
  };

  return (
    open && (
      <div className="alert-warning">
        <div className="alert-warning-info">
          <img src={alertTriangleIcon} />
          <div className="alert-body-wrap">
            <p className="alert-title">Предупреждение</p>
            <p className="alert-description">
              Ваш отзыв получен и будет виден после подтверждения администратором.
            </p>
          </div>
          <img onClick={closeAlert} className="cursor-pointer" src={DeleteAlertIcon} />
        </div>
      </div>
    )
  );
};

export default CreateProductReviewAlert;
