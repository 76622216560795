import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

const userSuspendedDialogSlice = createSlice({
  name: 'showUserSuspendedDialog',
  initialState: false,
  reducers: {
    showUserSuspendedDialog: () => {
      return true;
    },
    hideUserSuspendedDialog: () => {
      return false;
    },
  },
});

export const useUserSuspendedDialogState = () => {
  return useAppSelector((state: RootState) => state.buyer.showUserSuspendedDialog);
};

export default userSuspendedDialogSlice.reducer;
export const { showUserSuspendedDialog, hideUserSuspendedDialog } =
  userSuspendedDialogSlice.actions;
