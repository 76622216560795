import MobileFooterNavBar from '../MobileFooterNavBar/MobileFooterNavBar';
import MobileFooterSection from '../MobileFooterSection/MobileFooterSection';

const MobileFooter = () => {
  return (
    <>
      <MobileFooterSection />
      <MobileFooterNavBar />
    </>
  );
};

export default MobileFooter;
