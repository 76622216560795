import { AnyAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import bannerTypes from 'constants/bannerTypes';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

import { DashboardInterface, GetRandomProductsParams } from './types';

const getDashboardBanners = async () =>
  await read(`${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_REGULAR_BANNER}`);

const getDashboardCategories = async () =>
  await read(`${REACT_APP_BUYER_URL}/ordered-categories?order=popularity_order:ASC&limit=6`);

const getPopularCategoriesBanners = async () =>
  await read(
    `${REACT_APP_BUYER_URL}/banners-by-type/${bannerTypes.TYPE_ADVERTISEMENT_BANNER}?limit=2`
  );

export const getPopularCategoriesBannersThunk = createAsyncThunk(
  'popular-categories-banners',
  getPopularCategoriesBanners
);

export const getDashboardCategoriesData = createAsyncThunk(
  'dashboardCategory/list',
  getDashboardCategories
);

export const getDashboardBannersThunk = createAsyncThunk('banners', getDashboardBanners);

export const getBestCashbackProducts = async ({
  enableDurationPostalCode,
  postalCode,
}: {
  enableDurationPostalCode: boolean;
  postalCode: string;
}) => {
  let url = REACT_APP_BUYER_URL;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;
  const params = {
    limit: 5,
    order: 'cashback_maxmoney:DESC',
  } as { limit: number; order: string; postalCode?: string };

  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = selectedPostalCode;
  }
  return await read(`${url}/products`, { params });
};

export const getBestCashbackProductsThunk = createAsyncThunk(
  'bestCashback/get',
  getBestCashbackProducts
);

export const getRandomProducts = async ({
  limit = 16,
  excludedIds,
  enableDurationPostalCode,
  postalCode,
}: GetRandomProductsParams) => {
  let url = REACT_APP_BUYER_URL;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;
  const params = {
    limit,
    excludedIds: undefined,
    postalCode: undefined,
  };
  if (excludedIds) {
    params.excludedIds = JSON.stringify(excludedIds);
  }
  if (enableDurationPostalCode) {
    url = REACT_APP_BUYER_URL_V2;
    params.postalCode = selectedPostalCode;
  }
  return await read(`${url}/products/random`, { params });
};

export const getRandomProductsThunk = createAsyncThunk('randomProducts/get', getRandomProducts);

const initialState: DashboardInterface = {
  pageLoading: false,
  banners: {
    data: [],
    meta: {},
    status: 'idle',
  },
  bestCashback: {
    data: [],
    meta: {},
    status: 'idle',
  },
  popularCategoriesBanners: {
    data: [],
    meta: {},
    status: 'idle',
  },
  randomProducts: {
    data: [],
    meta: {},
    status: 'idle',
  },
  actualOffers: {
    data: [],
    meta: {},
  },
  categories: {
    data: [],
    meta: {},
    status: 'idle',
  },
};

const DashboardSlice = createSlice({
  name: 'dashboard',
  reducers: {
    removeFromWishlistInBestCashback: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.bestCashback.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        bestCashback: {
          ...state.bestCashback,
          data,
        },
      };
    },
    changeWishlistProductIdInBestCashback: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.bestCashback.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        bestCashback: {
          ...state.bestCashback,
          data,
        },
      };
    },
    removeFromWishlistInRandomProducts: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { wishlistProductId },
      } = action;

      const data = currentState.randomProducts.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));
      return {
        ...state,
        randomProducts: {
          ...state.randomProducts,
          data,
        },
      };
    },
    removeFromWishlistInActualOffers: (state: DashboardInterface, action) => {
      const {
        payload: { wishlistProductId },
      } = action;

      const data = state.actualOffers.data.map(elm => ({
        ...elm,
        wishlistProductId:
          elm.wishlistProductId === wishlistProductId ? null : elm.wishlistProductId,
      }));

      return {
        ...state,
        actualOffers: {
          ...state.actualOffers,
          data,
        },
      };
    },
    changWishlistProductIdInActualOffers: (state: DashboardInterface, action) => {
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = state.actualOffers.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        actualOffers: {
          ...state.actualOffers,
          data,
        },
      };
    },
    changWishlistProductIdInRandomProducts: (state: DashboardInterface, action) => {
      const currentState = current(state);
      const {
        payload: { productId, wishlistProductId },
      } = action;

      const data = currentState.randomProducts.data.map(elm => ({
        ...elm,
        wishlistProductId: elm.id === productId ? wishlistProductId : elm.wishlistProductId,
      }));
      return {
        ...state,
        randomProducts: {
          ...state.randomProducts,
          data,
        },
      };
    },
    resetDashboardState: () => {
      return { ...initialState, pageLoading: true };
    },
    setActualOffers: (state: DashboardInterface, action) => {
      return {
        ...state,
        actualOffers: action.payload,
      };
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(
        getDashboardBannersThunk.fulfilled.type,
        (state: DashboardInterface, action: any) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            banners: {
              data,
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(getDashboardBannersThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          banners: {
            ...state.banners,
            status: 'loading',
          },
        };
      })
      .addCase(getDashboardBannersThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          banners: {
            ...state.banners,
            status: 'failed',
          },
        };
      })
      .addCase(getRandomProductsThunk.fulfilled.type, (state: DashboardInterface, action: any) => {
        const { data, meta } = action.payload;
        return {
          ...state,
          randomProducts: {
            data,
            meta,
            status: 'success',
          },
        };
      })
      .addCase(getRandomProductsThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          randomProducts: {
            ...state.randomProducts,
            status: 'loading',
          },
        };
      })
      .addCase(getRandomProductsThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          randomProducts: {
            ...state.randomProducts,
            status: 'failed',
          },
        };
      })
      .addCase(
        getBestCashbackProductsThunk.fulfilled.type,
        (state: DashboardInterface, action: AnyAction) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            bestCashback: {
              ...state.bestCashback,
              data,
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(getBestCashbackProductsThunk.pending.type, (state: DashboardInterface) => {
        return {
          ...state,
          bestCashback: {
            ...state.bestCashback,
            status: 'loading',
          },
        };
      })
      .addCase(getBestCashbackProductsThunk.rejected.type, (state: DashboardInterface) => {
        return {
          ...state,
          bestCashback: {
            ...state.bestCashback,
            status: 'failed',
          },
        };
      })
      .addCase(
        getDashboardCategoriesData.fulfilled.type,
        (state: DashboardInterface, action: AnyAction) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            categories: {
              ...state.categories,
              data,
              meta,
            },
          };
        }
      )
      .addCase(
        getPopularCategoriesBannersThunk.fulfilled.type,
        (state: DashboardInterface, action: any) => {
          const { data, meta } = action.payload;
          return {
            ...state,
            popularCategoriesBanners: {
              ...state.popularCategoriesBanners,
              data,
              meta,
            },
          };
        }
      );
  },
});

export const useDashboardData = (): DashboardInterface =>
  useAppSelector((state: RootState) => state.dashboard);

export default DashboardSlice.reducer;
export const {
  setActualOffers,
  resetDashboardState,
  changeWishlistProductIdInBestCashback,
  removeFromWishlistInRandomProducts,
  changWishlistProductIdInRandomProducts,
  removeFromWishlistInBestCashback,
  changWishlistProductIdInActualOffers,
  removeFromWishlistInActualOffers,
} = DashboardSlice.actions;
