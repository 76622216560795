import { useMemo } from 'react';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { create, read } from 'utils/api';
import { RootState } from 'store/index';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { REACT_APP_SELLER_URL, REACT_APP_FNS_URL, REACT_APP_FNS_KEY } from 'constants/config';

import { SellersFnsDataType, SellerSignUpParamsType } from './types';

type RegisterUserDataType = {
  data?: SellerSignUpParamsType;
};
type RegisterUserDataTypeActionsType = {
  set: (userDta) => void;
};

type Fns = {
  status: string;
  data: {
    postalAddress: string;
    psrn: number | string;
    trr: number | string;
    organizationForm: string;
    organizationName: string;
    legalAddress: string;
  };
};

type FnsRequestParams = {
  value: string;
};

const initialFnsData = {
  postalAddress: '',
  psrn: '',
  trr: '',
  organizationForm: '',
  organizationName: '',
  legalAddress: '',
};

export const requestRegister = (params: unknown) =>
  create(`${REACT_APP_SELLER_URL}/register`, params);

export const requestFnsData = (params: FnsRequestParams) =>
  read(`${REACT_APP_FNS_URL}egr?req=${params.value}&${REACT_APP_FNS_KEY}`);

export const getFnsDataThunk = createAsyncThunk('register/getFnsData', requestFnsData);

const registerUserDataSlice = createSlice({
  name: 'sellerRegistration',
  initialState: {
    data: {},
    status: '',
    fns: <Fns>{
      status: '',
      data: initialFnsData,
    },
  },
  reducers: {
    set: (state, action: PayloadAction<SellerSignUpParamsType>) => {
      state.data = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getFnsDataThunk.pending.type, state => {
        state.fns.status = 'loading';
        state.fns.data = initialFnsData;
      })
      .addCase(
        getFnsDataThunk.fulfilled.type,
        (state, action: PayloadAction<SellersFnsDataType>) => {
          const fnsItems = action.payload.items;
          if (fnsItems.length > 0) {
            state.fns.status = 'success';
            for (const key in fnsItems[0]) {
              if (key === 'ЮЛ') {
                state.fns.data.organizationForm = fnsItems[0][key].ОКОПФ;
                state.fns.data.organizationName = fnsItems[0][key].НаимСокрЮЛ;
                state.fns.data.psrn = fnsItems[0][key].ОГРН;
                state.fns.data.trr = fnsItems[0][key].КПП;
              }
              if (key === 'ИП') {
                state.fns.data.organizationName = `${key} ${fnsItems[0][key].ФИОПолн}`;
                state.fns.data.organizationForm = fnsItems[0][key].ВидИП;
                state.fns.data.psrn = fnsItems[0][key].ОГРНИП;
              }
              state.fns.data.postalAddress = fnsItems[0][key].Адрес.АдресПолн;
              state.fns.data.legalAddress = fnsItems[0][key].Адрес.АдресПолн;
            }
          } else {
            state.fns.status = 'failed';
            state.fns.data = initialFnsData;
          }
        }
      )
      .addCase(getFnsDataThunk.rejected.type, state => {
        state.fns.status = 'failed';
        state.fns.data = initialFnsData;
      });
  },
});

export const useRegisterUserData = (): [RegisterUserDataType, RegisterUserDataTypeActionsType] => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.sellerRegistration);

  const actions = useMemo<RegisterUserDataTypeActionsType>(
    () => ({
      set: (userData: SellerSignUpParamsType) => {
        dispatch(registerUserDataSlice.actions.set(userData));
      },
    }),
    []
  );

  return [userData, actions];
};

export default registerUserDataSlice.reducer;
