import { changeProductStatusThunk, getShoppingCartThunk } from 'store/cart';
import { MOSCOW_LAT, MOSCOW_LNG } from 'constants/defaults';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';

import { dispatch } from 'store';

interface Params {
  getShoppingCart: boolean;
  enableDurationPostalCode: boolean;
  refreshShoppingCartFromRemoveProduct?: boolean;
  refreshShoppingCartFromChangeProductStatus?: boolean;
}

export const clearbuyNowData = () => {
  localStorage.removeItem('shoppingCartPreviousData');
  localStorage.removeItem('buyNowShoppingCartId');
  localStorage.removeItem('buyNowProductId');
};

export const returnShoppingCartPreviousItemsState = async ({
  getShoppingCart = true,
  enableDurationPostalCode,
  // refreshShoppingCartFromRemoveProduct = true,
  refreshShoppingCartFromChangeProductStatus = true,
}: Params) => {
  const preiouesCartItems = localStorage.getItem('shoppingCartPreviousData');
  const buyNowProductId = localStorage.getItem('buyNowProductId');

  const activeItems = [];
  let changeProductStatusRequest = null;
  // let removeFromShoppingCartRequest = null;
  const promises = [];
  if (preiouesCartItems) {
    try {
      const cartItems = JSON.parse(preiouesCartItems);
      cartItems.forEach(({ id, isActive, productId }) => {
        if (isActive || +buyNowProductId === productId) {
          activeItems.push(id);
        }
      });
    } catch (error) {
      return;
    }

    if (activeItems.length) {
      const data = {
        isActive: true,
        shopingCartIds: activeItems,
      };
      changeProductStatusRequest = dispatch(
        changeProductStatusThunk({
          data,
          enableDurationPostalCode,
          getShoppingCart: refreshShoppingCartFromChangeProductStatus,
        })
      );
      if (getShoppingCart) {
        const [lat, lng] = JSON.parse(sessionStorage.getItem('GPS')) || [MOSCOW_LAT, MOSCOW_LNG];
        const postalCode = sessionStorage.getItem('postalCode') || defaultPostalCode;
        dispatch(getShoppingCartThunk({ lat, lng, postalCode, enableDurationPostalCode }));
      }
    }
    // const buyNowShoppingCartId = +localStorage.getItem('buyNowShoppingCartId');
    // if (buyNowShoppingCartId) {
    //   removeFromShoppingCartRequest = dispatch(
    //     removeFromShoppingCartThunk({
    //       shopingCartIds: [buyNowShoppingCartId],
    //       enableDurationPostalCode,
    //       getShoppingCart: refreshShoppingCartFromRemoveProduct,
    //     })
    //   );
    // }
  }

  if (changeProductStatusRequest) promises.push(changeProductStatusRequest);
  // if (removeFromShoppingCartRequest) promises.push(removeFromShoppingCartRequest);

  return Promise.all(promises).then(() => clearbuyNowData());
};
