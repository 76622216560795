export const authStorage = {
  set: data => {
    const { userType, tokenType, accessToken, refreshToken } = data;
    userType && localStorage.setItem('userType', userType);
    tokenType && localStorage.setItem('tokenType', tokenType);
    accessToken && localStorage.setItem('accessToken', accessToken);
    refreshToken && localStorage.setItem('refreshToken', refreshToken);
  },
  get: () => ({
    userType: localStorage.getItem('userType'),
    tokenType: localStorage.getItem('tokenType'),
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
  }),
  remove: () => {
    localStorage.removeItem('userType');
    localStorage.removeItem('tokenType');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  },
  clear: () => {
    localStorage.clear();
  },
};
