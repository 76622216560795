import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { CONTACT_TYPE_KEY } from './constant';

import { ContactInformationInterfaceType, ContactInformationInterface } from './type';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { read } from 'utils/api';

const getContactInformation = async () => {
  return await read(`${REACT_APP_BUYER_URL}/contact-info`);
};

export const getContactInformationThunk = createAsyncThunk(
  'contactInformation/get',
  getContactInformation
);

const initialState: ContactInformationInterface = {
  data: [],
  contactInfoStatus: 'idle',
};

const contactInformation = createSlice({
  name: 'contactInformation',
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        getContactInformationThunk.fulfilled.type,
        (state: ContactInformationInterface, action: PayloadAction<any>) => {
          const { data } = action.payload;
          const groupData = data?.reduce((acc: any, item: ContactInformationInterfaceType) => {
            const typeKey = CONTACT_TYPE_KEY[item?.type];
            if (typeKey) {
              acc[typeKey] = acc[typeKey] || [];
              acc[typeKey].push(item);
            }
            return acc;
          }, {});
          return {
            ...state,
            data: groupData,
            contactInfoStatus: 'success',
          };
        }
      )
      .addCase(getContactInformationThunk.pending.type, (state: ContactInformationInterface) => {
        return {
          ...state,
          contactInfoStatus: 'loading',
        };
      })
      .addCase(getContactInformationThunk.rejected.type, (state: ContactInformationInterface) => {
        return {
          ...state,
          contactInfoStatus: 'failed',
        };
      });
  },
  initialState,
});

export const useContactInformationData = (): any => {
  const reducerState = useAppSelector((state: RootState) => state.contactInformation);
  return reducerState;
};

export default contactInformation.reducer;
