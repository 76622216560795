import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_BUYER_URL } from 'constants/config';
import { create } from 'utils/api';
import { RootState } from 'store';
import { Status } from 'store/types';
import { authStorage } from 'store/storage';
import { useAppSelector } from 'store/hooks';
import { promocodeErrorMessage } from 'components/MaxMoney/constants';

import { CheckPromocodeRequestParams, PromocodeInterface } from './types';

const checkPromocode = async ({
  promocode,
  productIds,
  paymentMethod,
}: CheckPromocodeRequestParams) => {
  const response = create(`${REACT_APP_BUYER_URL}/promo-codes/${promocode}/check`, {
    productIds,
    paymentMethod,
  });
  return response;
};

export const checkPromocodeThunk = createAsyncThunk('promocode/check', checkPromocode);

const initialState: PromocodeInterface = {
  promocode: {
    status: 'idle',
    amount: 0,
    errorMessage: '',
    promocode: '',
    withPickUpDiscount: true,
  },
};

const PromocodesSlice = createSlice({
  initialState,
  name: 'promocodes',
  reducers: {
    resetPromocodeStatus: (state: PromocodeInterface) => {
      return {
        ...state,
        promocode: {
          ...state.promocode,
          status: initialState.promocode.status,
          errorMessage: initialState.promocode.errorMessage,
        },
      };
    },
    reset: () => initialState,
    setPromocodeStatus: (
      state: PromocodeInterface,
      action: PayloadAction<{ status: Status; errorMessage: string }>
    ) => {
      return {
        ...state,
        promocode: {
          ...state.promocode,
          amount: 0,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(checkPromocodeThunk.pending.type, (state: PromocodeInterface) => {
        return {
          ...state,
          promocode: {
            ...state.promocode,
            status: 'loading',
          },
        };
      })
      .addCase(checkPromocodeThunk.fulfilled.type, (state: PromocodeInterface, action: any) => {
        const {
          payload: { amount, withPickUpDiscount, promoCodeType },
          meta: {
            arg: { promocode },
          },
        } = action;
        const isLoggedIn = !!authStorage.get().accessToken;
        if (!isLoggedIn) {
          localStorage.setItem('promocode', promocode);
        }

        return {
          ...state,
          promocode: {
            ...state.promocode,
            amount: amount || 0,
            withPickUpDiscount,
            status: 'success',
            errorMessage: '',
            promocode,
            promoCodeType,
          },
        };
      })
      .addCase(checkPromocodeThunk.rejected.type, (state: PromocodeInterface) => {
        localStorage.removeItem('promocode');
        return {
          ...state,
          promocode: {
            ...state.promocode,
            amount: 0,
            status: 'failed',
            errorMessage: promocodeErrorMessage,
          },
        };
      });
  },
});

export const usePromocodeData = (): PromocodeInterface => {
  const reducerState = useAppSelector((state: RootState) => state.promocodes);
  return reducerState;
};

export default PromocodesSlice.reducer;
export const { reset, resetPromocodeStatus, setPromocodeStatus } = PromocodesSlice.actions;
