import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { REACT_APP_BUYER_URL } from 'constants/config';

export const CdekPointsApi = createApi({
  reducerPath: 'CdekPointsApiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_BUYER_URL}`,
  }),
  keepUnusedDataFor: 600000,
  endpoints: builder => ({
    getCdekPoints: builder.query({
      query: () => '/cdek-points',
    }),
  }),
});

export const { useGetCdekPointsQuery } = CdekPointsApi;
