type EventName =
  | 'categoryView'
  | 'productDetail'
  | 'removeFromCart'
  | 'checkout'
  | 'productClick'
  | 'addToCart'
  | 'ProductImpressions'
  | 'transactionPush'
  | 'addToWishList'
  | 'setCustomer';

export const recordAnalyticsEvent = (
  eventName: EventName,
  ecommerceData: any,
  clearDataLayerFlagEnabled: boolean,
  enableRetailRocketFlagEnabled: boolean,
  recordToGoogleAnalytics = true
) => {
  if (recordToGoogleAnalytics) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: eventName,
      ecommerce: ecommerceData,
    });

    if (clearDataLayerFlagEnabled) {
      window.dataLayer = [];
    }
  }

  if (enableRetailRocketFlagEnabled) {
    (window['rrApiOnReady'] = window['rrApiOnReady'] || []).push(function () {
      try {
        const rrApi = window['rrApi'];

        switch (eventName) {
          case 'setCustomer': {
            const {
              userId: customerId = '',
              phoneNumber: phone = '',
              email = '',
              fullName = '',
            } = ecommerceData ?? {};

            rrApi.setCustomer({
              customerId: customerId.toString(),
              email,
              phone,
              customData: {
                fullName,
              },
            });
            break;
          }
          case 'productClick': {
            const { id: productID = '' } = ecommerceData['click']['products'][0] ?? {};

            rrApi.view(productID);
            break;
          }
          case 'categoryView': {
            const { categoryID = '' } = ecommerceData;

            rrApi.categoryView(categoryID);
            break;
          }
          case 'addToCart': {
            const { id: productID = '', quantity } = ecommerceData['add']['products'][0] ?? {};

            rrApi.addToBasket(productID, quantity);
            break;
          }
          case 'addToWishList': {
            const { id: productID = '' } = ecommerceData['add']['products'][0] ?? {};

            rrApi.rrAddToFavorite(productID);
            break;
          }
          case 'transactionPush': {
            const { actionField: { id: transactionId = '' } = {}, products = [] } =
              ecommerceData['purchase'] ?? {};

            rrApi.order({
              transaction: transactionId,
              items: products.map(({ id, price, quantity: qnt }) => ({
                id,
                qnt,
                price,
              })),
            });
            break;
          }
        }
      } catch (e) {
        return;
      }
    });
  }
};
