import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { read } from 'utils/api';
import { REACT_APP_BUYER_URL, REACT_APP_BUYER_URL_V2 } from 'constants/config';
import ROUTES from 'constants/routes';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { setRedirectionUrl } from 'store/routerRedirect';
import { defaultPostalCode } from 'helpers/getAddressNameByCoords';
import formatFilterObject from 'helpers/formatFilterObject';

import { CategoryStateInterface, Category, BannerPageCategoriesRequestInterface } from './types';

export const getCategory = async (id: number, { dispatch }) => {
  try {
    return await read(`${REACT_APP_BUYER_URL}/categories/${id}`);
  } catch (err: any) {
    const { response: { data: { status = 0 } = {} } = {} } = err;
    status === 404 && dispatch(setRedirectionUrl(ROUTES.DASHBOARD));
  }
};

export const getCategoryThunk = createAsyncThunk('subCategoriesList', getCategory);

export const getCategoriesListForBannerPage = async ({
  bannerToken,
  page,
  enableDurationPostalCode,
  enableFiltrationV2,
  postalCode,
  filteringObject,
}: BannerPageCategoriesRequestInterface) => {
  let url = `${REACT_APP_BUYER_URL}/banner-pages/${bannerToken}/categories`;
  const selectedPostalCode =
    postalCode || sessionStorage.getItem('postalCode') || defaultPostalCode;

  let params: any = {
    page,
    postalCode: undefined,
  };

  if (enableDurationPostalCode || enableFiltrationV2) {
    url = `${REACT_APP_BUYER_URL_V2}/categories`;
    params.bannerToken = bannerToken;

    if (filteringObject && enableFiltrationV2) {
      const newFilters = formatFilterObject(filteringObject);

      params = {
        ...params,
        ...newFilters,
      };

      if (params.brandId) {
        params.brandIds = params.brandId;
        delete params.brandId;
      }

      if (params.categoryIds) {
        delete params.categoryIds;
      }
    }

    if (enableDurationPostalCode) {
      params.postalCode = selectedPostalCode;
    } else if (enableFiltrationV2) {
      params.withoutPostalCode = true;
    }
  }

  return await read(url, { params });
};

export const getAllCategoriesListForBannerPageThunk = createAsyncThunk(
  'list',
  getCategoriesListForBannerPage
);

const initialState = {
  categoriesList: {
    meta: {},
    data: [],
    status: 'idle',
  },
  subCategory: {
    data: null,
    status: 'idle',
  },
} as CategoryStateInterface;

const CategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getCategoryThunk.fulfilled.type,
        (state: CategoryStateInterface, action: PayloadAction<Category>) => {
          return {
            ...state,
            subCategory: {
              data: action.payload,
              status: 'success',
            },
          };
        }
      )
      .addCase(getCategoryThunk.pending.type, (state: CategoryStateInterface) => {
        return {
          ...state,
          subCategory: {
            ...state.subCategory,
            status: 'loading',
          },
        };
      })
      .addCase(
        getAllCategoriesListForBannerPageThunk.pending.type,
        (state: CategoryStateInterface) => {
          return {
            ...state,
            categoriesList: {
              ...state.categoriesList,
              status: 'loading',
            },
          };
        }
      )
      .addCase(
        getAllCategoriesListForBannerPageThunk.fulfilled.type,
        (state: CategoryStateInterface, action: any) => {
          const {
            payload: { data, meta },
            meta: { arg: { page = 1 } = {} } = {},
          } = action;

          return {
            ...state,
            categoriesList: {
              ...state.categoriesList,
              data: page === 1 ? data : [...state.categoriesList.data, ...data],
              meta,
              status: 'success',
            },
          };
        }
      )
      .addCase(
        getAllCategoriesListForBannerPageThunk.rejected.type,
        (state: CategoryStateInterface) => {
          return {
            ...state,
            categoriesListForBannerPage: {
              ...state.categoriesList,
              status: 'failed',
            },
          };
        }
      );
  },
});

export const useCategoryData = (): CategoryStateInterface =>
  useAppSelector((state: RootState) => state.categories);

export const { reset } = CategorySlice.actions;
export default CategorySlice.reducer;
