import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { AppDispatch } from 'store';
import ROUTES from 'constants/routes';
import { resetStatus, useShoppingCart } from 'store/cart';

import AlertIcon from 'assets/icons/alertIcon.svg';
import FailureIcon from 'assets/icons/failed.svg';
import ArrowAlertIcon from 'assets/icons/arrowAlert.svg';
import DeleteAlertIcon from 'assets/icons/deleteAlert.svg';

import './style.scss';

const { CART } = ROUTES;

const limitedCodeNumber = 739;

const AddProductInShoppingCardAlert = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [alert, setAlert] = useState<boolean>(false);

  const { addProductInShoppingCartStatus: status, limitedCode } = useShoppingCart();

  const closeAlert = () => {
    setAlert(false);
  };

  useEffect(() => {
    if (status === 'success' || limitedCode === limitedCodeNumber) {
      setAlert(true);
    } else {
      setAlert(false);
    }
    setTimeout(() => {
      setAlert(false);
      dispatch(resetStatus());
    }, 7000);
  }, [status, limitedCode]);

  const NavigateShopingCards = () => {
    navigate(CART, {
      state: {
        buyNow: false,
      },
    });
    setAlert(false);
  };

  return (
    alert && (
      <div className="alert">
        <div className="al-wrap">
          <div className="alert-info">
            <img
              src={limitedCode === limitedCodeNumber ? FailureIcon : AlertIcon}
              width={24}
              height={24}
            />
            {limitedCode === limitedCodeNumber ? (
              <Box sx={{ fontSize: '14px', marginLeft: '10px', color: '#676767' }}>
                Лимит товаров в корзине превышен
              </Box>
            ) : (
              <p>Товар добавлен в корзину</p>
            )}
          </div>
          <div className="btn-alert-wrap">
            <button onClick={NavigateShopingCards} className="btn-alert">
              В корзину <img className="arrow-btn-alert" src={ArrowAlertIcon} />
            </button>
            <div className="arrow-alert"></div>
            <img onClick={closeAlert} src={DeleteAlertIcon} />
          </div>
        </div>
      </div>
    )
  );
};

export default AddProductInShoppingCardAlert;
