import { createAsyncThunk, createSlice, PayloadAction, current, AnyAction } from '@reduxjs/toolkit';

import { RootState } from 'store/index';
import { useAppSelector } from 'store/hooks';
import { Status } from 'store/types';
import { REACT_APP_BUYER_URL } from 'constants/config';
import { read, create, edit, remove } from 'utils/api';
import { changeOrderFields } from 'store/order';

import { UserCreditCard, UserCreditCardParams } from './types';

interface UserCreditCardsState {
  data: Array<UserCreditCard>;
  status: Status;
  errorMessage: string;
  CreateStatus: Status;
}

export const getUserCreditCardsAPI = async (_, { dispatch }) => {
  const response = await read(`${REACT_APP_BUYER_URL}/credit-cards`);
  const { data } = response;
  const mainCard = data.find(({ isMain }) => isMain) || {};
  dispatch(
    changeOrderFields({
      field: 'mainCard',
      value: mainCard,
    })
  );
  return response;
};

export const createUserCreditCardAPI = async (
  { data, callBack }: { data: UserCreditCardParams; callBack: any },
  { dispatch, rejectWithValue }: any
) => {
  try {
    const response = await create(`${REACT_APP_BUYER_URL}/credit-cards`, { ...data });
    callBack && callBack();
    dispatch(getUserCreditCardData(response));
    return response;
  } catch (error: any) {
    const { response: { data: { details: { message = '' } = {} } = {} } = {} } = error;
    if (typeof message === 'string') return rejectWithValue(message);
  }
};

const getUserOneCreditCardAPI = async ({ id }: any, { dispatch }) => {
  const response = await read(`${REACT_APP_BUYER_URL}/credit-cards/${id}`);
  dispatch(
    changeOrderFields({
      field: 'mainCard',
      value: response,
    })
  );
  return response;
};

const setUserMainCreditCardAPI = async (id: number, { dispatch }) => {
  await edit(`${REACT_APP_BUYER_URL}/credit-cards/${id}/main`);
  dispatch(
    changeOrderFields({
      field: 'mainCard',
      value: { id },
    })
  );
};

const deleteCreditCardAPI = async (id: number, { dispatch, getState }) => {
  const {
    userCreditCards: { data },
  } = getState();

  const response = await remove(`${REACT_APP_BUYER_URL}/credit-cards/${id}`);
  if (data.length >= 2) {
    let setMainCreditCardId = null;
    data.forEach((e, i, array) => {
      if (e.id === id) {
        setMainCreditCardId = array[i + 1] ? array[i + 1].id : array[i - 1].id;
      }
    });
    dispatch(setUserMainCreditCard(setMainCreditCardId));
  } else {
    dispatch(
      changeOrderFields({
        field: 'mainCard',
        value: {},
      })
    );
  }
  return response;
};

export const setUserMainCreditCard = createAsyncThunk(
  'userCreditCards/setMain',
  setUserMainCreditCardAPI
);

const getUserCreditCardData = createAsyncThunk(
  'userCreditCards/OneCreditCard',
  getUserOneCreditCardAPI
);

export const getUserCreditCardsData = createAsyncThunk(
  'userCreditCards/list',
  getUserCreditCardsAPI
);

export const createUserCreditCard = createAsyncThunk(
  'userCreditCards/createUserCreditCard',
  createUserCreditCardAPI
);

export const deleteCreditCardThunk = createAsyncThunk(
  'userCreditCards/delete',
  deleteCreditCardAPI
);

const initialState = {
  data: [],
  status: 'idle',
  errorMessage: '',
  CreateStatus: 'idle',
} as UserCreditCardsState;

const userCreditCardSlice = createSlice({
  name: 'userCreditCards',
  initialState,
  reducers: {
    setErrorMessage(state: UserCreditCardsState, action) {
      const { payload = '' } = action as any;
      state.errorMessage = payload;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createUserCreditCard.fulfilled.type, (state: UserCreditCardsState) => {
        return {
          ...state,
          CreateStatus: 'success',
        };
      })
      .addCase(createUserCreditCard.pending.type, (state: UserCreditCardsState) => {
        return {
          ...state,
          CreateStatus: 'loading',
        };
      })
      .addCase(
        createUserCreditCard.rejected.type,
        (state: UserCreditCardsState, action: AnyAction) => {
          return {
            ...state,
            errorMessage: action.payload,
            CreateStatus: 'failed',
          };
        }
      )
      .addCase(getUserCreditCardsData.pending.type, (state: UserCreditCardsState) => {
        return {
          ...state,
          status: 'loading',
        };
      })
      .addCase(
        getUserCreditCardsData.fulfilled.type,
        (state: UserCreditCardsState, action: PayloadAction<UserCreditCardsState>) => {
          const { data } = action.payload;
          return {
            ...state,
            status: 'success',
            data,
          };
        }
      )
      .addCase(getUserCreditCardsData.rejected.type, (state: UserCreditCardsState) => {
        return {
          ...state,
          status: 'failed',
        };
      })
      .addCase(
        getUserCreditCardData.fulfilled.type,
        (state: UserCreditCardsState, action: PayloadAction<UserCreditCard>) => {
          const { payload } = action;
          const { data } = current(state);

          const userCreditCards = [...data];

          const card = userCreditCards.find(e => e.id === payload.id);

          if (card) {
            return state;
          }

          if (userCreditCards.length) {
            const mainIndex = userCreditCards.findIndex(item => item.isMain);
            userCreditCards.splice(mainIndex, 1, { ...userCreditCards[mainIndex], isMain: false });
          }

          return {
            ...state,
            data: [payload, ...userCreditCards],
          };
        }
      )
      .addCase(deleteCreditCardThunk.fulfilled.type, (state: UserCreditCardsState, action: any) => {
        const {
          meta: { arg: cardId },
        } = action;
        const { data } = current(state);

        const userCreditCards = data.filter(e => e.id !== cardId);

        return {
          ...state,
          data: userCreditCards,
        };
      })
      .addCase(deleteCreditCardThunk.pending.type, (state: UserCreditCardsState, action: any) => {
        const {
          meta: { arg: cardId },
        } = action;

        const { data } = current(state);

        const userCreditCards = data.map(e => {
          if (e.id === cardId) {
            return { ...e, deletingLoading: 'loading' } as UserCreditCard;
          }
          return e;
        });

        return {
          ...state,
          data: userCreditCards,
        };
      })
      .addCase(deleteCreditCardThunk.rejected.type, (state: UserCreditCardsState, action: any) => {
        const {
          meta: { arg: cardId },
        } = action;

        const { data } = current(state);

        const userCreditCards = data.map(e => {
          if (e.id === cardId) {
            return { ...e, deletingLoading: 'failed' } as UserCreditCard;
          }
          return e;
        });

        return {
          ...state,
          data: userCreditCards,
        };
      });
  },
});

export const useUserCreditCards = (): UserCreditCardsState => {
  const reducerState = useAppSelector((state: RootState) => state.userCreditCards);
  return reducerState;
};

export const { reset, setErrorMessage } = userCreditCardSlice.actions;

export default userCreditCardSlice.reducer;
